<template>
  <div class="show-form-companies">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Наименование</td>
          <td>{{ company.name }}</td>
        </tr>
        <tr>
          <td class="label-col">ОГРН</td>
          <td>{{ company.ogrn }}</td>
        </tr>
        <tr>
          <td class="label-col">ИНН</td>
          <td>{{ company.inn }}</td>
        </tr>
        <tr>
          <td class="label-col">КПП</td>
          <td>{{ company.kpp }}</td>
        </tr>
        <tr>
          <td class="label-col">Юр. адрес</td>
          <td>{{ company.address }}</td>
        </tr>
        <tr>
          <td class="label-col">Расч. счет</td>
          <td>{{ company.rs_number }}</td>
        </tr>
        <tr>
          <td class="label-col">Банк расч. счета</td>
          <td>{{ company.rs_bank_name }}</td>
        </tr>
        <tr>
          <td class="label-col">Кор. счет</td>
          <td>{{ company.ks }}</td>
        </tr>
        <tr>
          <td class="label-col">БИК</td>
          <td>{{ company.bik }}</td>
        </tr>
        <tr>
          <td class="label-col">В лице</td>
          <td>{{ company.manager }}</td>
        </tr>
        <tr>
          <td class="label-col">Должность</td>
          <td>{{ company.position }}</td>
        </tr>
        <tr>
          <td class="label-col">ФИО для подписи</td>
          <td>{{ company.sign_name }}</td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4" />
  </div>
</template>

<script>
export default {
  name: 'CompanyShow',
  props: ['id', 'company']
}
</script>

<style scoped>
  .show-form-companies {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
