<template>
  <div>
    <div>
      <div v-if="car.lastState">
        Связь: <WatchState :location="car.lastState" />
      </div>
      <div v-if="car.lastState && car.lastState.ignition">
        Зажигание: {{ car.lastState.ignition.value == 1 ? 'есть' : 'нет' }}
      </div>
      <div v-if="car.lastState && car.lastState.lat">
        Скорость: {{ Math.round(car.lastState.speed.value) }} км/ч
      </div>
      <div v-if="car">
        Пробег:
        <span v-if="car.lastState && car.lastState.totalOdometer">
          {{' ' + Math.round(car.lastState.totalOdometer.value / 1000) + " км"}}
          (по датчику {{' ' + Math.round(car.lastState.totalOdometer.sensor / 1000) + " км"}})
        </span>
        <span v-else class="text-danger">
          <font-awesome-icon class="mr-1" icon="exclamation-circle"></font-awesome-icon>Нет данных
        </span>
      </div>
      <div v-if="car.lastState && car.lastState.lat && car.lastState.lng">
        Координаты: {{ car.lastState.lat.value }}, {{ car.lastState.lng.value }}
      </div>
      <div v-if="car.lastState && car.lastState.lat">
        Дата последней отправки данных: {{ toLocalTime(car.lastState.lat.time) }}
      </div>
      <div v-if="car.imei">IMEI: {{ car.imei }}</div>
    </div>
    <hr>
    <div>
      <h5 class="text-center" style="font-size: 18px">Маршрут</h5>
      <div class="my-1">Начало</div>
      <div class="row">
        <div class="col">
          <input
            class="w-100 form-control"
            type="datetime-local"
            placeholder="Начало маршрута"
            v-model="timeStartRoute"
          />
        </div>
      </div>
      <div class="my-2">Окончание</div>
      <div class="row">
        <div class="col">
          <input
            class="w-100 form-control"
            type="datetime-local"
            placeholder="Начало маршрута"
            v-model="timeEndRoute"
          />
        </div>
      </div>
      <button class="input-group-btn btn btn-primary my-4 align-content-center w-100" @click="getTrackByTime()">
        <span v-if="!loadingTrack">Показать</span>
        <div v-else class="spinner-border spinner-border-sm text-light" role="status">
          <span class="sr-only">Загрузка...</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'MapCardInfo',
  data () {
    return {
      timeStartRoute: null,
      timeEndRoute: null,
      loadingTrack: false
    }
  },
  computed: {
    ...mapGetters('taxi/mapCars', {
      car: 'selectedCar'
    })
  },
  mounted () {
    this.timeStartRoute = dayjs(new Date()).hour(0).minute(0).format('YYYY-MM-DDTHH:mm:00')
    this.timeEndRoute = dayjs(new Date()).add(1, 'day').hour(0).minute(0).format('YYYY-MM-DDTHH:mm:00')
  },
  methods: {
    ...mapActions('taxi/mapCars', [
      'getStartTrack',
      'clearTrack',
      'getTrack'
    ]),
    toLocalTime (date) {
      return new Date(date).toLocaleString()
    },
    getTrackByTime () {
      if (!this.loadingTrack) {
        this.loadingTrack = true
        this.clearTrack
        const startTime = dayjs(new Date(this.timeStartRoute) - (60 * 60 * 1000) * 5).format('YYYY-MM-DDTHH:mm:ss')
        const endTime = dayjs(new Date(this.timeEndRoute) - (60 * 60 * 1000) * 5).format('YYYY-MM-DDTHH:mm:ss')
        if (new Date(this.timeEndRoute) < new Date()) {
          this.getTrack({
            id: this.car.id,
            timeFrom: startTime,
            timeTo: endTime
          })
            .then(track => {
              this.loadingTrack = false
              if (!track.track.length) {
                Swal.fire({
                  icon: 'warning',
                  title: 'Нет данных',
                  text: 'Не удалось найти маршрут за данный период'
                })
              }
            })
            .catch(error => {
              this.loadingTrack = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: error.message
              })
            })
        } else {
          this.getStartTrack({
            id: this.car.id,
            startTimeOfTrack: startTime,
            endTimeOfTrack: endTime
          })
            .then(track => {
              this.loadingTrack = false
              if (!track.track.length) {
                Swal.fire({
                  icon: 'warning',
                  title: 'Нет данных',
                  text: 'Не удалось найти маршрут за данный период'
                })
              }
            })
            .catch(error => {
              this.loadingTrack = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: error.message
              })
            })
        }
      }
    }
  }
}
</script>
