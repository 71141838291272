import APITemplate from '@/api/APITemplate'

const url = '/contract'
const template = APITemplate(url)

const getRents = (config, type) => {
  const params = new URLSearchParams()
  if (config && config.page !== undefined && config.pageSize !== undefined) {
    params.append('pagination[page]', (config.page - 1).toString())
    params.append('pagination[pageSize]', config.pageSize)
  }
  if (config && config.searchText !== undefined) {
    params.append('search', config.searchText)
  }
  return template.fetchGet(`${url}/${type}?${params}`)
}

export default {
  ...template,
  archivate ({ id, archivedAt }) {
    return fetch(`${url}/archivate?id=${id}&archived_at=${archivedAt}`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(template.saveHandler)
  },
  activate (id) {
    return fetch(`${url}/activate?id=${id}`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(template.noResponseErrorHandler)
  },
  unArchivate ({ id }) {
    return fetch(`${url}/un-archivate?id=${id}`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  close (id) {
    return fetch(`${url}/close?id=${id}`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  checkDocuments ({ id }) {
    return fetch(`${url}/check-documents?id=${id}`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  getActiveRents (config) {
    return getRents(config, 'active-contracts-with-search')
  },
  getClosedRents (config) {
    return getRents(config, 'closed-contracts-with-search')
  },
  getDraftRents (config) {
    return getRents(config, 'draft-contracts-with-search')
  },
  getDraftCount () {
    return fetch(`${url}/draft-count`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => {
      if (response.status === 200) {
        return response.json()
      }
      return 0
    })
  }
}
