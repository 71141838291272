<template>
  <div class="page page-create-serve">
    <h3 class="header-title-mobile">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <div class="form-group">
      <label for="exampleInputEmail1">Дата/время обслуживания</label>
      <input
        type="datetime-local"
        class="form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Введите дату/время обслуживания"
        :max="now()"
        v-model="service.datetime"
      />
    </div>
    <div class="form-group">
      <label for="exampleInputEmail2">Фактические показания одометра</label>
      <input
        type="number"
        class="form-control"
        id="exampleInputEmail2"
        aria-describedby="emailHelp"
        placeholder="Введите значение одометра"
        v-model="service.total_odometer_stamp"
      />
    </div>

    <div class="draggable-cards-wrapper">
      <DraggableTree class="tree4" :data="works" :indent="30" :space="0">
        <div slot-scope="{data, store}">
          <template v-if="!data.isDragPlaceHolder">
            <div class="row text-dark py-2" @click="() => {
              if (!data.children || !data.children.length) {
                data.selected = !data.selected
              }
              store.toggleOpen(data)
            }">
              <div class="col">
                <font-awesome-icon
                  :icon="data.open ? 'chevron-up' : 'chevron-down'"
                  size="1x"
                  v-if="data.children && data.children.length"
                  class="mx-2"
                  :style="{
                    color: 'gray',
                    'margin-left': 'auto',
                    cursor: 'pointer'
                  }"
                />
                <input v-else type="checkbox" class="mr-3" v-model="data.selected" :id="`checkbox${data.id}`">
                {{ data.name }}
                <span class="badge" style="line-height: unset">
                  {{ data.interval ? `Каждые ${data.interval} км.` : "" }}
                </span>
              </div>
            </div>
          </template>
        </div>
      </DraggableTree>
    </div>
    <br />
    <div class="form-group">
      <label for="exampleFormControlTextarea1">Прочие работы</label>
      <textarea class="form-control" id="exampleFormControlTextarea1" v-model="service.notes" rows="6"></textarea>
    </div>
    <button class="btn btn-primary" @click="createServe()">Сохранить</button>
    <router-link class="btn btn-primary" style="margin-right: 10px;" :to="`/maintenance/car/${carId}`">Назад</router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import userApi from '@/api/user'
import serviceApi from '../../api/service'
import { djsMixin } from '@/mixins/djsMixin'

export default {
  name: 'CreateService',
  props: ['carId'],
  mixins: [djsMixin],
  mounted () {
    this.loadItem({ id: this.carId })
    this.loadItems({ sort: 'sort' })
    this.setTitle({
      items: [
        {
          title: 'Тех. обслуживание',
          url: '/maintenance'
        }, {
          title: 'Добавить'
        }
      ]
    })
  },
  computed: {
    ...mapState('taxi/cars', {
      car (state) {
        return state.items.find(el => el.id == this.carId)
      },
      isLoading: state => state.isLoading
    }),
    ...mapState('maintenance/serviceWork', {
      worksState: state => state.items.filter(e => !e.archived_at),
      isLoading: state => state.isLoading
    }),
    works: {
      get () {
        if (!this.worksCopy) {
          return JSON.parse(JSON.stringify(this.worksState))
        }
        return this.worksCopy
      }
    },
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  data () {
    return {
      worksCopy: null,
      service: {
        car_id: this.carId,
        total_odometer_stamp: null,
        author_id: null,
        datetime: this.now(),
        notes: null
      },
      isCreating: false
    }
  },
  methods: {
    ...mapActions('taxi/cars', [
      'loadItem'
    ]),
    ...mapActions('maintenance/serviceWork', [
      'loadItems'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    createServe () {
      if (this.isCreating) return
      userApi
        .getCurrentUser()
        .then(user => {
          this.isCreating = true
          serviceApi.createItem({
            ...this.service,
            datetime: this.toUTCtranslate(this.service.datetime),
            car_id: this.carId,
            author_id: user.id,
            worksRelatedIds: this.getActiveWorks(this.works)
          })
            .then(work => {
              this.isCreating = false
              this.$router.push(`/maintenance/car/${this.carId}`)
            })
            .catch(err => {
              console.error(err)
              this.isCreating = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    setOpenProp (works) {
      works.forEach(work => {
        work.open = false
        if (work.children.length) {
          this.setOpenProp(work.children)
        }
      })
    },
    getActiveWorks (works) {
      let worksIds = []
      works.forEach(work => {
        if (work.selected) {
          worksIds.push(work.id)
        }
        if (work.children.length) {
          worksIds = worksIds.concat(this.getActiveWorks(work.children))
        }
      })
      return worksIds
    }
  }
}
</script>

<style scoped>
  .page-create-serve {
    width: 98%;
    padding-bottom: 4rem;
  }
  ::v-deep .tree-node-inner:hover {
    cursor: pointer !important;
  }
</style>
