<template>
  <div class="page">
    <router-link to="/devices/create" class="btn btn-primary w-100">Добавить</router-link>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <thead>
        <tr>
          <th>Название</th>
          <th>IMEI</th>
          <th>Прошивка</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="device of devices"
          :key="device.id"
          @click="$router.push(`/devices/${device.id}`)"
          role="button"
          tabindex="0"
        >
          <td>{{ device.name }}</td>
          <td>{{ device.imei }}</td>
          <td>{{ device.firmware ? device.firmware.name : "" }}</td>
        </tr>
      </tbody>
    </table>
    <div class="mobile-table-view card mt-2 mb-2 p-2 d-md-none" v-for="device of devices" :key="device.id">
      <table v-if="devices.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Название</td>
            <td style="border-top: none">{{ device.name }}</td>
          </tr>
          <tr>
            <td>IMEI</td>
            <td>{{ device.imei }}</td>
          </tr>
          <tr>
            <td>Прошивка</td>
            <td>{{ device.firmware ? device.firmware.name : "" }}</td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'devicePage', params: { id: device.id } }"
        class="btn btn-primary btn-md create-device ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'TrackDevices',
  mounted () {
    this.loadItems()
    this.setTitle({
      items: [
        {
          title: 'Наблюдение',
          url: '/watch'
        },
        {
          title: 'Устройства'
        }
      ]
    })
  },
  computed: {
    ...mapState('taxi/trackDevice', {
      devices: state => state.items
    })
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/trackDevice', [
      'loadItems',
      'abortLoading'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
