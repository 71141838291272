<template>
  <div class="view-contracts">
    <table class="table" v-if="contract">
      <tbody>
        <tr v-if="contract.template">
          <td style="width:25%">Шаблон документа</td>
          <td>{{ contract.template.name }}</td>
        </tr>
        <tr v-if="contract.receiveListTemplate">
          <td>Шаблон акта возврата</td>
          <td >{{ contract.receiveListTemplate.name }}</td>
        </tr>
        <tr>
          <td>Водитель</td>
          <td v-if="contract.driver">{{ contract.driver.last_name }} {{ contract.driver.first_name }} {{ contract.driver.middle_name }}</td>
        </tr>
        <tr>
          <td>Автомобиль</td>
          <td v-if="contract.car">{{ contract.car.brand }} {{ contract.car.model }} - {{ contract.car.registration_plate }}</td>
        </tr>
        <tr>
          <td>Организация</td>
          <td>{{ companyName }}</td>
        </tr>
        <tr>
          <td>Дата/ время передачи автомобиля</td>
          <td>{{ contract.transfer_datetime | diffForHumans}}</td>
        </tr>
        <tr>
          <td>Дата/ время возврата автомобиля</td>
          <td v-if="contract.receiving_datetime">{{ contract.receiving_datetime | diffForHumans}}</td>
          <td v-else>Не назначена</td>
        </tr>
        <tr v-if="contract.archived_at">
          <td>Дата/ время принятия автомобиля (фактическая)</td>
          <td>{{ contract.archived_at | diffForHumans}}</td>
        </tr>
        <tr>
          <td>Адрес выдачи</td>
          <td>{{ contract.transfer_spot }}</td>
        </tr>
        <tr>
          <td>Адрес возврата</td>
          <td>{{ contract.receiving_spot }}</td>
        </tr>
        <tr>
          <td>Арендная плата</td>
          <td v-if="contract.price">{{ contract.price }} &#8381;</td>
          <td v-else>Не назначена</td>
        </tr>
        <tr>
          <td>Период оплаты</td>
          <td>{{ contract.payment_period | formatedPeriod }}</td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4" />
  </div>
</template>

<script>
import { djsMixin } from '@/mixins/djsMixin'
export default {
  name: 'ContractRentView',
  props: ['contract', 'companies'],
  mixins: [djsMixin],
  computed: {
    companyName () {
      const currentCompany = this.companies.find(company => company.id === this.contract.company_id)
      return currentCompany ? currentCompany.name : ''
    }
  },
  filters: {
    formatedPeriod (period) {
      switch (period) {
        case 'day':
          return 'Посуточно'
        case 'month':
          return 'Помесячно'
        case 'year':
          return 'Год'
      }
      return ''
    }
  }
}
</script>

<style scoped>
  .view-contracts {
    margin-top: 4rem;
  }
</style>
