/* eslint-disable camelcase */

export const headers = {
  accept: 'application/json',
  'content-type': 'application/json'
}
export const saveHandler = response => {
  if (!response.ok) {
    return response.json()
      .then(error => {
        if (error) {
          if (response.status === 422 && Array.isArray(error.model)) {
            throw new Error(error.model.map(err => err.message).join('<br />'))
          }
          if (response.status === 403) {
            throw new Error('У вас нет прав для выполнения этого действия')
          }
          throw new Error(error.message)
        }
        throw new Error(`Ошибка при сохранении.\n${response.status} ${response.statusText}`)
      })
  }
  return response.json()
}
const noResponseErrorHandler = response => {
  if (response.status !== 204) {
    return response.json()
      .then(error => {
        if (error) {
          throw new Error(`${error.message}`)
        }
      })
  }
}

const fetchGet = url => {
  const controller = new AbortController()
  return {
    promise: fetch(url, {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      signal: controller.signal,
      headers
    })
      .then(response => {
        if (response.status !== 200) {
          response.json()
          throw new Error('Ошибка при загрузке.')
        }
        return new Promise((resolve, reject) => {
          response.json().then(items => {
            resolve({
              items,
              page: response.headers.get('x-pagination-current-page'),
              pageCount: response.headers.get('x-pagination-page-count'),
              pageSize: response.headers.get('x-pagination-per-page'),
              totalCount: response.headers.get('x-pagination-total-count')
            })
          })
        })
      }),
    abort () {
      controller.abort()
    }
  }
}
export default url => {
  return {
    headers,
    fetchGet,
    saveHandler,
    noResponseErrorHandler,
    errorHandler (response) {
      if (!response.ok) {
        return response.json()
          .then(() => {
            if (response.status === 404 || response.status === 500) {
              throw new Error(`${response.status} ${response.statusText} `)
            }
          })
      }
      return response.json()
    },
    getItems (config) {
      const params = new URLSearchParams()
      if (config && config.page !== undefined && config.pageSize !== undefined) {
        params.append('pagination[page]', (config.page - 1).toString())
        params.append('pagination[pageSize]', config.pageSize)
      }
      if (config && config.sort) {
        params.append('sort', config.sort.toString())
      }
      if (config && config.expand) {
        params.append('expand', config.expand.toString())
      }
      if (config && config.searches) {
        Object.keys(config.searches).forEach(key => {
          params.append(`filter[or][][${key}][like]`, config.searches[key])
        })
      }
      // any param
      if (config && config.extra && config.extra.key && config.extra.value) {
        params.append(config.extra.key, config.extra.value)
      }

      return fetchGet(`${url}?${params}`)
    },
    createItem (fields) {
      return fetch(url + '/create', {
        method: 'POST',
        credentials: 'include',
        redirect: 'error',
        headers,
        body: JSON.stringify(fields)
      }).then(saveHandler)
    },
    getItem ({ id, queryWith }) {
      let request = url + '/' + id
      if (queryWith) {
        request += `?expand=${queryWith.toString()}`
      }
      return fetch(request, {
        method: 'GET',
        credentials: 'include',
        redirect: 'error',
        headers
      }).then(response => response.json())
    },
    getItemPromise ({ id, expand }) {
      const params = new URLSearchParams()
      if (expand) {
        params.append('expand', expand)
      }
      return fetchGet(`${url}/${id}?${params}`)
    },
    removeItem (id) {
      return fetch(url + `/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        redirect: 'error',
        headers
      }).then(noResponseErrorHandler)
    },
    updateItem (data) {
      return fetch(url + `/${data.id}`, {
        method: 'PUT',
        credentials: 'include',
        redirect: 'error',
        headers,
        body: JSON.stringify(data)
      }).then(saveHandler)
    }
  }
}
