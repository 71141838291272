<template>
  <div class="page page-drivers">
    <h3 class="header-title-mobile">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Фамилия</label>
        <input type="text" class="form-control" v-model="driver.last_name" placeholder required>
      </div>
      <div class="col-md-3 mb-2">
        <label>Имя</label>
        <input type="text" class="form-control" v-model="driver.first_name" placeholder required>
      </div>
      <div class="col-md-4 mb-2">
        <label>Отчество</label>
        <input type="text" class="form-control" v-model="driver.middle_name" placeholder required>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-5">
        <label>Телефон</label>
        <IMaskComponent
          type="text"
          name="phone"
          class="form-control"
          v-model="driver.phone"
          :mask="'+{7} (000) 000-00-00'"
          :unmask='false'
          placeholder='+7 (000) 000-00-00'
        />
      </div>
       <div class="col-md-4 mb-6">
        <label>Адрес проживания</label>
        <dadata-suggestions
          type="ADDRESS"
          class="form-control"
          v-model="driver.address"
        />
      </div>
    </div>
    <hr class="mb-4" />
    <div class="float-right btn-group">
      <button class="btn btn-primary" @click="createDriver()">Создать</button>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import DriversAPI from '@/api/drivers'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CreateDriver',
  data () {
    return {
      isCreating: false,
      driver: {
        last_name: '',
        middle_name: '',
        first_name: '',
        phone: '',
        address: ''
      }
    }
  },
  methods: {
    createDriver () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      DriversAPI.createItem(this.driver)
        .then(driver => {
          this.isCreating = false
          this.$router.push('/drivers')
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Водители',
          url: '/drivers'
        },
        {
          title: 'Добавить'
        }
      ]
    })
  }
}
</script>

<style scoped>
  .page-drivers h3 {
    margin-bottom: 3rem;
  }
</style>
