
const result = [
  /* {
    text: 'Наблюдение',
    url: '/watch',
    icon: 'eye',
    subactiveRoutes: ['/devices'],
    sublinks: [
      {
        text: 'Карта',
        url: '/watch'
      }, {
        text: 'Устройства',
        url: '/devices'
      }
    ]
  }, */ {
    text: 'Наблюдение',
    url: '/watch',
    icon: 'eye'
  }, {
    text: 'Аренда',
    url: '/rent',
    icon: 'retweet'
  }, {
    text: 'divider1',
    divider: true
  }, {
    text: 'Автомобили',
    url: '/cars',
    icon: 'car'
  }, {
    text: 'Водители',
    url: '/drivers',
    icon: ['fas', 'id-badge']
  }, {
    text: 'Организации',
    url: '/companies',
    icon: 'briefcase'
  }, {
    text: 'divider2',
    divider: true
  }, {
    text: 'Шаблоны',
    url: '/templates',
    icon: 'file-alt'
  }, {
    text: 'Пользователи',
    url: '/users',
    icon: 'user-lock'
  }
]

if (process.env.VUE_APP_MODULE_RESTRICTED_AREA === 'true') {
  const index = result.findIndex(e => e.text === 'Шаблоны')
  result.splice(index + 1, 0, {
    text: 'Геозоны',
    url: '/map-restricted-area',
    icon: 'map-marked'
  })
}

if (process.env.VUE_APP_MODULE_FUEL === 'true') {
  const index = result.findIndex(e => e.text === 'Аренда')
  result.splice(index + 1, 0, {
    text: 'Топливо',
    url: '/fueling',
    icon: 'gas-pump',
    subactiveRoutes: ['/fuel-consumptions', '/fuel-types'],
    sublinks: [
      {
        text: 'Заправки',
        url: '/fueling'
      }, {
        text: 'Расход',
        url: '/fuel-consumptions'
      }, {
        text: 'Виды топлива',
        url: '/fuel-types'
      }
    ]
  })
}

if (process.env.VUE_APP_MODULE_MAINTENANCE === 'true') {
  const index = result.findIndex(e => e.text === 'Аренда')
  result.splice(index + 1, 0, {
    text: 'Тех. обслуживание',
    url: '/maintenance',
    sublinks: [
      {
        text: 'Список',
        url: '/maintenance'
      }, {
        text: 'Планирование',
        url: '/maintenance/planning'
      }, {
        text: 'Работы',
        url: '/maintenance/service-works'
      }
    ],
    icon: 'wrench'
  })
}

export default result
