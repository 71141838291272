<template>
  <div class="page page-track-devices">
    <h3 class="header-title-mobile">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeTrackDevice"
      @saveAction="updateTrackDevice"
      @deleteAction="deleteTrackDevice"
      @cancelAction="cancelTrackDeviceChanges"
    />
    <br />
    <TrackDeviceEdit
      v-if="isEditing"
      class="device-view-edit"
      :trackDevice="trackDevice"
      :firmwares="firmwares"
    />
    <TrackDeviceView
      v-else
      class="device-view-edit"
      :trackDevice="trackDevice"
    />
  </div>
</template>

<script>
/* eslint-disable camelcase */

import Swal from 'sweetalert2'
import deepAssign from 'assign-deep'
import trackDeviceApi from '@/api/trackDevice'
import { mapActions, mapState } from 'vuex'
import TrackDeviceEdit from './Edit'
import TrackDeviceView from './View'

export default {
  name: 'TrackDevicePage',
  components: {
    TrackDeviceEdit,
    TrackDeviceView
  },
  props: ['id'],
  mounted () {
    this.loadData()
    trackDeviceApi.getFirmwares().then(response => {
      this.firmwares = response
    })
  },
  data () {
    return {
      isUpdating: false,
      prevTrackDevice: null,
      trackDevice: {},
      firmwares: [],
      isEditing: false
    }
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  methods: {
    updateTrackDevice () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      trackDeviceApi.updateItem(this.trackDevice)
        .then(response => {
          this.trackDevice = response.model
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadData () {
      trackDeviceApi.getItem({ id: this.id })
        .then(response => {
          this.trackDevice = response
          this.setTitle({
            items: [
              {
                title: 'Наблюдение',
                url: '/watch'
              },
              {
                title: 'Устройства',
                url: '/devices'
              },
              {
                title: response.name
              }
            ]
          })
        })
    },
    changeTrackDevice () {
      this.prevTrackDevice = deepAssign({}, this.trackDevice)
      this.isEditing = true
    },
    cancelTrackDeviceChanges () {
      this.trackDevice = this.prevTrackDevice
      this.isEditing = false
    },
    deleteTrackDevice () {
      trackDeviceApi.removeItem(this.trackDevice.id)
        .then(() => {
          this.$router.push('/devices')
        })
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style scoped>
  .page-track-devices {
    width: 98%;
    padding-bottom: 4rem;
    padding-top: 2.5rem;
  }
</style>
