import StsEdit from './sts/Edit'
import StsView from './sts/View'
import OsagoEdit from './osago/Edit'
import OsagoView from './osago/View'
import PassportEdit from './vehiclePassport/Edit'
import PassportView from './vehiclePassport/View'
import PassCarriageLicenceEdit from './passCarriageLicence/Edit'
import PassCarriageLicenceView from './passCarriageLicence/View'

export default {
  vehicle_passport: {
    title: 'ПТС',
    edit: {
      name: 'DocumentVehiclePassportEdit',
      component: PassportEdit
    },
    view: {
      name: 'DocumentVehiclePassportView',
      component: PassportView
    },
    tabs: {
      title: 'ПТС',
      scansTittle: 'Сканы ПТС'
    }
  },
  osago: {
    title: 'ОСАГО',
    edit: {
      name: 'DocumentOsagoEdit',
      component: OsagoEdit
    },
    view: {
      name: 'DocumentOsagoView',
      component: OsagoView
    },
    tabs: {
      title: 'ОСАГО',
      scansTittle: 'Сканы ОСАГО'
    }
  },
  sts: {
    title: 'СТС',
    edit: {
      name: 'DocumentStsEdit',
      component: StsEdit
    },
    view: {
      name: 'DocumentStsView',
      component: StsView
    },
    tabs: {
      title: 'СТС',
      scansTittle: 'Сканы СТС'
    }
  },
  pass_carriage_licence: {
    title: 'Разрешение на перевозку',
    edit: {
      name: 'DocumentPassCarriageLicenceEdit',
      component: PassCarriageLicenceEdit
    },
    view: {
      name: 'DocumentPassCarriageLicenceView',
      component: PassCarriageLicenceView
    },
    tabs: {
      title: 'Разрешение на перевозку',
      scansTittle: 'Сканы разрешения'
    }
  }
}
