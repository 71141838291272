import Vue from 'vue'
import DadataSuggestions from 'vue-dadata-suggestions'
import './car'
import './common'
import './contracts/rent'
import './maps'
import TextHighlight from 'vue-text-highlight'
import { IMaskComponent } from 'vue-imask'

Vue.use(DadataSuggestions, { token: process.env.VUE_APP_DADATA_AUTH_TOKEN })
Vue.component('TextHighlight', TextHighlight)
Vue.component('IMaskComponent', IMaskComponent)
