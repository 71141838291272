<template>
  <div class="page">
    <div class="d-flex flex-column">
      <div class="input-group without-separator">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <font-awesome-icon icon="search" style="color:gray" />
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск по марке, модели, рег. номеру"
          aria-label="Поиск по марке, модели, рег. номеру"
          @input="searchCar"
          :value="searchText"
        />
      </div>
      <div class="pt-2">
        <router-link to="/cars/create" class="btn btn-primary w-100">Добавить автомобиль</router-link>
      </div>
    </div>
    <!--p v-if="isLoading">Загрузка</p-->
    <table v-if="cars.length > 0" class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr
          v-for="car of cars"
          :key="car.id"
          role="button"
          tabindex="0"
          @click="$router.push(`/cars/${car.id}`)"
        >
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.model }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.registration_plate }}</TextHighlight>
          </td>
          <td>{{ car.manufacturing_year }}</td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.imei }}</TextHighlight>
          </td>
          <td class="col-state">
            <CarState :car="car" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mobile-table-view card mt-2 mb-2 p-2 d-md-none" v-for="car of cars" :key="car.id">
      <table v-if="cars.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Марка</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Модель</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ car.model }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Рег. номер</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ car.registration_plate }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>IMEI</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ car.imei }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Статус</td>
            <td>
              <CarState :car="car" />
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'carPage', params: { carId: car.id } }"
        class="btn btn-primary btn-md create-car ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CarState from '@/components/CarState'

export default {
  name: 'Cars',
  components: {
    CarState
  },
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Марка',
          storeName: 'brandIndex',
          values: ['', 'brand', '-brand'],
          order: 1
        }, {
          name: 'Модель',
          storeName: 'modelIndex',
          values: ['', 'model', '-model'],
          order: 2
        }, {
          name: 'Рег. номер',
          storeName: 'regPlateIndex',
          values: ['', 'reg_plate_number', '-reg_plate_number'],
          order: 4
        }, {
          name: 'Год выпуска',
          storeName: 'mYearIndex',
          values: ['', 'manufacturing_year', '-manufacturing_year'],
          order: 3
        }, {
          name: 'IMEI',
          storeName: 'imeiIndex',
          values: ['', 'tracking_device.imei', '-tracking_device.imei'],
          order: 2,
          disabled: true
        }, {
          name: 'Статус',
          storeName: 'statusIndex',
          values: ['', 'state', '-state'],
          order: 0
        }
      ]
    }
  },
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Автомобили'
        }
      ]
    })
    this.loadItems({ page: Number(this.page) })
  },
  computed: {
    ...mapState('taxi/cars', {
      cars: state => state.items,
      searchText: state => state.searchText,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading
    })
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/cars', [
      'loadItemsWith',
      'abortLoading',
      'removeItem',
      'setSearch',
      'loadItems',
      'setSearchText',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    searchCar (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'tracking_device.imei', value: e.target.value })
      this.setSearch({ key: 'brand', value: e.target.value })
      this.setSearch({ key: 'model', value: e.target.value })
      this.setSearch({ key: 'reg_plate_number', value: e.target.value })
      this.loadItems({ page: Number(this.page) })
    },
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
    }
  },
  watch: {
    page () {
      this.loadItems({ page: Number(this.page) })
    }
  }
}
</script>

<style scoped>
  .col-state {
    width: 9rem;
    padding: 0.5rem 0.5rem 0.1rem 0;
  }
  .highlighter >>> .text__highlight {
    border-radius: 0px;
    padding: 0.2em 0 0.2em 0;
    background: #fff2ae;
  }
</style>
