<template>
  <div class="page">
    <h3 class="header-title-mobile d-flex">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <div class="row">
      <div class="col-12">
        <label>Регистрационный номер машины</label>
        <VSelect
          style="width: 100%"
          :options="cars"
          v-model="consumption.car_id"
          :reduce="option => option.id"
          label="registration_plate"
        >
          <template v-slot:option="option">
            {{ option.brand }} {{ option.model }}
            <sub>рег. номер: {{ option.registration_plate }}</sub>
          </template>
        </VSelect>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Тип топлива</label>
        <VSelect
          style="width: 100%"
          :options="fuels"
          v-model="consumption.fuel_type_id"
          :reduce="option => option.id"
          label="name"
        >
          <template v-slot:option="option">{{ option.name }}</template>
        </VSelect>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Расход, на 100км</label>
        <input type="number" class="form-control" v-model="consumption.consumption" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Процент использования (сумма процентов на всех видах не должна превышать 100%)</label>
        <input type="number" class="form-control" v-model="consumption.percentage_of_use" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Дата</label>
        <input type="datetime-local" class="form-control" v-model="date" placeholder required />
      </div>
    </div>
    <hr class="mb-4" />
    <button class="btn btn-primary" @click="createConsumption()">Создать</button>
    <router-link to="/consumption" class="btn btn-primary">Назад</router-link>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import userApi from '@/api/user'
import FuelConsumptionsApi from '../../api/fuelConsumptions'

export default {
  name: 'CreateConsumption',
  data () {
    return {
      isCreating: false,
      consumption: {
        car_id: 0,
        fuel_type_id: 0,
        author_id: 0,
        consumption: 0,
        date: 0,
        percentage_of_use: 0,
        create_at: 0
      }
    }
  },
  beforeMount () {
    this.loadCars()
    this.loadFuels()
  },
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Расход',
          url: '/fuel-consumptions'
        }, {
          title: 'Добавить'
        }
      ]
    })
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    }),
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    ...mapState('fuel/fuelType', {
      fuelTypes: state => state.items.filter(e => !e.archived_at)
    }),
    date: {
      get () {
        return dayjs(this.consumption.date).format('YYYY-MM-DDTHH:mm')
      },
      set (dateTime) {
        this.consumption.date = dayjs(dateTime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ssZZ')
      }
    },
    fuels: {
      get () {
        if (!this.fuelsCopy) {
          return JSON.parse(JSON.stringify(this.fuelTypes))
        }

        return this.fuelsCopy
      },
      set (value) {
        value.forEach((v, i) => {
          this.updateItem({ ...v })
        })
        this.fuelsCopy = value
      }
    }
  },
  methods: {
    createConsumption () {
      if (this.isCreating) {
        return
      }
      userApi
        .getCurrentUser()
        .then(user => {
          this.isCreating = true
          this.consumption.author_id = user.id
          FuelConsumptionsApi.createItem(this.consumption)
            .then(() => {
              this.isCreating = false
              this.$router.push('/fuel-consumptions')
            })
            .catch(err => {
              console.error(err)
              this.isCreating = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('taxi/cars', {
      loadCars: 'loadItems'
    }),
    ...mapActions('fuel/fuelType', {
      loadFuels: 'loadItems'
    })
  }
}
</script>
