import tripsAPI from '@/api/trips'

const types = {
  loadItems: 'loadItems',
  setIsLoading: 'setIsLoading'
}
let request = null

export default {
  namespaced: true,
  state: {
    items: [],
    isLoading: false
  },
  mutations: {
    [types.loadItems] (state, { items }) {
      state.items = items
    },
    [types.setIsLoading] (state, value) {
      state.isLoading = value
    }
  },
  actions: {
    loadItems ({ state, commit, dispatch }, params) {
      if (state.isLoading) {
        dispatch('abortLoading')
      }

      if (request) {
        request.abort()
      }
      request = tripsAPI.getItems(params)
      commit(types.setIsLoading, true)
      return request.promise
        .then(params => {
          request = null
          commit(types.setIsLoading, false)
          commit(types.loadItems, params)
          return params.items
        })
    },
    abortLoading ({ state, commit }) {
      commit(types.setIsLoading, false)
      if (request) {
        request.abort()
        request = null
      }
    }
  }
}
