import { render, staticRenderFns } from "./Watch.vue?vue&type=template&id=00a7d669&scoped=true&"
import script from "./Watch.vue?vue&type=script&lang=js&"
export * from "./Watch.vue?vue&type=script&lang=js&"
import style0 from "./Watch.vue?vue&type=style&index=0&id=00a7d669&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../shared/frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a7d669",
  null
  
)

export default component.exports