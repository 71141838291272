<template>
  <div class="edit-form-companies">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Наименование</td>
          <td><input type="text" class="form-control" v-model="company.name" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">ОГРН</td>
          <td><input type="text" class="form-control" v-model="company.ogrn" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">ИНН</td>
          <td><input type="text" class="form-control" v-model="company.inn" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">КПП</td>
          <td><input type="text" class="form-control" v-model="company.kpp" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">Юр. адрес</td>
          <td><textarea class="form-control" v-model="company.address" placeholder required></textarea></td>
        </tr>
        <tr>
          <td class="label-col">Расч. счет</td>
          <td><input type="text" class="form-control" v-model="company.rs_number" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">Банк расч. счета</td>
          <td><textarea class="form-control" v-model="company.rs_bank_name" placeholder required></textarea></td>
        </tr>
        <tr>
          <td class="label-col">Кор. счет</td>
          <td><input type="text" class="form-control" v-model="company.ks" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">БИК</td>
          <td><input type="text" class="form-control" v-model="company.bik" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">В лице</td>
          <td><input type="text" class="form-control" v-model="company.manager" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">Должность</td>
          <td><input type="text" class="form-control" v-model="company.position" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">ФИО для подписи</td>
          <td><input type="text" class="form-control" v-model="company.sign_name" placeholder required /></td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4" />
  </div>
</template>

<script>
export default {
  name: 'CompanyEdit',
  props: ['id', 'company']
}
</script>

<style scoped>
  .edit-form-companies {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
