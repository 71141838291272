<template>
  <div v-if="track && track.length">
    <l-polyline :lat-lngs="track" color="#0597f2"></l-polyline>
    <l-feature-group>
      <l-marker :lat-lng="[...track].shift()">
        <l-icon
          :icon-url="require('@/assets/icons/flag-regular.svg')"
          :icon-retina-url="require('@/assets/icons/flag-regular.svg')"
          :iconSize="[25, 41]"
          :iconAnchor="[3, 35]"
        />
      </l-marker>
      <l-marker :lat-lng="[...track].pop()">
        <l-icon
          :icon-url="require('@/assets/icons/flag-checkered-solid.svg')"
          :iconSize="[25, 41]"
          :iconAnchor="[3, 35]"
          :icon-retina-url="require('@/assets/icons/flag-checkered-solid.svg')"
        />
      </l-marker>
    </l-feature-group>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'Track',
  computed: {
    ...mapState('taxi/mapCars', {
      track: state => state.track
    })
  }
}
</script>
<style lang="scss" scoped>

</style>
