import Serve from './views/Serve'
import CreateService from './views/service/CreateService'
import ServicePage from './views/service/ServicePage'
import ServiceWorks from './views/ServiceWorks'
import CreateServiceWork from './views/serviceWork/Create'
import UpdateServiceWork from './views/serviceWork/Update'
import Planning from './views/Planning'

export default [
  {
    path: '/maintenance',
    component: Serve,
    meta: {
      title: 'Тех. обслуживание',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/maintenance/car/:carId/create',
    component: CreateService,
    props: true,
    meta: {
      title: 'Тех. обслуживание',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/maintenance/car/:carId',
    component: ServicePage,
    name: 'servePage',
    props: true,
    meta: {
      title: 'Тех. обслуживание',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/maintenance/service-works',
    component: ServiceWorks,
    meta: {
      title: 'Тех. обслуживание',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/maintenance/service-works/create',
    component: CreateServiceWork,
    meta: {
      title: 'Тех. обслуживание',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/maintenance/service-works/update/:id',
    component: UpdateServiceWork,
    props: true,
    meta: {
      title: 'Тех. обслуживание',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/maintenance/planning',
    component: Planning,
    props: true,
    meta: {
      title: 'Планирование',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/service-works/create/:id',
    component: CreateServiceWork,
    props: true,
    meta: {
      title: 'Тех. обслуживание',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
