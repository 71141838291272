<template>
  <div class="edis-osago">
    <div class="row">
      <div class="col-md-6 mb-2">
        <label>Серия</label>
        <!--input type="text" class="form-control" v-model="data.series" placeholder required /-->
        <IMaskComponent type="text"
          class="form-control"
          :mask="'[aaa]'"
          v-model="data.series"
          :prepare="str => str.toUpperCase()"
          placeholder="AAA"
          required
        />
      </div>
      <div class="col-md-6 mb-2">
        <label>Номер</label>
        <IMaskComponent type="text"
          class="form-control"
          :mask="'[0000000000]'"
          v-model="data.number"
          placeholder="01234567890"
          required
        />
      </div>
      <div class="col-md-6 mb-2">
        <label>Дата начала</label>
        <input
          type="date"
          class="form-control"
          v-model="data.date_start"
          required
        />
      </div>
      <div class="col-md-6 mb-2">
        <label>Дата окончания</label>
        <input
          type="date"
          class="form-control"
          v-model="data.date_end"
          required
        />
      </div>
    </div>
  </div>
</template>

<script>
const type = 'osago'

export default {
  name: 'DocumentOsagoEdit',
  props: ['documentData'],
  data () {
    return {
      data: {
        series: '',
        number: '',
        date_start: '',
        date_end: ''
      }
    }
  },
  mounted () {
    if (this.documentData) {
      this.data = this.documentData
    }
    this.update()
  },
  methods: {
    update () {
      this.$emit('update', {
        type: type,
        data: this.data
      })
    }
  },
  watch: {
    data: {
      handler () {
        this.update()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .edis-osago input {
    margin-bottom: 5px;
  }
  .edis-osago textarea {
    margin-bottom: 5px;
  }
</style>
