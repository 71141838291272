<template>
  <l-feature-group
    v-if="car && car.lastState && car.lastState.lat &&
    car.lastState.lng && !track.length"
  >
    <l-marker
      :z-index-offset="1"
      :lat-lng="[car.lastState.lat.value, car.lastState.lng.value]"
    >
      <l-icon
        :icon-url="require('@/assets/icons/fadeMarker.svg')"
        :icon-retina-url="require('@/assets/icons/fadeMarker.svg')"
        :iconSize="[25, 41]"
        :iconAnchor="[12, 41]"
        :popupAnchor="[1, -34]"
        :tooltipAnchor="[16, -28]"
      />
      <l-tooltip :options="{
        permanent: true,
        interactive: false,
        opacity: 0.8
      }">
        <div class="selected-marker-tooltop">
          <ul>
            <li>Автомобиль: {{ car.brand }} {{ car.model }}</li>
            <li>Гос.номер: {{ car.registration_plate }}</li>
            <li>Пробег: {{ car.lastState && car.lastState.totalOdometer ? Math.round(car.lastState.totalOdometer.value / 1000) + 'км' : ''}}</li>
          </ul>
        </div>
      </l-tooltip>
    </l-marker>
    <l-polyline :lat-lngs="selectedTrack" color="#0597f2"></l-polyline>
  </l-feature-group>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'SelectedMarker',
  methods: {
    ...mapActions('taxi/mapCars', [
      'selectCar'
    ])
  },
  computed: {
    ...mapGetters('taxi/mapCars', {
      car: 'selectedCar'
    }),
    ...mapState('taxi/mapCars', {
      selectedTrack: state => state.selectedTrack,
      track: state => state.track
    })
  }
}
</script>

<style lang="scss" scoped>
  .overlay-content {
    background-color: white;
    border-radius: 15px;
    padding: 0.3rem;
    pointer-events: none;
  }
  .selected-marker-tooltop ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    margin-bottom: 4px;
    margin-right: 0;
  }
  @media screen and (max-width: 576px) {
    .selected-marker-tooltop ul {
      font: 10px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    }
  }
</style>
