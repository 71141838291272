<template>
  <div class="page page-company">
    <h2>Новая организация</h2>
    <div class="row">
      <div class="col-md-6 mb-2">
        <label>Наименование</label>
        <input type="text" class="form-control" v-model="company.name" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-2">
        <label>ОГРН</label>
        <input type="text" class="form-control" v-model="company.ogrn" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>ИНН</label>
        <input type="text" class="form-control" v-model="company.inn" placeholder required />
      </div>
      <div class="col-md-4 mb-2">
        <label>КПП</label>
        <input type="text" class="form-control" v-model="company.kpp" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-2">
        <label>Юр. адрес</label>
        <textarea class="form-control" v-model="company.address" placeholder required></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-3">
        <label>Расч. счет</label>
        <input type="text" class="form-control" v-model="company.rs_number" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-3">
        <label>Банк расч. счета</label>
        <textarea class="form-control" v-model="company.rs_bank_name" placeholder required></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 mb-3">
        <label>Кор. счет</label>
        <input type="text" class="form-control" v-model="company.ks" placeholder required />
      </div>
      <div class="col-md-3 mb-3">
        <label>БИК</label>
        <input type="text" class="form-control" v-model="company.bik" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-3">
        <label>В лице</label>
        <input type="text" class="form-control" v-model="company.manager" placeholder required />
      </div>
    </div>
    <div class="row">
     <div class="col-md-4 mb-2">
        <label>Должность</label>
        <input type="text" class="form-control" v-model="company.position" placeholder required />
      </div>
      <div class="col-md-4 mb-2">
        <label>ФИО для подписи</label>
        <input type="text" class="form-control" v-model="company.sign_name" placeholder required />
      </div>
    </div>
    <hr class="mb-4" />
    <button class="btn btn-primary" @click="createCompany()">Создать</button>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import companiesAPI from '@/api/companies'
import { mapActions } from 'vuex'

export default {
  name: 'CreateCompany',
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Организации',
          url: '/companies'
        },
        {
          title: 'Добавить'
        }
      ]
    })
    // this.loadItems()
  },
  data () {
    return {
      company: {}
    }
  },
  methods: {
    createCompany () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      companiesAPI.createItem(this.company)
        .then(company => {
          this.isCreating = false
          this.$router.push(`/companies/${company.model.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
<style scoped>
  .page-company {
    padding-bottom: 8rem;
  }
  .btn-primary {
    width: 8rem;
    float: right !important;
  }
  h2 {
    margin-bottom: 3rem;
  }
</style>
