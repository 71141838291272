<template>
  <div class="page">
    <h3 class="header-title-mobile">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <ActionBar
      v-show="!isEnding"
      :isEditing="isEditing"
      @changeAction="changeContract"
      @cancelAction="cancelContractChanges"
      @deleteAction="deleteContract"
      @saveAction="updateContract"
    />

    <button
      class="mx-1 btn btn-primary button-cancel-archivate"
      @click="isEnding = false"
      v-if="isEnding"
    >Отменить</button>
    <button
      class="mx-1 btn btn-primary button-archivate"
      @click="archivateContract()"
      v-if="config.contract.status === 'active'"
    >
      <font-awesome-icon class="text-white" icon="stop" size="1x"/>
      Завершить аренду
    </button>
    <button
      class="mx-1 btn btn-primary button-archivate"
      @click="unArchivateContract()"
      v-if="config.contract.status === 'closed'"
    >Возобновить аренду</button>
    <button
      class="mx-1 btn btn-primary button-activate"
      @click="activateContract()"
      v-if="config.contract.status === 'draft'"
      :disabled="notifications.length > 0"
    >Активировать</button>
    <a
      v-if="!isEnding && config.contract.template"
      :href="`/contract/download?id=${contractId}`"
      class="mx-1 btn btn-secondary button-download"
      role="button"
    >
      <font-awesome-icon class="text-white" icon="file-download" size="1x"/>
      Скачать договор
    </a>
    <a
      v-if="config.contract.status === 'closed' && config.contract.receiveListTemplate"
      :href="`/contract/download-receive-list?id=${contractId}`"
      class="mx-1 btn btn-secondary button-download"
      role="button"
    >
      <font-awesome-icon class="text-white" icon="file-download" size="1x"/>
      Скачать акт возврата
    </a>
    <RentEditForm v-if="isEditing && !isEnding" />
    <RentShowForm v-if="!isEditing && !isEnding" :contract="config.contract" :companies="config.companies" />
    <div class="notifications-container" v-if="config.contract.status === 'draft'">
      <div
        v-for="notification in notifications"
        :key="notification"
        class="alert alert-warning notification-warning"
        role="alert"
      >{{ notification }}</div>
    </div>
    <div v-if="isEnding" class="end-rent-date-container">
      <label for="inputEndRDate">Дата/ время приема автомобиля (фактическая)</label>
      <input
        type="datetime-local"
        class="form-control"
        id="inputEndRDate"
        v-model="config.contract.archived_at"
        min="2010-06-01T08:30"
        max="2050-06-30T16:30"
      />
    </div>
    <hr />
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import ContractsAPI from '@/api/contracts'
import DriversAPI from '@/api/drivers'
import CarsAPI from '@/api/cars'
import FilesAPI from '@/api/files'
import deepAssign from 'assign-deep'
import CompaniesAPI from '@/api/companies'
import { djsMixin } from '@/mixins/djsMixin'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'RentPage',
  props: ['contractId'],
  mixins: [djsMixin],
  data () {
    return {
      isUpdating: false,
      config: {
        contract: {
          driver_id: '',
          car_id: '',
          date: '',
          file_id: '',
          transfer_datetime: '',
          receiving_datetime: '',
          price: '',
          payment_period: '',
          transfer_spot: '',
          receiving_spot: ''
        },
        drivers: '',
        templates: [],
        cars: [],
        companies: []
      },
      notifications: [],
      isEditing: false,
      isEnding: false,
      pervContract: null
    }
  },
  mounted () {
    this.loadItems()
  },
  provide () {
    return { config: this.config }
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  methods: {
    changeContract () {
      this.pervContract = deepAssign({}, this.config.contract)
      this.isEditing = true
    },
    deleteContract () {
      ContractsAPI.removeItem(this.contractId)
        .then(() => {
          this.$router.push('/rent')
        })
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    cancelContractChanges () {
      this.config.contract = this.pervContract
      this.isEditing = false
    },
    updateContract () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true

      ContractsAPI.updateItem(this.config.contract)
        .then(contract => {
          this.config.contract = contract.model
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    archivateContract () {
      if (this.isUpdating) {
        return
      }
      if (!this.isEnding) {
        this.config.contract.archived_at = this.now()
        this.isEnding = true
        return
      }
      this.isUpdating = true
      ContractsAPI.archivate({
        id: this.contractId,
        archivedAt: this.config.contract.archived_at
      }).then(response => {
        this.isEnding = false
        this.isUpdating = false
        this.loadItems()
      })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    unArchivateContract () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      ContractsAPI.unArchivate({ id: this.contractId })
        .then(response => {
          this.isEnding = false
          this.isUpdating = false
          this.loadItems()
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    activateContract () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      ContractsAPI.activate(this.contractId).then(response => {
        this.isUpdating = false
        this.loadItems()
      })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadItems () {
      DriversAPI.getItems().promise.then(drivers => {
        this.config.drivers = drivers.items
        this.config.drivers.forEach(driver => {
          driver.fullName = `${driver.last_name} ${driver.first_name} ${driver.middle_name}`
        })
      })
      CarsAPI.getItems().promise.then(cars => {
        this.config.cars = cars.items
        this.config.cars.forEach(car => {
          car.displayedName = `${car.brand} ${car.model} ${car.registration_plate}`
        })
      })
      FilesAPI.getContractTemplates().promise.then(templateFiles => {
        this.config.templates.contracts = templateFiles.items
        this.config.templates.contracts.forEach(template => {
          template.nameWithDate = `${template.name} ${template.created_at}`
        })
      })
      FilesAPI.getReceiveListsTemplates().promise.then(templateFiles => {
        this.config.templates.receiveLists = templateFiles.items
        this.config.templates.receiveLists.forEach(template => {
          template.nameWithDate = `${template.name} ${template.created_at}`
        })
      })
      ContractsAPI.getItem({ id: this.contractId }).then(contract => {
        this.config.contract = contract
        this.setTitle({
          items: [
            {
              title: 'Аренда',
              url: '/rent'
            },
            {
              title: contract.number ? '№ ' + contract.number : 'Черновик'
            }
          ]
        })
      })
      ContractsAPI.checkDocuments({ id: this.contractId }).then(notifications => {
        this.notifications = notifications
      })
      CompaniesAPI.getItems().promise.then(companies => {
        this.config.companies = companies.items
      })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style scoped>
  .button-download {
    min-width: 12rem;
    margin-right: 3px;
  }
  .button-archivate {
    min-width: 12rem;
  }
  .end-rent-date-container {
    margin-top: 3rem;
  }
  .notification-warning {
    padding: 0.45rem 1.25rem;
    margin-right: 0.5rem;
    color: #f2b950;
    width: fit-content;
    background-color: rgba(242, 185, 80, 0.15);
  }
  .notifications-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
</style>
