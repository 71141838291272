<template>
  <thead>
    <tr>
      <th
        v-for="sort in localSorts"
        :key="sort.name"
        tabindex="0"
        :role="!sort.disabled ? 'button' : null"
        :class="{ 'user-select-none': !sort.disabled }"
        @click="sort.index = ++sort.index % sort.values.length"
      >
        {{ sort.name }}
        <span style="position: relative" v-if="!sort.disabled">
          <font-awesome-icon icon="sort" size="1x" class="trans-icon" />
          <font-awesome-icon
            v-if="sort.index != 0"
            :icon="iconByIndex(sort.index)"
            size="1x"
            :class="{
              'up-icon': sort.index == 1,
              'bot-icon': sort.index == 2
            }"
          />
        </span>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'TableSort',
  props: ['sorts'],
  mounted () {
    this.localSorts = this.sorts.map(el => {
      const storeVal = parseInt(localStorage[el.storeName]) || el.index || 0
      return { ...el, index: storeVal }
    })
  },
  data () {
    return {
      localSorts: null
    }
  },
  methods: {
    iconByIndex (index) {
      if (index == 1) {
        return 'sort-up'
      }
      if (index == 2) {
        return 'sort-down'
      }
      return 'sort'
    }
  },
  watch: {
    localSorts: {
      deep: true,
      immidiate: true,
      handler (value) {
        const arrCopy = [...value]
        arrCopy.sort((a, b) => a.order - b.order)
        this.$emit('sortsUpdate', arrCopy)
        arrCopy.forEach(el => {
          localStorage[el.storeName] = el.index
        })
      }
    }
  }
}
</script>

<style scoped>
  .trans-icon {
    opacity: 0.5;
  }
  .up-icon {
    position: absolute;
    left: 0;
    top: 0;
  }
  .bot-icon {
    position: absolute;
    right: 0;
    bottom: 1px;
  }
</style>
