import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login'
import Http404 from '@/views/Http404'
import Templates from '@/views/Templates'
import Watch from '@/views/Watch'

import store from '../store'

import companies from './companies'
import user from './user'
import cars from './cars'
import trackDevice from './trackDevice'
import drivers from './drivers'
import rents from './rents'
import modules from '@/modules/router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/site/login',
    component: Login,
    meta: {
      title: 'Вход'
    }
  }, {
    path: '/',
    redirect: '/watch'
  },
  ...companies, // companies routes
  ...user, // users routes
  ...trackDevice, // tracking devices routes
  ...cars, // cars and car documents routes
  ...drivers, // drivers and driver documents routes
  ...rents, // rents routes
  {
    path: '/watch',
    component: Watch,
    meta: {
      title: 'Наблюдение',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/watch/:carId',
    component: Watch,
    props: true,
    meta: {
      title: 'Наблюдение',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/templates',
    component: Templates,
    meta: {
      title: 'Шаблоны',
      layout: 'BaseWorkspaceLayout'
    }
  },
  ...modules,
  {
    path: '*',
    component: Http404,
    meta: {
      title: 'Страница не найдена'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('taxi/user/loadAuthedUser').then(user => {
    if (!user && to.path !== '/site/login') {
      return next('site/login')
    }
    if (to.path === '/users/create' && !user.roles['Администратор']) {
      return next('/users')
    }
    return next()
  })
})
router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
})

export default router
