<template>
  <div class="page">
    <h3>Изменение работы техобслуживания</h3>
    <div v-if="fueling">
      <div class="row">
        <div class="col-12">
          <label>Регистрационный номер машины</label>
          <VSelect
            style="width: 100%"
            :options="cars"
            v-model="fueling.car_id"
            :reduce="option => option.id"
            label="registration_plate"
          >
            <template v-slot:option="option">
              {{ option.brand }} {{ option.model }}
              <sub>рег. номер: {{ option.registration_plate }}</sub>
            </template>
          </VSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Тип топлива</label>
          <VSelect
            style="width: 100%"
            :options="fuels"
            v-model="fueling.fuel_type_id"
            :reduce="option => option.id"
            label="name"
          >
            <template v-slot:option="option">{{ option.name }}</template>
          </VSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Отстаток, л</label>
          <input type="number" class="form-control" v-model="fueling.remaining" placeholder required />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Заполнено, л</label>
          <input type="number" class="form-control" v-model="fueling.fueled" placeholder required />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Расчет</label>
          <VSelect
            class="w-100"
            :options="payments"
            :reduce="payment => payment.value"
            v-model="fueling.cash"
            label="name"
          ></VSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Дата заправки</label>
          <input type="datetime-local" class="form-control" v-model="date" placeholder required />
        </div>
      </div>
      <hr class="mb-4" />
    </div>
    <div class="float-right btn-group">
      <button v-if="fuel" class="btn btn-primary" @click="updateRecord">Сохранить</button>
      <router-link to="/fueling" class="btn btn-primary">Назад</router-link>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import CarFuelingApi from '../../api/carFueling'

export default {
  name: 'UpdateFueling',
  props: ['id'],
  data () {
    return {
      isUpdating: false,
      fueling: null,
      payments: [
        {
          name: 'Наличный расчет',
          value: 1
        }, {
          name: 'Безналичный расчет',
          value: 0
        }
      ]
    }
  },
  computed: {
    ...mapState('fuel/carFueling', {
      fuelingState (state) {
        return state.items.find(e => e.id == this.id)
      },
      isLoading: state => state.isLoading
    }),
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    ...mapState('fuel/fuelType', {
      fuelTypes: state => state.items.filter(e => !e.archived_at)
    }),
    date: {
      get () {
        return dayjs(this.fueling.date).format('YYYY-MM-DDTHH:mm')
      },
      set (dateTime) {
        this.fueling.date = dayjs(dateTime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ssZZ')
      }
    },
    fuels: {
      get () {
        if (!this.fuelsCopy) {
          return JSON.parse(JSON.stringify(this.fuelTypes))
        }
        return this.fuelsCopy
      },
      set (value) {
        value.forEach((v, i) => {
          this.updateItem({ ...v })
        })
        this.fuelsCopy = value
      }
    }
  },
  beforeMount () {
    this.loadCars()
    this.loadFuels()
    if (!this.fuelingState) {
      this.loadFueling()
        .then(() => {
          this.fueling = JSON.parse(JSON.stringify(this.fuelingState ? this.fuelingState : null))
        })
    }
    this.fueling = this.fuelingState
  },
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Заправки',
          url: '/fueling'
        }, {
          title: 'Изменить'
        }
      ]
    })
  },
  methods: {
    updateRecord () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      CarFuelingApi.updateItem(this.fueling)
        .then(() => {
          this.isUpdating = false
          this.$router.push('/fueling')
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi/cars', {
      loadCars: 'loadItems'
    }),
    ...mapActions('fuel/fuelType', {
      loadFuels: 'loadItems'
    }),
    ...mapActions('fuel/carFueling', {
      loadFueling: 'loadItems'
    }),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
