<template>
  <div class="page contracts-templates">
    <button class="btn btn-primary w-100" @click="$refs.files.click()">Загрузить шаблон</button>
    <input
      type="file"
      ref="files"
      @change="addFilesFromDialog"
      style="display:none"
      multiple
    />

    <nav class="nav nav-tabs mt-3">
      <a
        class="nav-item nav-link"
        :class="{ 'active': currentTab === 'contractTemplates' }"
        role="button"
        tabindex="0"
        @click="setContractTemplatesTab"
      >
        Договоры
      </a>
      <a
        class="nav-item nav-link"
        :class="{ 'active': currentTab === 'receiveListsTemplates' }"
        role="button"
        tabindex="0"
        @click="setReceiveListsTemplatesTab"
      >
        Акты возврата
      </a>
      <a
        class="nav-item nav-link"
        :class="{ 'active': currentTab === 'waybillTemplates' }"
        role="button"
        tabindex="0"
        @click="setWaybillTemplatesTab"
      >
        Путевые листы
      </a>
    </nav>
    <div class="tab-content">
      <!-- ШАБЛОНЫ ДОГОВОРОВ -->
      <div v-if="currentTab === 'contractTemplates'">
        <div class="card file-card" v-for="file of contractTemplates" :key="file.id">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <font-awesome-icon icon="file-word" style="color:#25559a" />
                {{ file.name }}
              </div>
              <div class="col-lg-auto mb-6 date">
                {{ file.created_at }}
                <div class="btn" role="button" tabindex="0" @click="archivateFile(file.id)">
                  <font-awesome-icon icon="trash-alt" style="color:gray" />
                </div>
                <a class="btn" :href="`/file/link?id=${file.id}`">
                  <font-awesome-icon icon="download" style="color:gray" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /ШАБЛОНЫ ДОГОВОРОВ -->
      <!-- ШАБЛОНЫ АКТОВ ВОЗВРАТА -->
      <div v-if="currentTab === 'receiveListsTemplates'">
        <div class="card file-card" v-for="file of receiveListsTemplates" :key="file.id">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <font-awesome-icon icon="file-word" style="color:#25559a" />
                {{ file.name }}
              </div>
              <div class="col-lg-auto mb-6 date">
                {{ file.created_at }}
                <div class="btn" role="button" tabindex="0" @click="archivateFile(file.id)">
                  <font-awesome-icon icon="trash-alt" style="color:gray" />
                </div>
                <a class="btn" :href="`/file/link?id=${file.id}`">
                  <font-awesome-icon icon="download" style="color:gray" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /ШАБЛОНЫ АКТОВ ВОЗВРАТА -->
      <!-- ШАБЛОНЫ ПУТЕВЫХ ЛИСТОВ -->
      <div v-if="currentTab === 'waybillTemplates'">
        <div class="card file-card" v-for="file of waybillTemplates" :key="file.id">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <font-awesome-icon icon="file-word" style="color:#25559a" />
                {{ file.name }}
              </div>
              <div class="col-lg-auto mb-6 date">
                {{ file.created_at }}
                <div class="btn" role="button" tabindex="0" @click="archivateFile(file.id)">
                  <font-awesome-icon icon="trash-alt" style="color:gray" />
                </div>
                <a class="btn" :href="`/file/link?id=${file.id}`">
                  <font-awesome-icon icon="download" style="color:gray" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /ШАБЛОНЫ ПУТЕВЫХ ЛИСТОВ -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FilesAPI from '@/api/files'

export default {
  name: 'Templates',
  data () {
    return {
      files: [],
      inputFile: '',
      dragging: false,
      preDragging: false
    }
  },
  mounted () {
    this.loadAllTabs()
    this.setTitle({
      items: [
        {
          title: 'Шаблоны'
        }
      ]
    })
  },
  computed: {
    ...mapState('taxi/templates', {
      isLoading: state => state.isLoading,
      contractTemplates: state => state.contractTemplates,
      waybillTemplates: state => state.waybillTemplates,
      receiveListsTemplates: state => state.receiveListsTemplates,
      currentTab: state => state.currentTab
    })
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/templates', [
      'loadCurrentTab',
      'setSearchText',
      'loadAllTabs',
      'setContractTemplatesTab',
      'setWaybillTemplatesTab',
      'setReceiveListsTemplatesTab',
      'abortLoading',
      'uploadTemplates'
    ]),
    addFilesFromDrop (e) {
      this.uploadTemplates(e.dataTransfer.files)
    },
    addFilesFromDialog (e) {
      this.uploadTemplates(e.target.files)
      this.$refs.files.value = null
    },

    getTemplate (id) {
      FilesAPI.getItem({ id }).then(template => {
        this.files.unshift(template)
      })
    },
    archivateFile (id) {
      FilesAPI.archivateItem(id).then(toRemove => {
        // TODO: оптимизировать
        this.loadAllTabs()
      })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  },
  watch: {
    currentTab () {
      this.loadCurrentTab()
    }
  }
}
</script>

<style scoped>
  .tab-content {
    z-index: 5;
  }
  .contracts-templates {
    width: 98%;
  }
  .date {
    min-width: 8rem;
  }
  .file-card {
    margin-top: 1rem;
  }
</style>
