<template>
  <form class="edit-contracts">
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorTemplate">Шаблон документа</label>
        <VSelect
          :options="config.templates.contracts"
          v-model="config.contract.file_id"
          :reduce="template => template.id"
          label="nameWithDate"
          id="selectorTemplate"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorTemplate">Шаблон акта возврата</label>
        <VSelect
          :options="config.templates.receiveLists"
          v-model="config.contract.receive_list_id"
          :reduce="template => template.id"
          label="nameWithDate"
          id="selectorTemplate"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorDriver">Водитель</label>
        <VSelect
          :options="config.drivers"
          v-model="config.contract.driver_id"
          :reduce="driver => driver.id"
          label="fullName"
          id="selectorDriver"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorCar">Автомобиль</label>
        <VSelect
          :options="filterAvilible(config.cars)"
          v-model="config.contract.car_id"
          :reduce="car => car.id"
          label="displayedName"
          id="selectorCar"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorCompany">Организация</label>
        <VSelect
          :options="config.companies"
          v-model="config.contract.company_id"
          :reduce="company => company.id"
          label="name"
          id="selectorCompany"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="inputSRDate">Дата/ время передачи автомобиля</label>
        <input
          type="datetime-local"
          class="form-control"
          v-model="config.contract.transfer_datetime"
          id="inputStartRDate"
          min="2010-06-01T08:30"
          max="2050-06-30T16:30"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
      <div class="form-group col">
        <label for="inputEndRDate">Дата/ время возврата автомобиля</label>
        <input
          type="datetime-local"
          class="form-control"
          id="inputEndRDate"
          v-model="config.contract.receiving_datetime"
          min="2010-06-01T08:30"
          max="2050-06-30T16:30"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
    </div>
    <div v-if="config.contract.archived_at" class="form-row row">
      <div class="form-group col">
        <label for="inputEndRDateFact">Дата/ время принятия автомобиля</label>
        <input
          type="datetime-local"
          class="form-control"
          v-model="config.contract.archived_at"
          id="inputEndRDateFact"
          min="2010-06-01T08:30"
          max="2050-06-30T16:30"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="input-r-spot">Адрес выдачи</label>
        <dadata-suggestions
          type="ADDRESS"
          class="form-control"
          v-model="config.contract.transfer_spot"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
      <div class="form-group col">
        <label for="input-er-spot">Адрес возврата</label>
        <dadata-suggestions
          type="ADDRESS"
          class="form-control"
          v-model="config.contract.receiving_spot"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="inputPrice">Арендная плата</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="currency-addon">&#8381;</span>
          </div>
          <IMaskComponent
            type="text"
            :mask="Number"
            :min="0"
            :max="10000"
            thousandsSeparator=" "
            v-model="config.contract.price"
            :unmask="true"
            :lazy="false"
            placeholder="Цена аренды"
            class="form-control"
            required
            addon="currency"
            id="inputPrice"
            :disabled="config.contract.status !== 'draft'"
          />
        </div>
      </div>
      <div class="form-group col">
        <label for="inputPeriod">Период оплаты</label>
        <select
          class="form-control"
          id="inputPeriod"
          v-model="config.contract.payment_period"
          :disabled="config.contract.status !== 'draft'"
        >
          <option value="day">Посуточно</option>
          <option value="month">Помесячно</option>
        </select>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ContractRentEdit',
  inject: ['config'],
  computed: {
    filterAvilible () {
      return cars => cars.filter(car => !car.isServe || car.id === this.config.contract.car_id)
    }
  }
}
</script>

<style scoped>
  .edit-contracts {
    margin-top: 4rem;
  }
</style>
