import Rent from '@/views/Rent'
import RentPage from '@/views/rent/RentPage'
import CreateRent from '@/views/rent/CreateRent'

export default [
  {
    path: '/rent',
    component: Rent,
    meta: {
      title: 'Аренда',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/rent/create',
    component: CreateRent,
    props: true,
    meta: {
      title: 'Арендовать автомобиль',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/rent/:contractId',
    component: RentPage,
    name: 'rentPage',
    props: true,
    meta: {
      title: 'Аренда',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
