<template>
  <div class="show-form-users">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Фамилия</td>
          <td>{{ user.last_name }}</td>
        </tr>
        <tr>
          <td>Имя</td>
          <td>{{ user.first_name }}</td>
        </tr>
        <tr>
          <td>Отчество</td>
          <td>{{ user.middle_name }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{{ user.email }}</td>
        </tr>
        <tr>
          <td>Логин</td>
          <td>{{ user.username }}</td>
        </tr>
        <tr>
          <td>Роли</td>
          <td>{{ user.roles ? Object.keys(user.roles).join(', ') : ''}}</td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4" />
  </div>
</template>

<script>
export default {
  name: 'UserShow',
  props: ['user']
}
</script>

<style scoped>
  .show-form-users {
    margin-top: 2rem;
  }
</style>
