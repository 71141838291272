<template>
  <router-link
    class="text-decoration-none text-dark"
    :class="{ 'selected': lastSelectedId === car.id }"
    :to="'/watch/' + car.id"
  >
    <div class="p-2 card-car w-100 m-0 d-flex justify-content-between">
      <div class="d-flex card-car-title">
        <font-awesome-icon
          v-if="car.lastState && car.lastState.trip && car.lastState.trip.value"
          icon="car"
          size="1x"
          :style="{ color: '#92BF4E', width: '20px', height: '20px' }"
        />
        <font-awesome-icon
          v-else
          icon="parking"
          size="1x"
          :style="{ color: '#62ADBF', width: '20px', height: '20px' }"
        />
        <RegistrationPlate
          class="ml-2"
          size="small"
          :reg-number="car.reg_plate_number"
          :reg-region="car.reg_plate_region"
        />
        <span class="pl-2">{{ car.brand }} {{ car.model }}</span>
      </div>
      <div class="text-right text-nowrap">
        <WatchState :location="car.lastState" />
      </div>
    </div>
    <hr class="my-0">
  </router-link>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapActions, mapState } from 'vuex'
import commandsAPI from '@/api/commands'
import RegistrationPlate from '../../car/RegistrationPlate'
export default {
  name: 'MapCard',
  components: { RegistrationPlate },
  props: ['car'],
  data () {
    return {
      sTrackDate: null,
      eTrackDate: null
    }
  },
  mounted () {
    const now = new Date()
    const hrAgo = new Date(Date.now() - 60 * 60 * 1000)
    this.sTrackDate = `${hrAgo.toLocaleDateString('en-CA')}T${hrAgo.getHours().toString().padStart(2, '0')}:${hrAgo.getMinutes().toString().padStart(2, '0')}`
    this.eTrackDate = `${now.toLocaleDateString('en-CA')}T${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`
  },
  computed: {
    isSelected () {
      return this.selectedCar && this.car.id === this.selectedCar.id
    },
    ...mapGetters('taxi/mapCars', [
      'selectedCar'
    ]),
    ...mapState('taxi/mapCars', {
      lastSelectedId: state => state.lastSelectedId
    })
  },
  methods: {
    ...mapActions('taxi/mapCars', [
      'selectCar',
      'getTrack',
      'clearTrack'
    ]),
    toLocalTime (date) {
      return new Date(date).toLocaleString()
    },
    track () {
      this.getTrack({
        id: this.selectedCar.id,
        timeFrom: new Date(this.sTrackDate).toISOString(),
        timeTo: new Date(this.eTrackDate).toISOString()
      })
    },
    disableIgnition () {
      Swal.fire({
        title: 'Отключить зажигание?',
        showCancelButton: true,
        confirmButtonText: 'Отправить',
        cancelButtonText: 'Назад'
      }).then((result) => {
        if (result.value) {
          commandsAPI.sendCommand({
            imei: this.selectedCar.imei,
            command: 'setdigout 1'
          })
            .then(answer => this.deviceAnswer(answer.result.response))
        }
      })
    },
    enableIgnition () {
      commandsAPI.sendCommand({
        imei: this.selectedCar.imei,
        command: 'setdigout 0'
      })
        .then(answer => this.deviceAnswer(answer.result.response))
    },
    anyCommand () {
      Swal.fire({
        title: 'Введите комманду',
        input: 'text',
        inputValidator: (value) => {
          if (!value) {
            return 'Команда не может быть пустой!'
          }
        }
      }).then(command => {
        if (!command.dismiss) {
          commandsAPI.sendCommand({
            imei: this.selectedCar.imei,
            command: command.value
          })
            .then(answer => this.deviceAnswer(answer.result.response))
        }
      })
    },
    deviceAnswer (answer) {
      Swal.fire({
        title: 'Ответ устройства',
        text: answer
      })
    }
  }
}
</script>

<style scoped>
  .card-car:hover {
    background: #e6e6e6;
  }
  @media screen and (max-width: 400px) {
    .card-car {
      display: block !important;
    }
  }
  .card-car-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
