<template>
  <div class="page">
    <h3 class="header-title-mobile">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <div class="row">
      <div class="col-12">
        <label>Название</label>
        <input type="text" class="form-control" v-model="serviceWork.name" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Интервал обслуживания</label>
        <input type="text" class="form-control" v-model="serviceWork.interval" placeholder required />
      </div>
    </div>
    <hr class="mb-4" />
    <div class="float-right btn-group">
      <button class="btn btn-primary" @click="createServiceWork()">Создать</button>
      <router-link to="/maintenance/service-works" class="btn btn-primary">Назад</router-link>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import serviceWorkApi from '../../api/serviceWork'

export default {
  name: 'CreateServiceWork',
  props: ['id'],
  data () {
    return {
      isCreating: false,
      parentWork: null,
      serviceWork: {
        name: '',
        interval: '',
        sort: '',
        parent_work_id: null
      }
    }
  },
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Тех. обслуживание',
          url: '/maintenance'
        }, {
          title: 'Работы',
          url: '/maintenance/service-works'
        }, {
          title: 'Добавить'
        }
      ]
    })
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  methods: {
    createServiceWork () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      serviceWorkApi.createItem(this.serviceWork)
        .then(work => {
          this.isCreating = false
          this.$router.push('/maintenance/service-works')
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('taxi/serviceWorks', ['getWorkById'])
  },

  beforeMount () {
    if (this.id) {
      this.getWorkById(this.id)
        .then(work => {
          this.parentWork = work
          this.serviceWork.parent_work_id = this.id
        })
    }
  }
}
</script>
