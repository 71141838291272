<template>
  <div class="show-form-cars">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Фамилия</td>
          <td><input type="text" class="form-control form-control-sm" v-model="driver.last_name" placeholder required></td>
        </tr>
        <tr>
          <td>Имя</td>
          <td><input type="text" class="form-control form-control-sm" v-model="driver.first_name" placeholder required></td>
        </tr>
        <tr>
          <td>Отчетво</td>
          <td>
            <input type="text" class="form-control form-control-sm" v-model="driver.middle_name" placeholder required>
          </td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>
            <IMaskComponent
              type="text"
              name="phone"
              class="form-control form-control-sm"
              v-model="driver.phone"
              :mask="'+{7} (000) 000-00-00'"
              :unmask='false'
              placeholder='+7 (000) 000-00-00'
            />
          </td>
        </tr>
        <tr>
          <td>Адрес проживания</td>
          <td>
            <dadata-suggestions
              type="ADDRESS"
              class="form-control form-control-sm"
              v-model="driver.address"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4" />
  </div>
</template>

<script>
export default {
  name: 'DriverEdit',
  props: ['id', 'driver']
}
</script>

<style scoped>
  .show-form-cars {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
