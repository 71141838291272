<template>
  <div class="dropdown">
    <input
      @focus="show = true"
      @blur="show = false"
      v-model="filterText"
      class="form-control"
      :placeholder="placeholder"
    >
    <ul
      v-if="optionsBeforeFilter.length"
      style="max-height: 300px"
      class="dropdown-menu py-0 overflow-auto w-100 shadow"
      :class="{show}"
    >
      <li v-for="(option, key) in optionsBeforeFilter" :key="key">
        <a class="dropdown-item" role="button" @mousedown="handleClick(key)">{{ option }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'LookupInput',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: String,
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      filterText: '',
      optionsBeforeFilter: [],
      show: false
    }
  },
  methods: {
    handleClick (key) {
      this.show = false
      this.$emit('change', this.optionsBeforeFilter[key])
      this.filterText = this.optionsBeforeFilter[key]
    },
    filter (text) {
      this.optionsBeforeFilter = this.options.filter(val => {
        return val.toLowerCase().indexOf(text.toLowerCase()) !== -1
      })
    }
  },
  watch: {
    filterText: {
      handler () {
        this.$emit('change', this.filterText)
        this.filter(this.filterText)
      },
      immediate: true
    },
    options: {
      handler () {
        this.filter(this.filterText)
      },
      immediate: true
    }
  }
})
</script>
