<template>
  <div class="page page-cars">
    <h3 class="header-title-mobile">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <div class="float-right">
      <router-link
        :to="'/watch/' + car.id"
        class="btn btn-secondary mx-1"
        tag="button"
      >
        <font-awesome-icon class="text-white" icon="external-link-alt" size="1x"/>
        Отслеживание
      </router-link>
      <button v-if="!isEditing" class="btn btn-danger mx-1" @click="deleteCar()">
        <font-awesome-icon class="text-white" icon="trash-alt" size="1x"/>
        Удалить
      </button>
      <button v-if="!isEditing" class="btn btn-primary mx-1" @click="changeCar()">
        <font-awesome-icon class="text-white" icon="edit" size="1x"/>
        Изменить
      </button>
      <button v-if="isEditing" class="btn btn-secondary mx-1" @click="cancelCarChanges()">Отменить</button>
      <button v-if="isEditing" class="btn btn-primary mx-1" @click="updateCar()">Сохранить</button>
    </div>
    <br/>
    <CarEditForm
      v-if="isEditing"
      :car="car"
      :trackingDevicesList="trackingDevicesList"
      :prev-car="prevCar"
    />
    <CarShowForm v-else :car="car" />
    <div class="car-equipments">
      <EquipmentsView
        :equipments="car.equipments"
        @actionEdit="$router.push(`/cars/${carId}/equipments`)"
      />
    </div>
    <hr class="mb-4" />
    <div class="car-docs">
      <h5>
        Документы
        <router-link :to="`/cars/${carId}/docs/create`" class="btn btn-primary float-right">
          <font-awesome-icon class="text-white" icon="plus" size="1x"/>
          Добавить
        </router-link>
      </h5>
      <br />
      <LightBox
        v-if="showDetailImage"
        @actionClose="showDetailImage = false"
        :scan="currentScan"
      />
      <Document
        v-for="document of car.documents"
        :key="document.id"
        :document="document"
        @actionDetailImage="detailImage(currentScan)"
        @actionDelte="deleteDocument(document.id)"
        @actionUpdate="$router.push(`/cars/${carId}/docs/${document.id}/update`)"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import Swal from 'sweetalert2'
import CarsAPI from '@/api/cars'
import deepAssign from 'assign-deep'
import EquipmentsView from './EquipmentsView'
import DocumentsAPI from '@/api/car-documents'
import { mapMutations, mapActions, mapState } from 'vuex'

export default {
  name: 'CarPage',
  components: {
    EquipmentsView
  },
  props: ['carId'],
  data () {
    return {
      isUpdating: false,
      car: {
        brand: '',
        model: '',
        reg_plate_number: '',
        reg_plate_region: '',
        manufacturing_year: '',
        radio_code: '',
        data: ''
      },
      trackingDevicesList: [],
      showDetailImage: false,
      isEditing: false,
      prevCar: null,
      currentScan: null
    }
  },
  mounted () {
    this.loadData()
    this.loadItems()
      .then(cars => {
        this.loadDeviceItems().then(result => {
          this.trackingDevicesList = result.filter(device => {
            if (this.car.tracking_device_id === device.id || !cars.find(car => car.tracking_device_id === device.id)) {
              return device
            }
          })
        })
      })
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  methods: {
    loadData () {
      CarsAPI.getItem({
        id: this.carId,
        queryWith: ['documents', 'isServe', 'equipments', 'trackingDevice', 'lastState']
      })
        .then(response => {
          this.car = response
          this.loadScans()
        })
    },
    loadScans () {
      this.clearItems()
      this.setModel('CarDocument')
      this.car.documents.forEach(element => {
        DocumentsAPI.getScans(element.id).then(result => {
          element.scans = result
          this.pushItems({ scans: result, documentId: element.id })
        })
          .catch(err => {
            console.error(err)
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              text: err.message
            })
          })
      })
    },
    changeCar () {
      this.prevCar = deepAssign({}, this.car)
      this.isEditing = true
    },
    deleteCar () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить автомобиль?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          CarsAPI.removeItem(this.carId)
            .then(() => {
              this.$router.push('/cars')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    cancelCarChanges () {
      this.car = this.prevCar
      this.isEditing = false
    },
    updateCar () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      if (!CarsAPI.validateRegistrationPlate(this.car.reg_plate_region, this.car.reg_plate_number)) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Регистрационный номер не соответствует формату'
        })
        this.isUpdating = false
        return
      }
      CarsAPI.updateItem(this.car)
        .then(car => {
          this.car = { ...this.car, ...car.model }
          this.car.tracking_device_id = parseInt(this.car.tracking_device_id)
          this.car.trackingDevice = this.trackingDevicesList.find(e => {
            return e.id === this.car.tracking_device_id
          })
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    deleteDocument (documentId) {
      DocumentsAPI.removeItem(documentId)
        .then(() => this.loadData())
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', ['setTitle']),
    ...mapActions('taxi/cars', ['loadItems']),
    ...mapActions('taxi/trackDevice', {
      loadDeviceItems: 'loadItems'
    }),
    ...mapMutations('taxi/scans', [
      'pushItems',
      'clearItems',
      'setModel'
    ])
  },
  watch: {
    car: {
      handler (car, carOld) {
        if (car?.id && car?.id !== carOld?.id) {
          this.setTitle({
            items: [
              {
                title: 'Автомобили',
                url: '/cars'
              }, {
                title: car.brand + ' ' + car.model + ' ' + car.registration_plate
              }
            ]
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
  .page-cars {
    width: 98%;
    padding-bottom: 4rem;
    padding-top: 2.5rem;
  }
</style>
