<template>
  <div class="page page-watch">
    <div class="split-container">
      <OLMap class="map-container" ref="map" />
      <MapRightPanel @findCar="$refs.map.findCarOnMap()" class="menu-container" />
    </div>
  </div>
</template>

<script>
import OLMap from '@/components/maps/OLMap'
import MapRightPanel from '@/components/maps/MapRightPanel'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Watch',
  components: {
    OLMap,
    MapRightPanel
  },
  props: {
    carId: String
  },
  mounted () {
    if (!this._timeout) {
      this.startWatch()
    }
    this.setTitle({
      items: [
        {
          title: 'Наблюдение',
          url: '/watch'
        },
        {
          title: 'Карта'
        }
      ]
    })
  },
  computed: {
    ...mapState('taxi/mapCars', {
      cars: state => state.items.filter(car => car.imei),
      isLoading: state => state.isLoading
    })
  },
  methods: {
    ...mapActions('taxi/mapCars', [
      'loadItems',
      'abortLoading',
      'selectCar'
    ]),
    startWatch () {
      if (!this.isLoading) {
        this.loadItems()
      }
      this._timeout = setTimeout(() => { this.startWatch() }, 4000)
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  },
  beforeDestroy () {
    clearTimeout(this._timeout)
    this._timeout = null
  },
  watch: {
    carId: {
      immediate: true,
      handler (carId) {
        if (carId == undefined) {
          this.selectCar(null)
        } else {
          this.selectCar(parseInt(carId))
        }
      }
    }
  }
}
</script>

<style scoped>
  .page-watch {
    height: 100%;
    padding: 0;
  }
  .split-container {
    display: flex;
    height: 100%;
  }
  .map-container {
    flex-grow: 1;
  }
  .menu-container {
    width: 500px;
    overflow-y: scroll;
  }
  @media screen and (max-width: 768px) {
    .split-container {
      display: block;
      overflow-y: scroll;
    }
    .map-container {
      height: 500px;
    }
    .menu-container {
      width: 100%;
      overflow-y: hidden;
    }
  }
</style>
