<template>
  <div class="page">
    <h1 class="h2">Изменить документ</h1>
    <component
      v-if="currentComponent"
      :is="currentComponent"
      :documentData="document.data"
      @update="handleData"
    />
    <button class="btn btn-primary" @click="updateDocument()">Записать</button>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import Swal from 'sweetalert2'
import Types from '@/views/carDocuments/types'
import DocumentsAPI from '@/api/car-documents'
import CarsAPI from '@/api/cars'
import { mapActions } from 'vuex'

const components = {}
for (const typeName in Types) {
  components[Types[typeName].edit.name] = Types[typeName].edit.component
  components[Types[typeName].view.name] = Types[typeName].view.component
}

export default {
  name: 'UpdateDocument',
  components,
  props: ['carId', 'documentId'],
  data () {
    return {
      isUpdating: false,
      currentComponent: null,
      document: {
        id: this.documentId,
        type: '',
        data: '',
        car_id: ''
      }
    }
  },
  mounted () {
    DocumentsAPI.getItem({ id: this.documentId })
      .then(response => {
        this.document = response
        this.currentComponent = Types[this.document.type].edit.name
      })
    CarsAPI.getItem({ id: this.carId })
      .then(car => {
        this.setTitle({
          items: [
            {
              title: 'Автомобили',
              url: '/cars/'
            }, {
              title: car.brand + ' ' + car.model + ' ' + car.registration_plate,
              url: '/cars/' + this.carId
            }, {
              title: 'Изменить документ'
            }
          ]
        })
      })
    this.setTitle({
      items: [
        {
          title: 'Документы',
          url: '/cars/' + this.carId
        }, {
          title: 'Изменить'
        }
      ]
    })
  },
  methods: {
    handleData ({ type, data }) {
      this.document.type = type
      this.document.data = data
    },
    updateDocument () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      DocumentsAPI.updateItem(this.document)
        .then(document => {
          this.isUpdating = false
          this.$router.push(`/cars/${this.carId}`)
          return document
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
