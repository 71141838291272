<template>
  <div class="page">
    <h3 class="header-title-mobile">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <br><br>
    <div class="row">
      <div class="col col-xl-7">
        <label>Фамилия</label>
        <input type="text" class="form-control" v-model="user.last_name" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Имя</label>
        <input type="text" class="form-control" v-model="user.first_name" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Отчество (не обязательно)</label>
        <input type="text" class="form-control" v-model="user.middle_name" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Email</label>
        <input type="text" class="form-control" v-model="user.email" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Логин</label>
        <input type="text" class="form-control" v-model="user.username" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Пароль</label>
        <input type="password" class="form-control" v-model="user.password" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Роли</label>
        <Mselect
          v-if="accessibleRoles && user.roles"
          v-model="user.roles"
          :options="accessibleRoles"
          option-key="name"
          option-label="name"
        />
      </div>
    </div>
    <hr class="mb-4" />
    <button class="btn btn-primary" @click="createUser()">Создать</button>
  </div>
</template>

<script>
import userApi from '@/api/user'
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CreateUser',
  data () {
    return {
      isUpdating: false,
      user: {
        first_name: '',
        middle_name: '',
        last_name: '',
        username: '',
        password: '',
        email: '',
        roles: []
      },
      accessibleRoles: []
    }
  },
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Пользователи',
          url: '/users'
        },
        {
          title: 'Добавить'
        }
      ]
    })
    userApi
      .getAccessibleRoles()
      .then(roles => {
        this.accessibleRoles = Object.values(roles)
        this.user.roles.push(roles['Пользователь'].name)
      })
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  methods: {
    createUser () {
      userApi
        .createItem(this.user)
        .then(user => {
          this.$router.push(`/users/${user.model.id}`)
        })
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style scoped>
  .btn-primary {
    width: 8rem;
    margin-left: .4rem;
    float: right !important;
  }
  .row {
    margin-bottom: 0.5rem;
  }
</style>
