<template>
  <div class="scans">
    <div class="upload-from-dialog">
      <button @click="$refs.scan.click()" class="btn btn-primary float-right">Загрузить...</button>
    </div>
    <input
      type="file"
      ref="scan"
      @change="addFileFromDialog"
      style="display:none"
      multiple
    />

    <ScanCard
      v-for="scan of scansByDocument(this.documentId)"
      :key="scan.id"
      :scan="scan"
      @detail-scan="detailScan(scan)"
      @remove-scan="removeItem(scan.id)"
      @update-scan="updateScan"
      @upload-scan="uploadScan(scan)"
    />
    <LightBox v-if="showDetail" @close="showDetail = false" :scan="currentScan" />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ScanCard from './ScanCard'

export default {
  name: 'ScansGallery',
  components: {
    ScanCard
  },
  props: ['documentId'],
  data () {
    return {
      dragging: false,
      showDetail: false,
      currentScan: null
    }
  },
  computed: {
    ...mapGetters('taxi/scans', [
      'scansByDocument'
    ]),
    ...mapState('taxi/scans', [
      'currentModel'
    ])
  },
  methods: {
    detailScan (scan) {
      this.currentScan = scan
      this.showDetail = true
    },
    addFileFromDialog (e) {
      this.addFiles(e.target.files)
      this.$refs.scan.value = null
    },
    addFileFromDrop (e) {
      this.addFiles(e.dataTransfer.files)
    },
    addFiles (files) {
      const formData = new FormData()
      files.forEach(element => {
        formData.append('upfiles[]', element)
      })
      formData.append('model', this.currentModel)
      formData.append('id', this.documentId)
      this.dragging = false
      this.uploadItem(formData)
    },
    updateScan ({ scan }) {
      this.updateItem(scan)
    },
    ...mapActions('taxi/scans', [
      'uploadItem',
      'removeItem',
      'updateItem'
    ])
  }
}
</script>

<style scoped>
  .scans {
    min-height: 10rem;
  }
  .upload-from-dialog {
    float: right;
    position: relative;
    width: 100%;
    margin-bottom: .7rem;
    z-index: 10 !important;
  }
</style>
