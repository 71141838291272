<template>
  <div class="page-login">
    <form class="form-signin" action="/site/login" method="post">
      <div class="text-center mb-4">
        <h1 class="h3 mb-3 font-weight-normal">Ввод</h1>
      </div>

      <div class="form-label-group">
        <label class="w-100">Пользователь
          <input
            name="LoginForm[username]"
            class="form-control"
            placeholder="Имя пользователя"
            required
            autofocus
          >
        </label>
      </div>

      <div class="form-label-group">
        <label class="w-100">Пароль
          <input
            type="password"
            name="LoginForm[password]"
            class="form-control"
            placeholder="Пароль"
            required
          >
        </label>
      </div>
      <br>
      <!--div class="checkbox mb-3">
        <label>
          <input type="checkbox" name="LoginForm[rememberMe]"> Запомнить меня
        </label>
      </div-->
      <button class="btn btn-lg btn-primary btn-block" type="submit">Вход</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Login'
}
</script>

<style scoped>
  .page-login {
    max-width: 420px;
    width: 100%;
    position: fixed;
    left: 50%;
    top: 33%;
    transform: translate(-50%, -33%);
    padding: 10px;
  }

  label {
    padding: 10px 0px 0px 0px;
  }
</style>
