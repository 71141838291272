<template>
  <div>
      <div
        class="row w-100 m-0 p-2 border-top"
        @click="showDetail = !showDetail"
        role="button"
        tabindex="0"
      >
        <div class="col">
          {{ setFormat(trip.start_date) }}
        </div>
        <div class="col">
          {{ trip.dayly_odometer }}
        </div>
        <div class="col-auto">
          <font-awesome-icon
            :icon="showDetail ? 'chevron-up' : 'chevron-down'"
            class="text-secondary"
            size="1x"
          />
        </div>
      </div>
      <table class="table borderless" v-if="showDetail">
        <tbody>
          <tr>
            <td>Показания одометра начальное</td>
            <td>{{ trip.start_odometer }}</td>
          </tr>
          <tr>
            <td>Показания одометра конечное</td>
            <td>{{ trip.end_odometer }}</td>
          </tr>
          <tr>
            <td>Начальный адерес</td>
            <td>{{ trip.startAddress }}</td>
          </tr>
          <tr>
            <td>Конечный адрес</td>
            <td>{{ trip.endAddress }}</td>
          </tr>
          <tr>
            <td colspan="2">
              <button
                class="btn btn-primary float-right"
                @click="getTrackDay(trip.start_date, trip.end_date)"
              >
                <span v-if="!loadingTrack">Показать</span>
                <div v-else class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="sr-only">Загрузка...</span>
                </div>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'FuelStatisticTrip',
  props: ['trip', 'carId'],
  data () {
    return {
      dateStart: null,
      showDetail: false,
      loadingTrack: false
    }
  },
  computed: {
    setFormat () {
      return (date) => {
        return dayjs(date).format('DD.MM.YYYY')
      }
    }
  },
  methods: {
    ...mapActions('taxi/mapCars', [
      'getTrack'
    ]),
    getTrackDay (sTime, eTime) {
      if (!this.loadingTrack) {
        this.loadingTrack = true
        this.getTrack({
          id: this.carId,
          timeFrom: dayjs(sTime).format('YYYY-MM-DDTHH:mm:ss'),
          timeTo: dayjs(eTime).format('YYYY-MM-DDTHH:mm:ss')
        })
          .then(() => {
            this.loadingTrack = false
          })
          .catch(error => {
            this.loadingTrack = false
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              text: error.message
            })
          })
      }
    }
  }
}
</script>
