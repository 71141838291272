<template>
  <div class="wrapper" v-if="selectedCar">
    <div class="sticky-header" style="z-index: 1">
      <div class="text-center h5 p-3 pl-5">
        {{ selectedCar.brand }} {{ selectedCar.model }} {{ selectedCar.registration_plate }}
        <span class="car-buttons">
          <div
            class="btn"
            role="button"
            tabindex="0"
            @click="findCar"
          >
            <font-awesome-icon icon="search-location" />
          </div>
          <div
            class="btn"
            role="button"
            tabindex="0"
            @click="$router.push('/cars/' + selectedCar.id)"
          >
            <font-awesome-icon icon="edit" />
          </div>
          <div
            class="btn"
            role="button"
            tabindex="0"
            @click="$router.push('/watch')"
          >
            <font-awesome-icon icon="times" />
          </div>
        </span>
      </div>
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs justify-content-center">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#info">Инфо</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#history">История</a>
          </li>
          <li class="nav-item" v-if="hasFuelModule">
            <a class="nav-link" data-toggle="tab" href="#fuel">Топливо</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#commands">Команды</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div class="tab-content">
        <!--<INFO>-->
        <div class="tab-pane fade active show" id="info">
          <MapCardInfo />
        </div>
        <!--</INFO>-->
        <!--<HISTORY>-->
        <div class="tab-pane fade" id="history"><MapCardHistory /></div>
        <!--</HISTORY>-->
        <!--<FUEL>-->
        <div class="tab-pane fade" id="fuel" v-if="hasFuelModule"><FuelStatistic /></div>
        <!--</FUEL>-->
        <!--<COMMANDS>-->
        <div class="tab-pane fade" id="commands"><MapCardCommands /></div>
        <!--</COMMANDS>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
const components = {}
if (process.env.VUE_APP_MODULE_FUEL === 'true') {
  components.FuelStatistic = require('@/modules/fuel/components/FuelStatistics').default
}

export default {
  name: 'CardSelected',
  components: components,
  computed: {
    ...mapGetters('taxi/mapCars', ['selectedCar']),
    hasFuelModule () {
      return process.env.VUE_APP_MODULE_FUEL === 'true'
    }
  },
  watch: {
    selectedCar: {
      handler (car, carOld) {
        if (car?.id !== carOld?.id) {
          this.getStartTrack({
            id: car.id,
            startTimeOfTrack: dayjs(new Date() - (60 * 60 * 1000) * 6).format('YYYY-MM-DDTHH:mm:ss'),
            endTimeOfTrack: dayjs(new Date() - (60 * 60 * 1000) * 5).format('YYYY-MM-DDTHH:mm:ss')
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('taxi/mapCars', [
      'getStartTrack',
      'getTrack',
      'selectCar',
      'clearTrack'
    ]),
    findCar () {
      this.clearTrack()
      this.$emit('findCar')
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-header {
    background: white !important;
  }
  .card-header {
    padding: 0;
  }
  .sticky-header {
    position: sticky;
    top: 0;
    background: white;
  }
  .card-header-tabs {
    margin: 0;
  }
  .wrapper {
    background: white;
  }
  .back-button {
    color: #55606a;
    opacity: 0.7;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }
  .back-button:hover {
    opacity: 1 !important;
  }
  .car-brief-area {
    text-align: center;
  }

  .car-buttons {
    margin: 0 5px;
  }
  .car-buttons .btn {
    color: grey;
  }
</style>
