<template>
  <div class="page">
    <h3 class="header-title-mobile">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <div class="row">
      <div class="col-md-6 mb-2">
        <label>IMEI</label>
        <input type="text" class="form-control" v-model="trackDevice.imei" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Тип прошивки</label>
        <VSelect
          :options="firmwares"
          v-model="trackDevice.firmware_id"
          :reduce="firmware => firmware.id"
          label="name"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-2">
        <label>Название</label>
        <input type="text" class="form-control" v-model="trackDevice.name" placeholder required />
      </div>
    </div>
    <hr class="mb-4" />
    <button class="btn btn-primary float-right" @click="createTrackDevice()">Создать</button>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import trackDeviceApi from '@/api/trackDevice'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'createTrackDevice',
  mounted () {
    trackDeviceApi.getFirmwares().then(response => {
      this.firmwares = response
    })
    this.setTitle({
      items: [
        {
          title: 'Наблюдение',
          url: '/watch'
        },
        {
          title: 'Устройства',
          url: '/devices'
        },
        {
          title: 'Добавить'
        }
      ]
    })
  },
  data () {
    return {
      trackDevice: {},
      firmwares: []
    }
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  methods: {
    createTrackDevice () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      trackDeviceApi.createItem(this.trackDevice)
        .then(trackDevice => {
          this.isCreating = false
          this.$router.push(`/devices/${trackDevice.model.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    generateNameTrackingDevice () {
      this.trackDevice.name = ''
      if (this.trackDevice?.imei || this.trackDevice.firmware_id) {
        if (this.trackDevice.firmware_id) {
          this.trackDevice.name += this.firmwares[this.trackDevice.firmware_id - 1].name
        }
        if (this.trackDevice?.imei && this.trackDevice.firmware_id) {
          this.trackDevice.name += '-'
        }
        if (this.trackDevice.imei) {
          this.trackDevice.name += this.trackDevice.imei
        }
      }
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  },
  watch: {
    'trackDevice.imei' () {
      this.generateNameTrackingDevice()
    },
    'trackDevice.firmware_id' () {
      this.generateNameTrackingDevice()
    }
  }
}
</script>
