<template>
  <div class="page">
    <div class="d-flex flex-column">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="map-search-icon-addon">
            <font-awesome-icon icon="search" size="1x" :style="{ color: 'gray' }" />
          </span>
        </div>
        <input
          type="text"
          class="form-control map-search"
          placeholder="Поиск по фамилии, имени, отчеству, телефону"
          aria-label="Поиск по фамилии, имени, отчеству, телефону"
          aria-describedby="map-search-icon-addon"
          @input="searchDriver"
          :value="searchText"
        />
      </div>
      <div class="pt-2">
        <router-link to="/drivers/create" class="btn btn-primary w-100">Добавить водителя</router-link>
      </div>
    </div>
    <!--p v-if="isLoading">Загрузка</p-->
    <table v-if="drivers.length > 0" class="table table-hover w-100 mt-3 d-none d-md-table">
      <thead>
        <tr>
          <th>Фамилия</th>
          <th>Имя</th>
          <th>Отчество</th>
          <th>Телефон</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="driver of drivers"
          :key="driver.id"
          @click="$router.push(`/drivers/${driver.id}`)"
          role="button"
          tabindex="0"
        >
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ driver.last_name }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ driver.first_name }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ driver.middle_name }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ driver.phone }}</TextHighlight>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mobile-table-view card mt-2 mb-2 p-2 d-md-none" v-for="driver of drivers" :key="driver.id">
      <table v-if="drivers.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Фамилия</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">{{ driver.last_name }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Имя</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ driver.first_name }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Отчество</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ driver.middle_name }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Телефон</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ driver.phone }}</TextHighlight>
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'driverPage', params: { id: driver.id } }"
        class="btn btn-primary btn-md create-driver ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Drivers',
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  mounted () {
    this.loadItems()
    this.setTitle({
      items: [
        {
          title: 'Водители'
        }
      ]
    })
  },
  computed: {
    ...mapState('taxi/drivers', {
      drivers: state => state.items,
      searchText: state => state.searchText,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading
    })
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/drivers', [
      'loadItems',
      'abortLoading',
      'removeItem',
      'setSearch',
      'setSearchText'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    searchDriver (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'first_name', value: e.target.value })
      this.setSearch({ key: 'last_name', value: e.target.value })
      this.setSearch({ key: 'phone', value: e.target.value })
      this.setSearch({ key: 'middle_name', value: e.target.value })
      this.loadItems({ page: Number(this.page) })
    }
  },
  watch: {
    page () {
      this.loadItems({ page: Number(this.page) })
    }
  }
}
</script>

<style scoped>
  th {
    font-weight: 600;
    font-size: 15.7px;
  }
  .input-group-text {
    background-color: white;
  }
  .input-group input {
    border-left: none;
  }
  .highlighter >>> .text__highlight {
    border-radius: 0px;
    padding: 0.2em 0 0.2em 0;
    background: #fff2ae;
  }
</style>
