import moduleTemplate from '@/store/moduleTemplate'
import carsAPI from '@/api/cars'

const types = {
  selectCar: 'selectCar',
  addTrack: 'addTrack',
  loadTrack: 'loadTrack',
  addToTrackOfSelected: 'addToTrackOfSelected',
  clearTrackOfSelected: 'clearTrackOfSelected',
  clearTrack: 'clearTrack'
}

const template = moduleTemplate(carsAPI)

template.state = {
  ...template.state,
  selectedId: null,
  track: [],
  selectedTrack: []
}

template.state.expandAllItems = ['lastState']

template.getters = {
  selectedCar: state => {
    return state.items.find(car => car.id === state.selectedId)
  }
}

template.mutations = {
  ...template.mutations,
  [template.types.loadItems] (state, {
    items,
    page,
    pageCount,
    pageSize,
    totalCount
  }) {
    state.items = items
    // записать обновленную позицию
    const selectedCar = state.items.find(car => car.id === state.selectedId)
    if (selectedCar) {
      const peekedPoint = state.selectedTrack[0]
      if (selectedCar && selectedCar.lastState &&
        selectedCar.lastState.lat?.value &&
        selectedCar.lastState.lng?.value &&
        ((peekedPoint &&
        selectedCar.lastState.lat?.value != peekedPoint[0] &&
        selectedCar.lastState.lng?.value != peekedPoint[0]) ||
        !peekedPoint)
      ) {
        state.selectedTrack.push([selectedCar.lastState.lat.value, selectedCar.lastState.lng.value])
      }
    }
    state.page = page
    state.pageCount = pageCount
    state.pageSize = pageSize
    state.totalCount = totalCount
  },
  [types.addTrack] (state, { track }) {
    if (state.selectedId) {
      const addedTrack = []
      track.reverse().forEach(trackPoint => {
        const peekedPoint = state.selectedTrack[0]
        if (trackPoint &&
          trackPoint[0] &&
          trackPoint[1] &&
          peekedPoint &&
          trackPoint[0] != peekedPoint[0] &&
          trackPoint[1] != peekedPoint[0]
        ) {
          addedTrack.unshift([trackPoint[0].toString(), trackPoint[1].toString()])
        }
      })
      state.selectedTrack = addedTrack.concat(state.selectedTrack)
    }
  },
  [types.selectCar] (state, id) {
    state.selectedId = id
    const selectedCar = state.items.find(car => car.id === state.selectedId)
    if (selectedCar && selectedCar.lastState.lat?.value) {
      state.selectedTrack.push([selectedCar.lastState.lat.value, selectedCar.lastState.lng.value])
    }
  },
  [types.loadTrack] (state, { track }) {
    state.track = track
  },
  [types.clearTrackOfSelected] (state) {
    state.selectedTrack = []
  },
  [types.clearTrack] (state) {
    state.track = []
  }
}

template.actions = {
  ...template.actions,
  selectCar ({ commit }, id) {
    if (id) {
      commit(types.clearTrackOfSelected)
    }
    commit(types.selectCar, id)
  },
  getTrack ({ commit }, { id, timeFrom, timeTo }) {
    return carsAPI.getTrack(id, timeFrom, timeTo)
      .promise
      .then(track => {
        commit(types.loadTrack, track)
        return track
      })
  },
  getStartTrack ({ commit }, { id, startTimeOfTrack, endTimeOfTrack }) {
    return carsAPI.getTrack(id, startTimeOfTrack, endTimeOfTrack)
      .promise
      .then(track => {
        if (id) {
          commit(types.clearTrackOfSelected)
        }
        commit(types.selectCar, id)
        commit(types.addTrack, track)
        return track
      })
  },
  clearTrack ({ commit }) {
    commit(types.clearTrackOfSelected)
    commit(types.clearTrack)
  }
}

export default template
