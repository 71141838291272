import moduleTemplate from '@/store/moduleTemplate'
import contractsAPI from '@/api/contracts'

const template = moduleTemplate(contractsAPI)

template.types = {
  ...template.types,
  currentTab: 'currentTab',
  setSearchText: 'setSearchText',
  loadActiveRents: 'loadActiveRents',
  loadClosedRents: 'loadClosedRents',
  loadDraftRents: 'loadDraftRents',
  setDraftCount: 'setDraftCount'
}

const tabTypes = {
  active: 'active',
  closed: 'closed',
  draft: 'draft'
}

template.mutations = {
  ...template.mutations,
  [template.types.loadActiveRents] (state, {
    items,
    page,
    pageCount,
    pageSize,
    totalCount
  }) {
    state.activeRents = items
    state.page = page
    state.pageCount = pageCount
    state.pageSize = pageSize
    state.totalCount = totalCount
  },
  [template.types.loadClosedRents] (state, {
    items,
    page,
    pageCount,
    pageSize,
    totalCount
  }) {
    state.closedRents = items
    state.page = page
    state.pageCount = pageCount
    state.pageSize = pageSize
    state.totalCount = totalCount
  },
  [template.types.loadDraftRents] (state, {
    items,
    page,
    pageCount,
    pageSize,
    totalCount
  }) {
    state.draftRents = items
    state.page = page
    state.pageCount = pageCount
    state.pageSize = pageSize
    state.totalCount = totalCount
  },
  [template.types.currentTab] (state, tab) {
    state.currentTab = tabTypes[tab]
  },
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  },
  [template.types.setDraftCount] (state, value) {
    state.draftCount = value
  }
}

template.state = {
  ...template.state,
  activeRents: [],
  closedRents: [],
  draftRents: [],
  searchText: '',
  draftCount: 0,
  currentTab: tabTypes.active
}

template.actions = {
  ...template.actions,
  loadActiveRents ({ state, dispatch, commit }) {
    if (state.isLoading) {
      dispatch('abortLoading')
    }
    commit(template.types.setIsLoading, true)
    return contractsAPI
      .getActiveRents({ searchText: state.searchText })
      .promise
      .then(params => {
        commit(template.types.setIsLoading, false)
        commit(template.types.loadActiveRents, params)
        return params.items
      })
  },
  loadClosedRents ({ state, dispatch, commit }) {
    if (state.isLoading) {
      dispatch('abortLoading')
    }
    commit(template.types.setIsLoading, true)
    return contractsAPI
      .getClosedRents({ searchText: state.searchText })
      .promise
      .then(params => {
        commit(template.types.setIsLoading, false)
        commit(template.types.loadClosedRents, params)
        return params.items
      })
  },
  loadDraftRents ({ state, dispatch, commit }) {
    if (state.isLoading) {
      dispatch('abortLoading')
    }
    commit(template.types.setIsLoading, true)
    return contractsAPI
      .getDraftRents({ searchText: state.searchText })
      .promise
      .then(params => {
        commit(template.types.setIsLoading, false)
        commit(template.types.loadDraftRents, params)
        return params.items
      })
  },
  setActiveTab ({ commit }) {
    commit(template.types.currentTab, tabTypes.active)
  },
  setClosedTab ({ commit }) {
    commit(template.types.currentTab, tabTypes.closed)
  },
  setDraftTab ({ commit }) {
    commit(template.types.currentTab, tabTypes.draft)
  },
  search ({ dispatch }) {
    dispatch('loadCurrentTab')
  },
  loadCurrentTab ({ state, dispatch }) {
    switch (state.currentTab) {
      case tabTypes.active:
        dispatch('loadDraftCount')
        dispatch('loadActiveRents')
        break
      case tabTypes.closed:
        dispatch('loadDraftCount')
        dispatch('loadClosedRents')
        break
      case tabTypes.draft:
        dispatch('loadDraftCount')
        dispatch('loadDraftRents')
        break
    }
  },
  setSearchText ({ dispatch, commit }, searchText) {
    commit(template.types.setSearchText, searchText)
    dispatch('loadCurrentTab')
  },
  loadAllTabs ({ dispatch }) {
    dispatch('loadActiveRents')
    dispatch('loadClosedRents')
    dispatch('loadDraftRents')
    dispatch('loadDraftCount')
  },
  loadDraftCount ({ commit }) {
    contractsAPI
      .getDraftCount()
      .then(response => {
        commit(template.types.setDraftCount, response)
      })
  }
}

template.getters = {
  activeContracts: state => {
    return state.activeRents
  },
  draftContracts: state => {
    return state.draftRents
  },
  closedContracts: state => {
    return state.closedRents
  }
}

export default template
