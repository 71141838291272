<template>
  <div class="page">
    <h3 class="header-title-mobile" >
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <div class="row">
      <div class="col-lg-3 mb-2">
        <label>Марка</label>
        <LookupInput
          v-if="cars"
          v-model="car.brand"
          :options="Object.keys(brandAndModels)"
        ></LookupInput>
        <input v-else type="text" class="form-control" v-model="car.brand" placeholder required>
      </div>
      <div class="col-lg-4 mb-2">
        <label>Модель</label>
        <LookupInput
          v-if="cars"
          v-model="car.model"
          :options="brandAndModels[car.brand] || []"
        ></LookupInput>
        <input v-else type="text" class="form-control" v-model="car.model" placeholder required>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 mb-2">
        <label>Рег. номер</label>
        <div class="row w-100 m-0">
          <input
            type="text"
            class="form-control col-8"
            v-model="car.reg_plate_number"
            placeholder="Номер"
            maxlength="6"
            required
          >
          <input
            type="text"
            class="form-control col-4"
            v-model="car.reg_plate_region"
            placeholder="Регион"
            maxlength="3"
            required
          >
        </div>
      </div>
      <div class="col-lg-2 mb-6">
        <label>Год выпуска</label>
        <input type="text" class="form-control" v-model="car.manufacturing_year" placeholder required>
      </div>
      <div class="col-lg-2 mb-6">
        <label>Код радио</label>
        <input type="text" class="form-control" v-model="car.radio_code" placeholder required>
      </div>
    </div>
    <h5 class="py-1">Устройство наблюдения (необязательно)</h5>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <label>Название устройства наблюдения</label>
        <input type="text" class="form-control" v-model="devise.trackingDevice.name" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <label>IMEI</label>
        <input type="text" class="form-control" v-model="devise.trackingDevice.imei" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <label>Тип прошивки</label>
        <VSelect
          :options="devise.firmwares"
          v-model="devise.trackingDevice.firmware_id"
          :reduce="firmware => firmware.id"
          label="name"
        />
      </div>
    </div>
    <hr class="mb-4" />
    <div class="float-right btn-group">
      <button class="btn btn-primary" @click="create()">Создать</button>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import carsAPI from '@/api/cars'
import trackDeviceApi from '@/api/trackDevice'
import LookupInput from '../../components/LookupInput'

export default {
  name: 'CreateCar',
  components: { LookupInput },
  data () {
    return {
      isCreating: false,
      car: {
        brand: '',
        model: '',
        reg_plate_number: '',
        reg_plate_region: '',
        manufacturing_year: '',
        data: 'not fillable now',
        tracking_device_id: null
      },
      devise: {
        firmwares: [],
        trackingDevice: {
          imei: null,
          name: null,
          firmware_id: null
        }
      }
    }
  },
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Автомобили',
          url: '/cars'
        },
        {
          title: 'Добавить'
        }
      ]
    })
    trackDeviceApi.getFirmwares().then(response => {
      this.devise.firmwares = response
    })
    this.loadItems()
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    }),
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    brandAndModels () {
      const usedBrands = []
      this.cars.forEach(car => {
        if (!usedBrands[car.brand]) {
          usedBrands[car.brand] = []
        }
        if (!usedBrands[car.brand].includes(car.model)) {
          usedBrands[car.brand].push(car.model)
        }
      })
      return usedBrands
    }
  },
  methods: {
    ...mapActions('taxi/cars', [
      'createItem'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('taxi/cars', [
      'abortLoading',
      'loadItems'
    ]),
    generateNameTrackingDevice () {
      this.devise.trackingDevice.name = ''
      if (this.devise.trackingDevice?.imei || this.devise.trackingDevice.firmware_id) {
        if (this.devise.trackingDevice.firmware_id) {
          this.devise.trackingDevice.name += this.devise.firmwares[this.devise.trackingDevice.firmware_id - 1].name
        }
        if (this.devise.trackingDevice?.imei && this.devise.trackingDevice.firmware_id) {
          this.devise.trackingDevice.name += '-'
        }
        if (this.devise.trackingDevice?.imei) {
          this.devise.trackingDevice.name += this.devise.trackingDevice.imei
        }
      }
    },
    create () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      if (this.devise.trackingDevice.name || this.devise.trackingDevice.imei || this.devise.trackingDevice.firmware_id) {
        trackDeviceApi.createItem(this.devise.trackingDevice)
          .then(trackDevice => {
            this.isCreating = false
            this.car.tracking_device_id = trackDevice.model.id
            this.createCar()
          })
          .catch(err => {
            console.error(err)
            this.isCreating = false
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              html: err.message
            })
          })
      } else {
        this.createCar()
      }
    },
    createCar () {
      if (!carsAPI.validateRegistrationPlate(this.car.reg_plate_region, this.car.reg_plate_number)) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Регистрационный номер не соответствует формату'
        })
        this.isCreating = false
        return
      }
      carsAPI.createItem(this.car)
        .then(() => {
          this.isCreating = false
          this.$router.push('/cars')
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    }
  },
  watch: {
    'devise.trackingDevice.imei': {
      handler () {
        this.generateNameTrackingDevice()
      }
    },
    'devise.trackingDevice.firmware_id': {
      handler () {
        this.generateNameTrackingDevice()
      }
    }
  },
  beforeDestroy () {
    this.abortLoading()
  }
}
</script>
