<template>
  <div class="page">
    <h3 class="header-title-mobile d-flex">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <div class="row">
      <div class="col-12">
        <label>Название</label>
        <input type="text" class="form-control" v-model="fuelType.name" placeholder required />
      </div>
    </div>
    <hr class="mb-4" />
    <div class="float-right btn-group">
      <button class="btn btn-primary" @click="createFuel()">Создать</button>
      <router-link to="/fuel-types" class="btn btn-primary">Назад</router-link>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import FuelTypeApi from '../../api/fuelType'

export default {
  name: 'CreateFuelType',
  data () {
    return {
      isCreating: false,
      fuelType: {
        name: ''
      }
    }
  },
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Топливо',
          url: '/fueling'
        }, {
          title: 'Виды топлива',
          url: '/fuel-types'
        }, {
          title: 'Добавить'
        }
      ]
    })
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  methods: {
    createFuelType () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      FuelTypeApi.createItem(this.fuelType)
        .then(() => {
          this.isCreating = false
          this.$router.push('/fuel-types')
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
