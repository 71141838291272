/* eslint-disable camelcase */
import APITemplate from '@/api/APITemplate'

const url = '/trip'
const template = APITemplate(url)
template.getItems = config => {
  const params = new URLSearchParams()
  params.append('imei', config.imei)
  params.append('startDateTime', config.startDateTime)
  params.append('endDateTime', config.endDateTime)

  return template.fetchGet(`${url}/in-range?${params}`)
}
export default template
