<template>
  <div class="page">
    <div class="row">
      <div class="col">
        <h3 class="header-title-mobile">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
      </div>
      <div class="col">
        <ActionBar
          v-if="authedUser.roles['Администратор']"
          :isEditing="isEditing"
          @changeAction="changeUser"
          @cancelAction="cancelUserChanges"
          @deleteAction="deleteUser"
          @saveAction="updateUser"
        />
      </div>
    </div>
    <Edit v-if="isEditing" v-model="user" />
    <Show v-else :user="authedUser.id == userId ? authedUser : user" />
    <button
      v-if="authedUser.roles['Администратор']"
      class="btn btn-outline-danger"
      @click="newPassword"
    >Установить новый пароль</button>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import userApi from '@/api/user'
import deepAssign from 'assign-deep'
import { mapActions, mapState } from 'vuex'
import Edit from './Edit'
import Show from './Show'

export default {
  name: 'UserPage',
  components: {
    Edit,
    Show
  },
  props: ['userId'],
  data () {
    return {
      isUpdating: false,
      prevUser: null,
      user: {},
      isEditing: false
    }
  },
  computed: {
    ...mapState('taxi/user', {
      authedUser: state => state.items.find(user => user.id === state.authedId)
    }),
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  methods: {
    newPassword () {
      Swal.fire({
        title: 'Введите новый пароль',
        input: 'password',
        inputValidator: (value) => {
          if (!value) {
            return 'Пароль не может быть пустым!'
          }
        }
      }).then(password => {
        if (!password.dismiss) {
          userApi.resetPassword({ id: this.userId, password: password.value })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    updateUser () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      userApi.setUserRoles({
        id: this.user.id,
        roles: this.user.selectedRoles
      })
        .then(() => {
          userApi.updateItem(this.user)
            .then(user => {
              this.isEditing = false
              this.isUpdating = false
              this.user = user.model
              this.user.selectedRoles = Object.keys(this.user.roles)
              this.user.accessibleRoles = Object.values(this.user.accessibleRoles)
              if (this.user.id === this.authedUser.id) {
                this.loadAuthedUser()
              }
            })
            .catch(err => {
              console.error(err)
              this.isUpdating = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadData () {
      userApi.getItem({ id: this.userId })
        .then(response => {
          this.user = response
          this.user.selectedRoles = Object.keys(this.user.roles)
          this.user.accessibleRoles = Object.values(this.user.accessibleRoles)
          this.setTitle({
            items: [
              {
                title: 'Пользователи',
                url: '/users'
              },
              {
                title: (response.last_name !== '-' ? response.last_name + ' ' + response.first_name + ' ' + response.middle_name : response.username)
              }
            ]
          })
        })
    },
    changeUser () {
      this.prevUser = deepAssign({}, this.user)
      this.isEditing = true
    },
    cancelUserChanges () {
      this.user = this.prevUser
      this.isEditing = false
    },
    deleteUser () {
      userApi.removeItem(this.userId)
        .then(() => {
          this.$router.push('/users')
        })
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi/user', [
      'loadAuthedUser'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ])
  },
  watch: {
    userId: {
      immediate: true,
      handler () {
        this.loadAuthedUser()
        this.loadData()
      }
    }
  }
}
</script>
