<template>
  <table class="table">
    <tbody>
      <tr>
        <td class="table-col">Серия</td>
        <td>{{ documentData.series }}</td>
      </tr>
      <tr>
        <td class="table-col">Номер</td>
        <td>{{ documentData.number }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'DocumentPassCarriageLicenceView',
  props: ['documentData']
}
</script>

<style scoped>
  .table-col {
    width: 50%;
  }
</style>
