<template>
  <div class="edit-sts">
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Серия</label>
        <input type="text" class="form-control" v-model="data.series" placeholder required />
      </div>
      <div class="col-md-4 mb-2">
        <label>Номер</label>
        <input type="text" class="form-control" v-model="data.number" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Дата выдачи</label>
        <input type="date" class="form-control" v-model="data.issue_date" placeholder required />
      </div>
    </div>

  </div>
</template>

<script>
const type = 'sts'

export default {
  name: 'DocumentStsEdit',
  props: ['documentData'],
  data () {
    return {
      data: {
        series: '',
        number: '',
        issue_date: ''
      }
    }
  },
  mounted () {
    if (this.documentData) {
      this.data = this.documentData
    }
    this.update()
  },
  methods: {
    update () {
      this.$emit('update', {
        type: type,
        data: this.data
      })
    }
  },
  watch: {
    data: {
      handler () {
        this.update()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .edit-sts input {
    margin-bottom: 5px;
  }
  .edit-sts textarea {
    margin-bottom: 5px;
  }
</style>
