<template>
  <div class="show-form-cars">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Марка</td>
          <td>{{ car.brand }}</td>
        </tr>
        <tr>
          <td>Модель</td>
          <td>{{ car.model }}</td>
        </tr>
        <tr>
          <td>Рег. номер</td>
          <td>
            <RegistrationPlate
              :reg-number="car.reg_plate_number"
              :reg-region="car.reg_plate_region"
            />
          </td>
        </tr>
        <tr>
          <td>Год выпуска</td>
          <td>{{ car.manufacturing_year }}</td>
        </tr>
        <tr>
          <td>Код радио</td>
          <td>{{ car.radio_code }}</td>
        </tr>
        <tr>
          <td>Пробег</td>
          <td v-if="car.lastState && car.lastState.totalOdometer">
            {{ Math.round(car.lastState.totalOdometer.value / 1000) + " км" }}
            (по датчику {{ ' ' + Math.round(car.lastState.totalOdometer.sensor / 1000) + " км" }})
          </td>
          <td v-else>Нет данных</td>
        </tr>
        <tr>
          <td>Устройство геопозиционирования</td>
          <td>
            <router-link
              v-if="car.trackingDevice"
              :to="'/devices/' + car.trackingDevice.id"
            >
              {{ car.trackingDevice.name }}
            </router-link>
          </td>
        </tr>
        <tr>
          <td>IMEI</td>
          <td>
            <router-link
              v-if="car.trackingDevice"
              :to="'/devices/' + car.trackingDevice.id"
            >
              {{ car.trackingDevice.imei }}
            </router-link>
          </td>
        </tr>
        <tr>
          <td>Статус</td>
          <td class="col-state"><CarState :car="car" /></td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4" />
  </div>
</template>

<script>
import CarState from '@/components/CarState'
import RegistrationPlate from './RegistrationPlate'

export default {
  name: 'CarShowForm',
  components: {
    CarState,
    RegistrationPlate
  },
  props: ['id', 'car']
}
</script>

<style scoped>
  .show-form-cars {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
  .col-state {
    padding: 0 !important;
    vertical-align: middle;
  }
</style>
