const headers = {
  accept: 'application/json',
  'content-type': 'application/json'
}

const errorHandler = response => {
  if (response.status >= 400) {
    return response.json()
      .then(error => {
        if (error) {
          throw new Error(`${error.message}`)
        }
      })
  }
  return response.json()
}

export default {
  /**
   * https://wiki.teltonika-gps.com/view/FMB900_SMS/GPRS_Commands
   */
  sendCommand (request) {
    return fetch('/device/send-command', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers,
      body: JSON.stringify(request)
    })
      .then(response => errorHandler(response))
  },
  lockIgnition (request) {
    return fetch('/device/lock-ignition', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers,
      body: JSON.stringify(request)
    })
      .then(response => errorHandler(response))
  },
  unlockIgnition (request) {
    return fetch('/device/unlock-ignition', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers,
      body: JSON.stringify(request)
    })
      .then(response => errorHandler(response))
  },
  fastConfig (request) {
    return fetch('/device/fast-config', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers,
      body: JSON.stringify(request)
    })
      .then(response => errorHandler(response))
  },
  taskReject (request) {
    return fetch('/device/task-reject', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers,
      body: JSON.stringify(request)
    })
      .then(response => errorHandler(response))
  }
}
