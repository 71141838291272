<template>
  <div class="show-serve">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Автомобиль</td>
          <td v-if="car">{{ car.brand }} {{ car.model }}</td>
        </tr>
        <tr>
          <td>Гос. номер</td>
          <td v-if="car">{{ car.registration_plate }}</td>
        </tr>
        <tr>
          <td>Текущий пробег, км</td>
          <td v-if="car">
            <span v-if="car.lastState && car.lastState.totalOdometer">
              {{ Math.round(car.lastState.totalOdometer.value / 1000) + " км" }}
              (по датчику {{ ' ' + Math.round(car.lastState.totalOdometer.sensor / 1000) + " км" }})
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4" />
  </div>
</template>

<script>

export default {
  name: 'ServiceShow',
  props: ['car']
}
</script>

<style scoped>
  .show-serve {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
