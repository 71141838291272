import APITemplate from '@/api/APITemplate'

const headers = {
  accept: 'application/json',
  'content-type': 'application/json'
}

export default {
  ...APITemplate('/car'),
  getItemsWith (expand) {
    const controller = new AbortController()
    return {
      promise: fetch('/car?expand=' + expand.toString(), {
        method: 'GET',
        credentials: 'include',
        redirect: 'error',
        signal: controller.signal,
        headers
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Ошибка при загрузке.')
          }
          return new Promise((resolve, reject) => {
            response.json().then(items => {
              resolve({
                items
              })
            })
          })
        }),
      abort () {
        controller.abort()
      }
    }
  },
  getTrack (id, timeFrom, timeTo) {
    const request = `/car/track?id=${id}&s_datetime=${timeFrom}&e_datetime=${timeTo}`
    const controller = new AbortController()
    return {
      promise: fetch(request, {
        method: 'GET',
        credentials: 'include',
        redirect: 'error',
        signal: controller.signal,
        headers
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Ошибка при загрузке.')
          }
          return new Promise((resolve, reject) => {
            response.json().then(track => {
              resolve({
                track
              })
            })
          })
        }),
      abort () {
        controller.abort()
      }
    }
  },
  validateRegistrationPlate (region, number) {
    const letters = 'АВЕКМНОРСТУХABEKMHOPCTYX'
    return region.match(/^\d{2,3}(?<![0])$/ui) &&
      (number.match(
        new RegExp(
          `^(([${letters}]{2}\\d{3}(?<!000))|([${letters}]\\d{3}(?<!000)[${letters}]{2}))$`,
          'ui'
        )
      ))
  }
}
