<template>
  <div class="edit-form-track-devices">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Название</td>
          <td><input type="text" class="form-control" v-model="trackDevice.name" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">IMEI</td>
          <td><input type="text" class="form-control" v-model="trackDevice.imei" placeholder required /></td>
        </tr>
        <tr>
          <td class="label-col">Прошивка</td>
          <td>
            <VSelect :options="firmwares" label="name" v-model="trackDevice.firmware_id" :reduce="firmware => firmware.id" />
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4" />
  </div>
</template>

<script>
export default {
  name: 'TrackDeviceEdit',
  props: ['trackDevice', 'firmwares']
}
</script>

<style scoped>
  .edit-form-track-devices {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
