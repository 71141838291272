<template>
  <div class="page page-fuel-type">
    <div class="d-flex justify-content-end">
      <router-link to="/fuel-types/create" class="btn btn-success">
        Добавить
      </router-link>
    </div>
    <br />
    <!-- TODO: <button>ДОБАВИТЬ ГРУППУ</button> -->
    <div class="draggable-cards-wrapper">
      <li v-for="fuelType in fuelTypes" :key="fuelType.id" class="list-group-item">
        {{ fuelType.name }}
        {{ fuelType.archived_at }}
        <font-awesome-icon
          icon="pen"
          size="1x"
          :style="{
            color: 'gray',
            'margin-left': 'auto',
            cursor: 'pointer'
          }"
          @click="$router.push(`/fuel-types/update/${fuelType.id}`)"
        />
        <font-awesome-icon
          icon="trash-alt"
          size="1x"
          :style="{
            color: 'gray',
            'margin-left': '1.5rem',
            cursor: 'pointer'
          }"
          @click="removeItem(fuelType)"
        />
      </li>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FuelTypeApi from '../api/fuelType'

export default {
  name: 'FuelTypes',
  mounted () {
    this.loadItems()
    this.setTitle({
      items: [
        {
          title: 'Виды топлива'
        }
      ]
    })
  },
  beforeDestroy () {
    this.abortLoading()
  },
  data () {
    return {
      editable: true,
      fuelTypesCopy: null
    }
  },
  computed: {
    ...mapState('fuel/fuelType', {
      allFuelTypes: state => state.items.filter(e => !e.archived_at),
      isLoading: state => state.isLoading
    }),
    fuelTypes: {
      get () {
        if (!this.fuelTypesCopy) {
          return JSON.parse(JSON.stringify(this.allFuelTypes))
        }

        return this.fuelTypesCopy
      },
      set (value) {
        value.forEach(v => this.updateItem(v))
        this.fuelTypesCopy = value
      }
    }
  },
  methods: {
    ...mapActions('fuel/fuelType', [
      'loadItems',
      'updateItem',
      'setSorts',
      'abortLoading'
    ]),
    removeItem (fuelType) {
      this.fuelTypes = this.fuelTypes.filter(ft => ft !== fuelType)
      FuelTypeApi
        .archivateItem(fuelType.id)
        .then(() => this.loadItems())
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group-item {
    display: flex;
  }
  .list-group-item i {
    cursor: pointer;
  }
  .page-fuel-type {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  .draggable-cards-wrapper {
    height: 100%;
    flex-direction: column;
    flex-flow: nowrap;
  }
</style>
