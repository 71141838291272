<template>
  <div class="wrapper" @click.self="$emit('close-menu')">
    <aside class="base-sidebar">
      <div class="sidebar-sticky">
        <ul class="nav flex-column">
          <li
            v-for="link of links"
            :key="link.text"
            :class="['nav-item', link.divider ? 'divider' : '']"
            @click="!link.sublinks && $emit('close-menu')"
          >
            <router-link
              v-if="!link.divider"
              :to="link.url"
              :class="{ active: link.active }"
              class="nav-menu-item align-middle"
            >
              <div class="nav-menu-item-title">
                <font-awesome-icon v-if="link.icon" class="sidebar-icon" :icon="link.icon" />
                <div v-else class="sidebar-icon"></div>
                <div class="link-text">{{ link.text }}</div>
              </div>
              <div v-if="link.sublinks && link.active" style="margin-top: 0.3rem">
                <li
                  v-for="sublink of link.sublinks"
                  :key="sublink.text"
                  :class="['nav-item', link.divider ? 'divider' : '']"
                  @click="$emit('close-menu')"
                >
                  <router-link
                    v-if="!sublink.divider"
                    :to="sublink.url"
                    :class="{ active: sublink.active }"
                    class="nav-sublink align-middle"
                  >
                    <div class="nav-menu-item-title">
                      <font-awesome-icon v-if="sublink.icon" class="sidebar-icon" :icon="sublink.icon" />
                      <div v-else class="sidebar-icon"></div>
                      <div class="sublink-text">{{ sublink.text }}</div>
                    </div>
                    <span v-if="sublink.active" class="sr-only">(current)</span>
                  </router-link>
                </li>
              </div>
              <span v-if="link.active" class="sr-only">(current)</span>
            </router-link>
          </li>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
import BaseWorkspaceLayout from './BaseWorkspaceLayout'

export default {
  name: 'MobileSideBar',
  mixins: [BaseWorkspaceLayout]
}
</script>

<style lang="scss" scoped>
  @import "bootstrap";
  @import "@/assets/scss/globals.scss";

  .base-sidebar {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
  .base-sidebar .sidebar-sticky {
    position: sticky;
    top: 30px;
  }
  .base-sidebar {
    padding: 15px 0 0;
    font-size: 0.875rem;
    font-weight: 400;
    background-color: #fafafa;
    border-right: 1px solid #ddd;
    li {
      width: 100%;
    }
    .nav-menu-item {
      @extend .nav-link;
      color: #707070;
      &:hover {
        color: #4f4f4f;
      }
      &.active .sidebar-icon {
        color: #0597f2;
      }
      &.active .link-text {
        font-weight: 600;
        color: #0597f2;
      }
    }
    .nav-sublink {
      @extend .nav-link;
      color: #707070;
      &:hover {
        color: #4f4f4f;
      }
      &.active .sublink-icon {
        color: #0597f2;
      }
      &.active .sublink-text {
        font-weight: 600;
        color: #0597f2;
      }
    }
  }
  .divider {
    margin: 0.25rem 0 0.25rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
  .wrapper {
    position: fixed;
    display: grid;
    grid-template-columns: 230px auto;
    grid-template-rows: $bwlayuotHeader minmax(calc(100vh - #{$bwlayuotHeader}), max-content);
    background-color: rgba(0, 0, 0, 0.15);
    display: none;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  @media screen and (max-width: 1199px) {
    .wrapper {
      display: grid;
    }
  }

  .nav-menu-item-title {
    display: flex;
    align-items: center;
  }
  .nav-menu-item-title .sidebar-icon {
    width: 40px;
  }
</style>
