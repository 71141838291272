<template>
  <div>
    <div class="card equip-card">
      <div class="card-header" @click="showFields = !showFields">
        <h5 class="mb-0">
          <button class="btn btn-link">Комплектность</button>
        </h5>
      </div>
      <div v-if="showFields">
        <div class="card-body">
          <table class="table">
            <thead>
              <th>Наименование</th>
              <th>Количество</th>
              <th>ЕИ</th>
            </thead>
            <tbody>
              <tr v-for="equipment in equipments" :key="equipment.id">
                <td>{{ equipment.name }}</td>
                <td>{{ equipment.count }}</td>
                <td>{{ equipment.measure_units }}</td>
              </tr>
            </tbody>
          </table>
          <button
            class="btn btn-link collapsed float-right"
            @click="$emit('actionEdit')"
          >Редактировать</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EquipmentsView',
  props: ['equipments'],
  data () {
    return {
      showFields: false
    }
  }
}
</script>

<style scoped>
  .card {
    width: 100%;
    margin-top: 1rem;
  }
  .equip-card th {
    font-weight: 500;
    font-size: 16px;
  }
  .equip-card .card-header {
    background-color: white;
  }
  .btn-link {
    color: #0597F2 !important;
  }
</style>
