<template>
  <div>
     <l-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      ref="leafletMap"
      @update:zoom="zoom => currentZoom = zoom"
      @ready="initMap"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-control-zoom position="topright"  ></l-control-zoom>
      <Track />
      <VMarkerCluster ref="clusterRef" class="cluster" :options="{ spiderfyOnMaxZoom: false, disableClusteringAtZoom: 15 }">
        <div v-for="car in cars" :key="car.id">
          <div v-if="selectedCar !== car">
            <MapMarker :car="car"/>
          </div>
        </div>
      </VMarkerCluster>
      <MapMarker v-if="selectedCar" :car="selectedCar"/>
      <SelectedMarker />
    </l-map>
  </div>
</template>

<script>
import L, { latLng } from 'leaflet'
import VMarkerCluster from 'vue2-leaflet-markercluster'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'OLMap',
  components: {
    VMarkerCluster
  },
  data () {
    return {
      zoom: 11,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      center: latLng(57.1418583, 65.5413183),
      currentZoom: 11.5,
      defaultZoom: 13,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      }
    }
  },
  computed: {
    ...mapState('taxi/mapCars', {
      cars: state => state.items,
      track: state => state.track
    }),
    ...mapGetters('taxi/mapCars', [
      'selectedCar'
    ])
  },
  methods: {
    ...mapActions('taxi/mapCars', [
      'selectCar'
    ]),
    initMap () {
      this.map = this.$refs.leafletMap.mapObject
      this.map.invalidateSize()
      const drawnItems = new L.FeatureGroup([], { name: 'polygonLayer' })
      drawnItems.addTo(this.map)
    },
    initPolygons (polygons) {
      let drawnItems = null
      for (const key in this.map._layers) {
        const layer = this.map._layers[key]
        if (layer.options.name === 'polygonLayer') {
          drawnItems = layer
          break
        }
      }
      if (!drawnItems) {
        return
      }
      for (const key in drawnItems._layers) {
        drawnItems._layers[key].removeFrom(drawnItems)
      }
      for (const model of polygons) {
        if (!model.show_on_map) {
          continue
        }
        const layer = L.polygon(model.points, {
          color: model.color || '#008000',
          weight: 1,
          opacity: 0.7
        })
          .on('click', () => {
            this.selectedPolygon = model
          })
          .addTo(drawnItems)
        layer.model = model
      }
    },
    reloadPolygons () {
      if (process.env.VUE_APP_MODULE_RESTRICTED_AREA === 'true') {
        require('@/modules/restrictedArea/api')
          .default
          .getItems()
          .promise
          .then(({ items }) => this.initPolygons(items))
          .then(() => setTimeout(() => this.reloadPolygons(), 60 * 1000))
      }
    },
    findCarOnMap () {
      if (this.map && this.selectedCar?.lastState &&
        this.selectedCar?.lastState?.lat?.value &&
        this.selectedCar?.lastState?.lng?.value) {
        const targetPoint = this.map.project(
          latLng(this.selectedCar.lastState?.lat?.value, this.selectedCar.lastState?.lng?.value),
          this.currentZoom >= this.defaultZoom ? this.currentZoom : this.defaultZoom
        )
        const targetLatLng = this.map.unproject(targetPoint,
          this.currentZoom >= this.defaultZoom ? this.currentZoom : this.defaultZoom
        )
        this.map.setView(targetLatLng,
          this.currentZoom >= this.defaultZoom ? this.currentZoom : this.defaultZoom
        )
      }
    }
  },
  mounted () {
    this.reloadPolygons()
  },
  watch: {
    selectedCar (newCar, oldCar) {
      if (!oldCar || !newCar || oldCar?.id !== newCar?.id) {
        this.findCarOnMap()
      }
    },
    track () {
      if (this.track.length) {
        this.map.fitBounds(this.track, { padding: [0, 5], maxZoom: 13 })
      }
    }
  }
}
</script>

<style lang="scss">
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

  .marker-cluster-medium,
  .marker-cluster-large,
  .marker-cluster-small {
    background-color: rgba(121, 217, 217, 0.6) !important;
    div {
      background-color: rgba(4, 157, 217, 0.6) !important;
      color: #fff !important;
    }
  }

</style>
