const result = {}

if (process.env.VUE_APP_MODULE_FUEL === 'true') {
  result.fuel = require('./fuel/store/index').default
}

if (process.env.VUE_APP_MODULE_MAINTENANCE === 'true') {
  result.maintenance = require('./maintenance/store/index').default
}

export default result
