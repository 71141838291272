import { render, staticRenderFns } from "./AreaPropEditor.vue?vue&type=template&id=0023fc2b&scoped=true&"
import script from "./AreaPropEditor.vue?vue&type=script&lang=js&"
export * from "./AreaPropEditor.vue?vue&type=script&lang=js&"
import style0 from "./AreaPropEditor.vue?vue&type=style&index=0&id=0023fc2b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../shared/frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0023fc2b",
  null
  
)

export default component.exports