<template>
  <div class="wrapper">
    <aside class="base-sidebar pt-0" :class="{ 'togled-left-bar': showFullSideMenu }">
      <div class="sidebar-sticky">
        <div class="top-bar-title d-flex align-items-center" style="height: 43px; place-content: center">Stattika</div>
        <ul class="nav flex-column">
          <li
            v-for="link of links"
            :key="link.text"
            :class="['nav-item', link.divider ? 'divider' : '', link.subactive ? 'subactive' : '']"
          >
            <router-link
              v-if="!link.divider"
              :to="link.url"
              :class="{ active: link.active || (link.sublinks && link.sublinks.find((el) => el.active)) }"
              class="nav-menu-item align-middle"
            >
              <div class="row">
                <div class="col-sm-2">
                  <font-awesome-icon class="sidebar-icon" :icon="link.icon" />
                </div>
                <div class="col link-text">{{ link.text }}</div>
              </div>
              <div v-if="link.sublinks && link.subactive" class="sublinks-wrapper" style="margin-top: 0.3rem">
                <li
                  v-for="sublink of link.sublinks"
                  :key="sublink.text"
                  :class="['nav-item', link.divider ? 'divider' : '']"
                >
                  <router-link
                    v-if="!sublink.divider"
                    :to="sublink.url"
                    :class="{ active: sublink.active }"
                    class="nav-sublink align-middle"
                  >
                    <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col sublink-text">{{ sublink.text }}</div>
                    </div>
                    <span v-if="sublink.active" class="sr-only">(current)</span>
                  </router-link>
                </li>
              </div>
              <span v-if="link.active" class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-subitem bottom-sidebar-item">
            <div
              class="togle-button-wrapper nav-menu-item align-middle noselect"
              role="button"
              tabindex="0"
              @click="showFullSideMenu = !showFullSideMenu"
            >
              <div class="row">
                <div class="col-sm-2">
                  <font-awesome-icon v-if="showFullSideMenu" class="sidebar-icon" icon="angle-double-left" />
                  <font-awesome-icon v-else class="sidebar-icon" icon="angle-double-right" />
                </div>
                <div class="col link-text">Свернуть</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </aside>
    <MobileSideBar v-if="showSideMenu" @close-menu="showSideMenu = false" />

    <div class="wrapper-vertical">
      <header class="navbar-expand-lg navbar-light bg-light top-bar">
        <div>
          <button
            class="hamburger hamburger--arrow"
            type="button"
            :class="{ 'is-active': showSideMenu }"
            @click="showSideMenu = !showSideMenu"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
          <!-- <div class="bell-item noselect" role="button" tabindex="0" @click="showEvents = !showEvents" style="display: none">
            <div class="numberCircle">3</div>
            <font-awesome-icon class="notifications-bell-icon" icon="bell" :style="{ color: '#404040' }" />
          </div> -->
          <div class="header-title">
            <div v-for="(item, key) in title.items" :key="key">
              <span class="px-2" v-if="key !== 0">›</span>
              <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
              <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div
          class="float-right noselect"
          role="button"
          tabindex="0"
          v-click-outside="hideLogout"
          @click="showLogout = !showLogout"
        >
          <font-awesome-icon class="user-icon" icon="user-circle" :style="{ color: '#404040' }" />
        </div>
      </header>
      <main class="content">
        <Logout
          v-if="showLogout"
          @hide-logout="showLogout = false"
          class="logout-card"
          ref="logoutPopup"
        />
        <slot />
      </main>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapActions, mapState } from 'vuex'
import leftMenuLinks from './leftMenuLinks'
import Logout from '@/components/common/Logout'

export default {
  name: 'BaseWorkspaceLayout',
  components: {
    Logout
  },
  data () {
    return {
      showLogout: false,
      showSideMenu: false,
      showFullSideMenu: false
    }
  },
  computed: {
    links () {
      const links = JSON.parse(JSON.stringify(leftMenuLinks))
      for (const link of links) {
        if (link.subactiveRoutes &&
          link.subactiveRoutes.find(x => this.$route.path.includes(x))) {
          link.subactive = true
        }

        if (this.$route.path.includes(link.url)) {
          link.subactive = true
        }

        if (link.url === this.$route.path) {
          link.active = true
        }

        if (!link.sublinks) {
          continue
        }

        link.sublinks.forEach(el => {
          if (el.url === this.$route.path) {
            el.active = true
            link.active = true
          } else {
            el.active = false
          }
        })
      }
      return links
    },
    ...mapState('taxi', {
      title: state => state.title
    })
  },
  mounted () {
    this.loadAuthedUser()
  },
  methods: {
    hideLogout (e) {
      if (this.$refs.logoutPopup && !e.path.includes(this.$refs.logoutPopup.$el)) {
        this.showLogout = false
      }
    },
    ...mapActions('taxi/user', [
      'loadAuthedUser'
    ])
  },
  watch: {
    $route (to, from) {
      this.showFullSideMenu = false
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss" scoped>
  // HAMBURGER STYLING
  $hamburger-layer-width: 20px;
  $hamburger-layer-height: 2px;
  $hamburger-layer-spacing: 3px;
  $hamburger-padding-x: 0;
  $hamburger-padding-y: 0;
  $hamburger-layer-color: #55606a;
  @import "@/assets/scss/globals.scss";
  @import "hamburgers/_sass/hamburgers/hamburgers.scss";
  @import "bootstrap";
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

  .wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
  }
  .top-bar-title {
    font-family: Poppins, sans-serif;
    font-size: 24px;
    text-align: center;
    margin-bottom: 3px;
  }
  .header-title {
    display: flex;
    margin-left: 15px;
    font-size: 20px;
  }

  .base-sidebar {
    flex-basis: 250px;
    padding: 15px 0 0;
    font-size: 0.875rem;
    font-weight: 400;
    background-color: #fafafa;
    border-right: 1px solid #ddd;
    li {
      width: 100%;
    }
    .nav-menu-item {
      @extend .nav-link;
      color: #707070;
      &:hover {
        color: #4f4f4f;
      }
      &.active .sidebar-icon {
        color: #0597f2;
      }
      &.active .link-text {
        font-weight: 600;
        color: #0597f2;
      }
    }
    .nav-sublink {
      @extend .nav-link;
      color: #707070;
      &:hover {
        color: #4f4f4f;
      }
      &.active .sublink-icon {
        color: #0597f2;
      }
      &.active .sublink-text {
        font-weight: 600;
        color: #0597f2;
      }
    }
  }

  .wrapper-vertical {
    position: relative;
    display: flex;
    flex-grow: 0;
    overflow: hidden;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }

  @media screen and (max-width: 768px) {
    .link-text {
      display: block;
    }
    .base-sidebar {
      display: none;
    }
    .hamburger {
      display: block !important;
    }
  }
  @media screen and (max-width: 1199px) {
    .header-title {
      display: none;
    }
  }

  header {
    width: 100%;
    height: 43px;
    align-items: center;
    padding: 0;
    display: flex;
  }

  .hamburger {
    padding: 0;
    margin-left: 14px;
    display: none;
  }
  .logout-card {
    z-index: 9999;
    margin-right: calc(100% - 100vw + 0.2rem);
  }
  .events {
    z-index: 9999;
  }
  .divider {
    margin: 0.25rem 0 0.25rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
  .bell-item {
    position: relative;
    width: 25px;
    height: 25px;
    vertical-align: middle !important;
    margin-left: auto;
  }
  .notifications-bell-icon {
    height: 25px !important;
    width: 25px !important;
    position: absolute;
  }
  .top-bar {
    height: #{$bwlayuotHeader};
    flex-shrink: 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
  }
  .user-icon {
    height: 25px;
    width: 25px;
    margin-right: 15px;
  }
  .sidebar-icon {
    width: 18px;
    height: 18px;
    margin-right: 30px;
    vertical-align: -3px;
  }
  .content {
    font-weight: 400;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .noselect {
    user-select: none;
  }
  .togled-left-bar {
    width: 220px;
    z-index: 9999;
    .link-text {
      display: block;
    }
  }
  .bottom-sidebar-item {
    position: absolute;
    bottom: 0.3rem;
    display: none;
  }
</style>
