import moduleTemplate from '@/store/moduleTemplate'
import FuelTypeApi from '../api/fuelType'

const template = moduleTemplate(FuelTypeApi)
template.types = {
  ...template.types,
  setItems: 'setSearchText'
}
template.mutations = {
  ...template.mutations,
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  }
}

template.actions = {
  ...template.actions,
  updateItem ({ state, commit, dispatch }, params) {
    FuelTypeApi.updateItem(params)
  }
}

export default template
