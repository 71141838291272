<template>
  <div class="page page-drivers">
    <h3 class="header-title-mobile">
      <div v-for="(item, key) in headerTitle.items" :key="key">
        <span class="px-2" v-if="key !== 0">›</span>
        <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
        <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
      </div>
    </h3>
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeDriver"
      @cancelAction="cancelDriverChanges"
      @deleteAction="deleteDriver"
      @saveAction="updateDriver"
    />
    <br/>
    <DriverEdit
      v-if="isEditing"
      class="driver-view-edit"
      :driver="driver"
    />
    <DriverView
      v-else
      class="driver-view-edit"
      :driver="driver"
    />
    <div class="driverDocs">
      <h5>Документы водителя<router-link :to="`/drivers/${id}/docs/create`" class="btn btn-primary float-right">
        <font-awesome-icon class="text-white" icon="plus" size="1x"/>
        Добавить
      </router-link></h5>
      <br/>
      <LightBox
        v-if="showDetailImage"
        @actionClose="showDetailImage = false"
        :scan="currentScan"
      />
      <div v-if="driver.documents">
        <Document v-for="document of driver.documents" :key="document.id"
          :document="document"
          @actionDetailImage="detailImage(currentScan)"
          @actionDelte="deleteDocument(document.id)"
          @actionUpdate="$router.push(`/drivers/${id}/docs/${document.id}/update`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import Swal from 'sweetalert2'
import deepAssign from 'assign-deep'
import DocumentsAPI from '@/api/driver-documents'
import DriversAPI from '@/api/drivers'
import { mapActions, mapMutations, mapState } from 'vuex'
import DriverEdit from './Edit'
import DriverView from './View'

export default {
  name: 'DriverPage',
  components: {
    DriverEdit,
    DriverView
  },
  props: ['id'],
  data () {
    return {
      isUpdating: false,
      prevDriver: null,
      driver: {
        id: this.id,
        last_name: '',
        middle_name: '',
        first_name: '',
        phone: '',
        documents: []
      },
      isEditing: false,
      currentScan: null,
      showDetailImage: false
    }
  },
  watch: {
    driver: {
      handler (driver, driverOld) {
        if (driver?.id && driver?.id !== driverOld?.id) {
          this.setTitle({
            items: [
              {
                title: 'Водители',
                url: '/drivers'
              }, {
                title: driver.last_name + ' ' + driver.first_name + ' ' + driver.middle_name
              }
            ]
          })
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.loadData()
  },
  computed: {
    ...mapState('taxi', {
      headerTitle: state => state.title
    })
  },
  methods: {
    updateDriver () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      DriversAPI.updateItem(this.driver)
        .then(driver => {
          this.driver = { ...this.driver, ...driver.model }
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadData () {
      DriversAPI.getItem({
        id: this.id,
        queryWith: ['documents']
      })
        .then(response => {
          this.driver = response
          this.loadScans()
        })
    },
    loadScans () {
      this.clearItems()
      this.setModel('DriverDocument')
      this.driver.documents.forEach(element => {
        DocumentsAPI.getScans(element.id).then(result => {
          element.scans = result
          this.pushItems({ scans: result, documentId: element.id })
        })
          .catch(err => {
            console.error(err)
            Swal.fire({
              icon: 'error',
              title: 'Ошибка сервера',
              text: err.message
            })
          })
      })
    },
    deleteDocument (documentId) {
      DocumentsAPI.removeItem(documentId)
        .then(() => this.loadData())
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    changeDriver () {
      this.prevDriver = deepAssign({}, this.driver)
      this.isEditing = true
    },
    cancelDriverChanges () {
      this.driver = this.prevDriver
      this.isEditing = false
    },
    deleteDriver () {
      DriversAPI.removeItem(this.driver.id)
        .then(() => {
          this.$router.push('/drivers')
        })
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', ['setTitle']),
    ...mapMutations('taxi/scans', [
      'pushItems',
      'clearItems',
      'setModel'
    ])
  }
}
</script>

<style scoped>
  .page-drivers {
    width: 98%;
    padding-bottom: 4rem;
    padding-top: 2.5rem;
  }
</style>
