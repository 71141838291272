import APITemplate from '@/api/APITemplate'
const template = APITemplate('/user')

export default {
  ...template,
  getCurrentUser () {
    return fetch('/user/me', {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  setUserRoles (data) {
    return fetch('/user/set-roles', {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers,
      body: JSON.stringify(data)
    }).then(template.saveHandler)
  },
  resetPassword (data) {
    return fetch('/user/reset-password', {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers,
      body: JSON.stringify(data)
    }).then(template.saveHandler)
  },
  getAccessibleRoles () {
    return fetch('/user/accessible-roles', {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  }
}
