<template>
  <div>
    <div class="my-1">Начало</div>
    <div class="row">
      <div class="col">
        <input
          class="w-100 form-control"
          type="date"
          placeholder="Начало"
          v-model="dateStart"
        />
      </div>
    </div>
    <div class="my-2">Окончание</div>
    <div class="row">
      <div class="col">
        <input
          class="w-100 form-control"
          type="date"
          placeholder="Окончание"
          v-model="dateEnd"
        />
      </div>
    </div>
    <button class="input-group-btn btn btn-primary my-4 align-content-center w-100" @click="loadWaybill()">
      <span v-if="!loadingStatistic">Показать</span>
      <div v-else class="spinner-border spinner-border-sm text-light" role="status">
        <span class="sr-only">Загрузка...</span>
      </div>
    </button>
    <div v-if="trips">
      <div v-if="trips.length" class="mb-2">
        <a
          :href="'/waybill/get-rept?id=' + car.id + '&startDateTime=' + dateStart + '&endDateTime=' + dateEnd"
          class="input-group-btn btn btn-primary mr-2">
          <font-awesome-icon class="text-white" icon="file-download" size="1x" />
          Скачать отчет
        </a>
        <a
          :href="'/waybill/get-waybill?car_id=' + car.id + '&startDateTime=' + dateStart + '&endDateTime=' + dateEnd"
          class="input-group-btn btn btn-primary">
          <font-awesome-icon class="text-white" icon="file-download" size="1x" />
          Скачать путевые листы
        </a>
      </div>
      <div class="row w-100 m-0 p-2 border-top">
        <div class="col-5 font-weight-bold">Дата</div>
        <div class="col-5 font-weight-bold">Пройдено км</div>
      </div>
      <div v-if="!trips.length" class="text-center w-100 border-top py-3">Ничего не найдено</div>
      <FuelStatisticTrip v-for="(trip, key) in trips" :key="key" :trip="trip" :car-id="car.id"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import FuelStatisticTrip from './FuelStatisticTrip'

export default {
  name: 'FuelStatistic',
  components: { FuelStatisticTrip },
  props: ['distance', 'interval', 'afterService'],
  data () {
    return {
      dateStart: null,
      dateEnd: null,
      loadingStatistic: false
    }
  },
  computed: {
    ...mapState('taxi/waybill', {
      trips: state => state.items
    }),
    ...mapGetters('taxi/mapCars', {
      car: 'selectedCar'
    })
  },
  methods: {
    ...mapActions('taxi/waybill', [
      'abortLoading',
      'loadItems',
      'getAddress',
      'clearItems'
    ]),
    loadWaybill () {
      if (!this.loadingStatistic) {
        if (this.dateStart && this.dateEnd) {
          this.loadingStatistic = true
          this.clearItems()
          this.loadItems({ id: this.car.id, startDateTime: this.dateStart, endDateTime: this.dateEnd })
            .then(items => {
              this.loadingStatistic = false
              items.forEach(item => {
                this.getAddressByCoords(item.startLocation.lat, item.startLocation.lng)
                  .then(response => {
                    item.startAddress = response
                  })
                this.getAddressByCoords(item.endLocation.lat, item.endLocation.lng)
                  .then(response => {
                    item.endAddress = response
                  })
              })
            })
            .catch(error => {
              this.loadingStatistic = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: error.message
              })
            })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: 'Не введены даты'
          })
        }
      }
    },
    getAddressByCoords ($lat, $lng) {
      return this.getAddress({ lat: $lat, lon: $lng })
        .then(address => {
          let response = address.features
          if (response.length) {
            response = address.features[0].properties
            address = ''
            if (response.city && response.district) {
              address += 'г. ' + response.city + ' ' + response.district
            }
            if (response.type === 'house') {
              address += ' ул. ' + response.street
              if (response.housenumber) {
                address += ' д. ' + response.housenumber
              } else {
                address += ' ' + response.name
              }
            } else if (response.type === 'street' || response.type === 'locality') {
              address += response.name
            }
            return address
          }
          return ''
        })
    }
  },
  beforeMount () {
    this.dateStart = dayjs(new Date().setMonth((new Date()).getMonth() - 1))
    this.dateStart = this.dateStart.format('YYYY-MM-DD')
    this.dateEnd = dayjs(new Date()).format('YYYY-MM-DD')
  },
  beforeDestroy () {
    this.abortLoading()
  },
  watch: {
    car (newCar, oldCar) {
      if (newCar?.id !== oldCar?.id) {
        this.clearItems()
      }
    }
  }
}
</script>
