<template>
  <div v-if="car.lastState && car.lastState.lat && !track.length">
    <l-marker
      :z-index-offset="isSelected ? 99 : 2"
      :lat-lng="[car.lastState.lat.value, car.lastState.lng.value]"
      @click="$router.push('/watch/' + car.id).catch(err => err)"
    >
      <l-icon v-if="isSelected"
        :icon-url="require('@/assets/icons/selectedMarker.svg')"
        :icon-retina-url="require('@/assets/icons/selectedMarker.svg')"
        :shadow-url="require('leaflet/dist/images/marker-shadow.png')"
      />
    </l-marker>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MapMarker',
  props: ['car'],
  computed: {
    isSelected () {
      return this.selectedCar && this.selectedCar.id === this.car.id
    },
    ...mapState('taxi/mapCars', {
      track: state => state.track
    }),
    ...mapGetters('taxi/mapCars', ['selectedCar'])
  }
}
</script>
