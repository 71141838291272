<template>
  <div class="page">
    <h1 class="h2">Изменить документ</h1>
    <component
      v-if="currentComponent"
      :is="currentComponent"
      :documentData="document.data"
      @update="handleData"
    />
    <button class="btn btn-primary" @click="updateDocument()">Записать</button>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import Swal from 'sweetalert2'
import Types from '@/views/driverDocuments/types'
import DocumentsAPI from '@/api/driver-documents'
import DriversAPI from '@/api/drivers'
import { mapActions } from 'vuex'

const components = {}
for (const typeName in Types) {
  components[Types[typeName].edit.name] = Types[typeName].edit.component
  components[Types[typeName].view.name] = Types[typeName].view.component
}

export default {
  name: 'UpdateDocument',
  components,
  props: ['driverId', 'documentId'],
  data () {
    return {
      isUpdating: false,
      currentComponent: null,
      document: {
        id: this.documentId,
        type: '',
        data: '',
        driver_id: ''
      }
    }
  },
  mounted () {
    DriversAPI.getItem({ id: this.driverId })
      .then(driver => {
        this.setTitle({
          items: [
            {
              title: 'Водители',
              url: '/drivers'
            }, {
              title: driver.last_name + ' ' + driver.first_name + ' ' + driver.middle_name,
              url: '/drivers/' + this.driverId
            }, {
              title: 'Изменить документ'
            }
          ]
        })
      })
    DocumentsAPI.getItem({ id: this.documentId }).then(response => {
      this.document = response
      this.currentComponent = Types[this.document.type].edit.name
    })
  },
  methods: {
    handleData ({ type, data }) {
      this.document.type = type
      this.document.data = data
    },
    updateDocument () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      DocumentsAPI.updateItem(this.document)
        .then(document => {
          this.isUpdating = false
          this.$router.push(`/drivers/${this.driverId}`)
          return document
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
