<template>
  <div :class="`alert ${stateClass} alert-state text-center`" role="alert">
    {{ stateMessage }}
  </div>
</template>

<script>
export default {
  name: 'ServiceState',
  props: ['distance', 'interval', 'afterService'],
  computed: {
    stateClass () {
      if (!this.distance && this.interval && this.afterService !== 'н/д' && this.afterService !== 'н/д о пробеге') {
        return 'alert-warning'
      }
      if (!this.distance || !this.interval) {
        return 'alert-secondary'
      }
      if (this.distance < -this.interval / 5) {
        return 'alert-danger'
      }
      if (this.distance < this.interval / 5) {
        return 'alert-warning'
      }

      return 'alert-success'
    },
    stateMessage () {
      if (!this.distance && this.interval && this.afterService !== 'н/д' && this.afterService !== 'н/д о пробеге') {
        return 'осталось 0 км'
      }
      if (!this.distance || !this.interval) {
        return 'н/д'
      }
      if (this.distance < -this.interval / 5) {
        return `просрочено на ${Math.abs(Math.round(this.distance))} км`
      }
      if (this.distance < 0) {
        return `просрочено на ${Math.abs(Math.round(this.distance))} км`
      }
      if (this.distance < this.interval / 5) {
        return `осталось ${Math.round(this.distance)} км`
      }

      return `осталось ${Math.round(this.distance)} км`
    }
  }
}
</script>

<style scoped>
  .alert-state {
    margin: 0;
    height: 30px;
    width: fit-content;
    height: fit-content;
    line-height: 30px;
    padding: 0px 15px;
    min-width: 10rem;
    border-radius: 15px;
    border: none;
  }
  .alert-danger {
    color: #BF543B;
    background-color: rgba(191, 84, 59, 0.15);
  }
  .alert-success {
    color: #92BF4E;
    background-color: rgba(146, 191, 78, 0.15);
  }
  .alert-warning {
    color: #F2B950;
    background-color: rgba(242, 185, 80, 0.15);
  }
  .alert-info {
    color: #62ADBF;
    background-color: rgba(98, 173, 191, 0.15);
  }
  .alert-secondary {
    color: #414A35;
    background-color: rgba(65, 74, 53, 0.15);
  }
</style>
