import APITemplate from '@/api/APITemplate'

const url = '/file'
const template = APITemplate(url)

export default {
  ...template,
  getContractTemplates () {
    const params = new URLSearchParams()
    params.append('type', 'contract-template')
    return template.fetchGet(`${url}/get-by-type?${params}`)
  },
  getWaybillTemplates () {
    const params = new URLSearchParams()
    params.append('type', 'waybill-template')
    return template.fetchGet(`${url}/get-by-type?${params}`)
  },
  getReceiveListsTemplates () {
    const params = new URLSearchParams()
    params.append('type', 'receive-list-template')
    return template.fetchGet(`${url}/get-by-type?${params}`)
  },
  uploadTemplates (formData) {
    return fetch(url + '/upload-templates', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      body: formData
    }).then(response => response.json())
  },
  uploadScan (formData) {
    return fetch(url + '/upload-scans', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      body: formData
    }).then(response => response.json())
  },
  archivateItem (id) {
    return fetch(`${url}/archivate?id=${id}`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  }
}
