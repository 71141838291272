let result = []

if (process.env.VUE_APP_MODULE_RESTRICTED_AREA === 'true') {
  result = [
    ...result,
    ...require('./restrictedArea/router').default
  ]
}

if (process.env.VUE_APP_MODULE_FUEL === 'true') {
  result = [
    ...result,
    ...require('./fuel/router').default
  ]
}

if (process.env.VUE_APP_MODULE_MAINTENANCE === 'true') {
  result = [
    ...result,
    ...require('./maintenance/router').default
  ]
}

export default result
