var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('h3',[_vm._v("Изменение работы техобслуживания")]),(_vm.work)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Название")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.work.name),expression:"work.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"","required":""},domProps:{"value":(_vm.work.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.work, "name", $event.target.value)}}})])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Интервал обслуживания")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.work.interval),expression:"work.interval"}],staticClass:"form-control",attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.work.interval)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.work, "interval", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Вложенные работы")]),_c('div',{staticClass:"draggable-cards-wrapper"},[_c('DraggableTree',{staticClass:"tree4",attrs:{"data":_vm.work.children,"draggable":"","cross-tree":"","indent":30,"space":0},on:{"change":_vm.changeItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var store = ref.store;
return _c('div',{},[(!data.isDragPlaceHolder)?[_c('div',{staticClass:"row text-dark py-2"},[_c('div',{staticClass:"col"},[(data.children && data.children.length)?_c('font-awesome-icon',{staticClass:"mx-2",style:({
                      color: 'gray',
                      'margin-left': 'auto',
                      cursor: 'pointer'
                    }),attrs:{"icon":data.open ? 'chevron-up' : 'chevron-down',"size":"1x"},on:{"click":function($event){return store.toggleOpen(data)}}}):_vm._e(),_vm._v(" "+_vm._s(data.name)+" "),_c('span',{staticClass:"badge",staticStyle:{"line-height":"unset"}},[_vm._v(" "+_vm._s(data.interval ? ("Каждые " + (data.interval) + " км.") : "")+" ")])],1),_c('div',{staticClass:"col-auto"},[_c('font-awesome-icon',{style:({
                      color: 'gray',
                      'margin-left': 'auto',
                      cursor: 'pointer'
                    }),attrs:{"icon":"plus","size":"1x"},on:{"click":function($event){return _vm.$router.push(("/maintenance/service-works/create/" + (data.id)))}}}),_c('font-awesome-icon',{style:({
                      color: 'gray',
                      'margin-left': '2.0rem',
                      cursor: 'pointer'
                    }),attrs:{"icon":"pen","size":"1x"},on:{"click":function($event){return _vm.$router.push(("/maintenance/service-works/update/" + (data.id)))}}}),_c('font-awesome-icon',{style:({
                      color: 'gray',
                      'margin-left': '1.5rem',
                      cursor: 'pointer'
                    }),attrs:{"icon":"trash-alt","size":"1x"},on:{"click":function($event){return _vm.removeItem(data.id)}}})],1)])]:_vm._e()],2)}}])})],1)])]),_c('hr',{staticClass:"mb-4"}),_c('div',{staticClass:"float-right btn-group"},[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":'/service-works/create/' + this.id}},[_vm._v(" Добавить ")]),_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.updateRecord}},[_vm._v("Сохранить")]),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/maintenance/service-works"}},[_vm._v("Назад")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }