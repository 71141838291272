import moduleTemplate from '@/store/moduleTemplate'
import filesAPI from '@/api/files'
const template = moduleTemplate(filesAPI)
let request = null
template.types = {
  ...template.types,
  setCurrentTab: 'setCurrentTab',
  loadContractTemplates: 'loadContractTemplates',
  loadWaybillTemplates: 'loadWaybillTemplates',
  loadReceiveListsTemplates: 'loadReceiveListsTemplates'
}
const tabTypes = {
  contractTemplates: 'contractTemplates',
  waybillTemplates: 'waybillTemplates',
  receiveListsTemplates: 'receiveListsTemplates'
}
const tabFileTypes = {
  contractTemplates: 'contract-template',
  waybillTemplates: 'waybill-template',
  receiveListsTemplates: 'receive-list-template'
}
template.mutations = {
  ...template.mutations,
  [template.types.loadContractTemplates] (state, { items }) {
    state.contractTemplates = items
  },
  [template.types.loadWaybillTemplates] (state, { items }) {
    state.waybillTemplates = items
  },
  [template.types.loadReceiveListsTemplates] (state, { items }) {
    state.receiveListsTemplates = items
  },
  [template.types.setCurrentTab] (state, value) {
    state.currentTab = value
  }
}
template.state = {
  ...template.state,
  contractTemplates: [],
  waybillTemplates: [],
  receiveListsTemplates: [],
  currentTab: tabTypes.contractTemplates
}

template.actions = {
  ...template.actions,
  loadContractTemplates ({ state, dispatch, commit }) {
    if (state.isLoading) {
      dispatch('abortLoading')
    }
    request = filesAPI.getContractTemplates()
    commit(template.types.setIsLoading, true)
    return dispatch('getTemplates', template.types.loadContractTemplates)
  },
  loadWaybillTemplates ({ state, dispatch, commit }) {
    if (state.isLoading) {
      dispatch('abortLoading')
    }
    request = filesAPI.getWaybillTemplates()
    commit(template.types.setIsLoading, true)
    return dispatch('getTemplates', template.types.loadWaybillTemplates)
  },
  loadReceiveListsTemplates ({ state, dispatch, commit }) {
    if (state.isLoading) {
      dispatch('abortLoading')
    }
    request = filesAPI.getReceiveListsTemplates()
    commit(template.types.setIsLoading, true)
    return dispatch('getTemplates', template.types.loadReceiveListsTemplates)
  },
  setContractTemplatesTab ({ commit }) {
    commit(template.types.setCurrentTab, tabTypes.contractTemplates)
  },
  setWaybillTemplatesTab ({ commit }) {
    commit(template.types.setCurrentTab, tabTypes.waybillTemplates)
  },
  setReceiveListsTemplatesTab ({ commit }) {
    commit(template.types.setCurrentTab, tabTypes.receiveListsTemplates)
  },
  loadCurrentTab ({ state, dispatch }) {
    switch (state.currentTab) {
      case tabTypes.receiveListsTemplates:
        dispatch('loadReceiveListsTemplates')
        break
      case tabTypes.contractTemplates:
        dispatch('loadContractTemplates')
        break
      case tabTypes.waybillTemplates:
        dispatch('loadWaybillTemplates')
        break
    }
  },
  loadAllTabs ({ commit, dispatch }) {
    dispatch('loadWaybillTemplates')
    dispatch('loadReceiveListsTemplates')
    dispatch('loadContractTemplates')
  },
  getTemplates ({ commit }, type) {
    return request.promise
      .then(params => {
        request = null
        commit(template.types.setIsLoading, false)
        commit(type, params)
        return params.items
      })
  },
  uploadTemplates ({ state, dispatch }, files) {
    const formData = new FormData()
    files.forEach(element => {
      formData.append('upfiles[]', element)
    })
    formData.append('type', tabFileTypes[state.currentTab])
    filesAPI
      .uploadTemplates(formData)
      .then(template => {
        // TODO: Оптимизировать при необходимости
        dispatch('loadAllTabs')
      })
  }
}

export default template
