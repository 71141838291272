/* eslint-disable camelcase */
import APITemplate from '@/api/APITemplate'
export const headers = {
  accept: 'application/json',
  'content-type': 'application/json'
}
const url = '/waybill'
const template = APITemplate(url)
template.getItems = config => {
  const params = new URLSearchParams()
  params.append('id', config.id)
  params.append('startDateTime', config.startDateTime)
  params.append('endDateTime', config.endDateTime)
  return template.fetchGet(`${url}/get-statics?${params}`)
}
/**
 * Получить информацию об адресе
 * URL: https://photon.komoot.io
 * Используются следующие параметры запроса:
 * - q - адрес
 * Сервис возвращает информацию об адресе (координаты, тип помещения, почтовый индекс и тд)
 */
template.getAddressByCoords = config => {
  return fetch('https://photon.komoot.io/reverse/?' +
    new URLSearchParams({
      limit: '1',
      lat: config.lat,
      lon: config.lon
    }), {
    method: 'GET',
    headers
  })
    .then(response => {
      if (response.status !== 200) {
        response.json()
        throw new Error('Ошибка при загрузке.')
      }
      return response.json()
    })
}
export default template
