<template>
  <div class="map-area-editor">
     <LMap
      :zoom="zoom"
      :center="center"
      :options="options"
      ref="map"
      @ready="initMap"
    >
    </LMap>
    <div class="map-area-menu">
      <div class="map-area-items">
        <div
          v-for="polygon of polygons"
          :key="polygon.id"
          :style="getStyleForAreaButton(polygon)"
          class="area-button"
          role="button"
          tabindex="0"
          @click="selectModel(polygon)"
        >
          <div class="area-title">{{ polygon.title || `територия ${polygon.id}` }}</div>
          <div class="area-subtitle">{{ getCenterTextForArea(polygon) }}</div>
        </div>
        <div v-if="polygons.length <= 0">
          Создайте новый полигон
        </div>
      </div>
      <hr>
      <div class="map-area-items">
        <AreaPropEditor
          v-if="selectedPolygon"
          :mapArea="selectedPolygon"
          @update="polygonUpdated"
          @delete="polygonDeleted"
        />
      </div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet-draw'
import { mapActions } from 'vuex'
import RestrictedArea from '../api'
import AreaPropEditor from '../components/AreaPropEditor'

export default {
  name: 'MapRestrictedArea',
  components: {
    AreaPropEditor
  },
  data () {
    return {
      selectedPolygon: null,
      polygons: [],
      zoom: 11,
      center: [57.1418583, 65.5413183],
      options: {
        zoomSnap: 0.5,
        zoomControl: false
      }
    }
  },
  computed: {
    getStyleForAreaButton () {
      return model => {
        let color = '#008000'
        if (model.color) {
          color = model.color
        }
        if (this.selectedPolygon === model) {
          return {
            background: color + '70'
          }
        }
        return {
          background: color + '30'
        }
      }
    },
    getCenterTextForArea () {
      return model => {
        let lat = 0
        let lng = 0
        for (const point of model.points) {
          lat += point[0]
          lng += point[1]
        }
        lat = (lat / model.points.length).toFixed(6)
        lng = (lng / model.points.length).toFixed(6)
        return `${lat}, ${lng}`
      }
    }
  },
  mounted () {
    RestrictedArea.getItems()
      .promise
      .then(({ items }) => this.initPolygons(items))
    this.setTitle({
      items: [
        {
          title: 'Геозоны',
          url: '/map-restricted-area'
        }
      ]
    })
  },
  methods: {
    initMap (map) {
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map)
      this._map = map
    },
    getLayerForModel (model) {
      for (const key in this._map._layers) {
        const layer = this._map._layers[key]
        if (layer.model === model) {
          return layer
        }
      }
      return null
    },
    selectModel (model) {
      this.selectedPolygon = model
      const group = this.getLayerForModel(model)
      if (group) {
        this._map.fitBounds(group.getBounds())
      }
    },
    initPolygons (polygons) {
      L.drawLocal.draw.toolbar.buttons.polygon = 'Добавить геозону'
      L.drawLocal.draw.handlers.polygon.tooltip.start = 'Добавить геозону'
      L.drawLocal.draw.handlers.polygon.tooltip.start = 'Нажмите, чтобы начать рисовать фигуру.'
      L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Нажмите, чтобы продолжить рисование фигуры.'
      L.drawLocal.draw.handlers.polygon.tooltip.end = 'Щелкните первую точку, чтобы закрыть эту форму.'
      L.drawLocal.draw.toolbar.actions.title = 'Прекратить рисовать'
      L.drawLocal.draw.toolbar.actions.text = 'Закрыть'
      L.drawLocal.draw.toolbar.finish.title = 'Сохранить геозону'
      L.drawLocal.draw.toolbar.finish.text = 'Сохранить'
      L.drawLocal.draw.toolbar.undo.title = 'Удалить последнюю точку'
      L.drawLocal.draw.toolbar.undo.text = 'Удалить последнюю точку'
      L.drawLocal.edit.toolbar.buttons.edit = 'Изменить геозоны'
      L.drawLocal.edit.handlers.edit.tooltip.text = 'Перетащите точки, чтобы редактировать объекты.'
      L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Нажмите "Отмена", чтобы отменить изменения.'
      L.drawLocal.edit.handlers.remove.tooltip.text = 'Нажмите на объект, чтобы удалить.'
      L.drawLocal.edit.toolbar.actions.save.title = 'Сохранить изменения'
      L.drawLocal.edit.toolbar.actions.save.text = 'Сохранить'
      L.drawLocal.edit.toolbar.actions.cancel.title = 'Отменить редактирование, отменить все изменения'
      L.drawLocal.edit.toolbar.actions.cancel.text = 'Отмена'
      L.drawLocal.edit.toolbar.actions.clearAll.title = 'Очистить все геозоны'
      L.drawLocal.edit.toolbar.actions.clearAll.text = 'Очистить все'
      L.drawLocal.edit.toolbar.buttons.edit = 'Редактировать геозоны'
      L.drawLocal.edit.toolbar.buttons.editDisabled = 'Нет геозон для редактирования'
      L.drawLocal.edit.toolbar.buttons.remove = 'Удалить геозоны'
      L.drawLocal.edit.toolbar.buttons.removeDisabled = 'Нет геозон для удаления'
      this.polygons = polygons
      const drawnItems = new L.FeatureGroup()
      this._map.addLayer(drawnItems)
      for (const model of this.polygons) {
        const layer = L.polygon(model.points, {
          color: model.color || '#008000',
          weight: 1,
          opacity: 0.7
        })
          .on('click', () => {
            this.selectedPolygon = model
          })
          .addTo(drawnItems)
        layer.model = model
      }

      const drawControl = new L.Control.Draw({
        draw: {
          polyline: false,
          polygon: {
            allowIntersection: false,
            drawError: {
              color: '#e1e100', // Color the shape will turn when intersects
              message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
            },
            shapeOptions: {
              color: '#008000',
              stroke: false
            }
          },
          circle: false,
          circlemarker: false,
          marker: false,
          rectangle: false
        },
        edit: {
          remove: false,
          featureGroup: drawnItems
        }
      })
      this._map.addControl(drawControl)
      this._map.on(L.Draw.Event.CREATED, event => {
        // const type = event.layerType
        const layer = event.layer

        this.createPolygonOnServer(event.layer)
          .then(model => {
            layer.setStyle({
              color: model.color || '#008000'
            })
              .on('click', () => {
                this.selectedPolygon = model
              })
              .addTo(drawnItems)
            layer.model = model
          })
        // this.polygons.push(event.layer.getLatLngs()[0].map(ll => [ll.lat, ll.lng]))
      })
      this._map.on(L.Draw.Event.DELETED, event => {
        for (const layer of event.layers.getLayers()) {
          this.deletePolygonOnServer(layer)
        }
      })
      this._map.on(L.Draw.Event.EDITED, event => {
        for (const layer of event.layers.getLayers()) {
          this.updatePolygonOnServer(layer)
        }
      })
    },
    createPolygonOnServer (layer) {
      layer.savingToServer = true
      return RestrictedArea
        .createItem({
          trigger_entry: false,
          trigger_escape: false,
          trigger_timeout_entry: 5,
          trigger_timeout_escape: 5,
          trigger_message_entry: '',
          trigger_message_escape: '',
          show_on_map: true,
          points: layer.getLatLngs()[0].map(ll => [ll.lat, ll.lng])
        })
        .then(({ model }) => {
          this.polygons.push(model)
          return model
        })
    },
    updatePolygonOnServer (layer) {
      if (!layer.model) {
        console.error('Ошибка обновления полигона, обновите страницу')
        return
      }
      // console.log('Update area ' + layer.model.id)
      layer.model.points = layer.getLatLngs()[0].map(ll => [ll.lat, ll.lng])
      RestrictedArea.updateItem(layer.model)
        .catch(err => {
          console.error('Ошибка удаления полигона, обновите страницу')
          console.error(err)
        })
    },
    deletePolygonOnServer (layer) {
      if (!layer.model) {
        console.error('Ошибка удаления полигона, обновите страницу')
        return
      }
      // console.log('Delete area ' + layer.model.id)
      RestrictedArea.removeItem(layer.model.id)
        .catch(err => {
          console.error('Ошибка удаления полигона, обновите страницу')
          console.error(err)
        })
    },
    polygonUpdated (polygon) {
      for (const index in this.polygons) {
        if (this.polygons[index].id === polygon.id) {
          for (const key in polygon) {
            this.$set(this.polygons[index], key, polygon[key])
          }
          polygon = this.polygons[index]
          break
        }
      }
      const layer = this.getLayerForModel(polygon)
      layer.setStyle({
        color: polygon.color || '#008000'
      })
    },
    polygonDeleted (polygon) {
      const index = this.polygons.findIndex(p => p === polygon)
      if (index >= 0) {
        const layer = this.getLayerForModel(polygon)
        this._map.removeLayer(layer)
        this.polygons.splice(index, 1)
        this.selectedPolygon = null
      }
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  },
  watch: {
    selectedPolygon: {
      immediate: true,
      handler (selectedPolygon) {
        if (!this._map) {
          return
        }
        for (const key in this._map._layers) {
          const layer = this._map._layers[key]
          if (!layer.model) {
            continue
          }
          if (layer.model === selectedPolygon) {
            layer.setStyle({
              opacity: 0.7
            })
            layer.bringToFront()
          } else {
            layer.setStyle({
              opacity: 0.2
            })
          }
        }
      }
    }
  }
}
</script>

<style src="leaflet-draw/dist/leaflet.draw.css">
</style>

<style scoped>
  .map-area-editor {
    display: flex;
    height: 100%;
  }
  .map-area-menu {
    padding: 15px;
    flex: 0 0 450px;
    overflow-y: scroll;
  }
  .area-button {
    padding: 2px 7px;
    margin-bottom: 2px;
  }
  .area-title {
    font-size: 1em;
  }
  .area-subtitle {
    font-size: 1em;
  }
</style>
