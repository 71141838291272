<template>
  <div class="page">
    <div class="d-flex flex-column">
      <div class="input-group without-separator">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <font-awesome-icon icon="search" style="color:gray" />
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск по марке, модели, рег. номеру, ФИО"
          aria-label="Поиск по марке, модели, рег. номеру, ФИО"
          @input="searchRent"
          :value="searchText"
        />
      </div>
      <div class="pt-2">
        <router-link to="/rent/create" class="btn btn-primary w-100">Сдать в аренду</router-link>
      </div>
    </div>

    <div class="card-body p-0 mt-3">
      <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: currentTab === 'active' }"
            @click.prevent="setActiveTab()"
            href="#active-rents"
          >Активные</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: currentTab === 'closed' }"
            @click.prevent="setClosedTab()"
            href="#closed-rents"
          >Завершенные</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: currentTab === 'draft' }"
            href="#draft-rents"
            @click.prevent="setDraftTab()"
          >Черновики ({{ draftCount }})</a>
        </li>
      </ul>
      <div class="tab-content">
        <!--<ACTIVE CONTRACTS>-->
        <div class="tab-pane fade" :class="{ 'active show': currentTab === 'active' }" id="active-rents">
          <table class="table table-hover w-100 d-none d-md-table">
            <thead>
              <tr>
                <th scope="col">№</th>
                <th>Автомобиль</th>
                <th>Водитель</th>
                <th>Адрес возврата</th>
                <th>Окончание аренды</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="activeContracts.length === 0">
                <td colspan="5" style="text-align: center">Ничего не найдено</td>
              </tr>
              <tr
                v-for="contract of activeContracts"
                :key="contract.id"
                @click="$router.push(`/rent/${contract.id}`)"
                role="button"
                tabindex="0"
                class="tablerow"
              >
                <td scope="row">{{ contract.number }}</td>
                <td>
                  <TextHighlight class="highlighter" :queries="searchText">
                    {{ contract.car.brand }} {{ contract.car.model }} - {{ contract.car.registration_plate }}
                  </TextHighlight>
                </td>
                <td>
                  <TextHighlight class="highlighter" :queries="searchText">
                    {{ contract.driver.last_name }} {{ contract.driver.first_name }} {{ contract.driver.middle_name }}
                  </TextHighlight>
                </td>
                <td>{{ contract.receiving_spot }}</td>
                <td>{{ contract.receiving_datetime | diffForHumans }}</td>
              </tr>
            </tbody>
          </table>
          <div class="mobile-table-view card mt-2 mb-2 p-2 d-md-none" v-for="contract of activeContracts" :key="contract.id">
            <table v-if="activeContracts.length > 0" class="table borderless">
              <tbody>
                <tr>
                  <td style="border-top: none">№</td>
                  <td style="border-top: none">
                    <TextHighlight class="highlighter" :queries="searchText">{{ contract.number }}</TextHighlight>
                  </td>
                </tr>
                <tr>
                  <td>Автомобиль</td>
                  <td>
                    <TextHighlight class="highlighter" :queries="searchText">
                      {{ contract.car.brand }} {{ contract.car.model }} - {{ contract.car.registration_plate }}
                    </TextHighlight>
                  </td>
                </tr>
                <tr>
                  <td>Водитель</td>
                  <td>
                    <TextHighlight class="highlighter" :queries="searchText">
                      {{ contract.driver.last_name }} {{ contract.driver.first_name }} {{ contract.driver.middle_name }}
                    </TextHighlight>
                  </td>
                </tr>
                <tr>
                  <td>Адрес возврата</td>
                  <td>{{ contract.receiving_spot }}</td>
                </tr>
                <tr>
                  <td>Окончание аренды</td>
                  <td>{{ contract.receiving_datetime | diffForHumans }}</td>
                </tr>
              </tbody>
            </table>
            <router-link
              :to="{ name: 'rentPage', params: { contractId: contract.id } }"
              class="btn btn-primary btn-md ml-auto"
            >
              Подробно
            </router-link>
          </div>
        </div>
        <!--</ACTIVE CONTRACTS>-->

        <!--<CLOSED CONTRACTS>-->
        <div class="tab-pane fade" :class="{ 'active show': currentTab === 'closed' }" id="closed-rents">
          <table class="table table-hover closed-table w-100 d-none d-md-table">
            <thead>
              <tr>
                <th scope="col">№</th>
                <th>Автомобиль</th>
                <th>Водитель</th>
                <th>Адрес возврата</th>
                <th>Окончание аренды</th>
                <th>Автомобиль принят</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="closedContracts.length === 0">
                <td colspan="6" style="text-align: center">Ничего не найдено</td>
              </tr>
              <tr
                v-for="contract of closedContracts"
                :key="contract.id"
                @click="$router.push(`/rent/${contract.id}`)"
                class="tablerow"
                role="button"
                tabindex="0"
              >
                <td scope="row" class="closed-number-col">{{ contract.number }}</td>
                <td>
                  <TextHighlight class="highlighter" :queries="searchText">
                    {{ contract.car.brand }} {{ contract.car.model }} - {{ contract.car.registration_plate }}
                  </TextHighlight>
                </td>
                <td>
                  <TextHighlight class="highlighter" :queries="searchText">
                    {{ contract.driver.last_name }} {{ contract.driver.first_name }} {{ contract.driver.middle_name }}
                  </TextHighlight>
                </td>
                <td>{{ contract.receiving_spot }}</td>
                <td>{{ contract.receiving_datetime | diffForHumans }}</td>
                <td>{{ contract.archived_at | diffForHumans }}</td>
              </tr>
            </tbody>
          </table>
          <div class="mobile-table-view card mt-2 mb-2 p-2 d-md-none" v-for="contract of closedContracts" :key="contract.id">
            <table v-if="closedContracts.length > 0" class="table borderless">
              <tbody>
                <tr>
                  <td style="border-top: none">№</td>
                  <td style="border-top: none">
                    <TextHighlight class="highlighter" :queries="searchText">{{ contract.number }}</TextHighlight>
                  </td>
                </tr>
                <tr>
                  <td>Автомобиль</td>
                  <td>
                    <TextHighlight class="highlighter" :queries="searchText">
                      {{ contract.car.brand }} {{ contract.car.model }} - {{ contract.car.registration_plate }}
                    </TextHighlight>
                  </td>
                </tr>
                <tr>
                  <td>Водитель</td>
                  <td>
                    <TextHighlight class="highlighter" :queries="searchText">
                      {{ contract.driver.last_name }} {{ contract.driver.first_name }} {{ contract.driver.middle_name }}
                    </TextHighlight>
                  </td>
                </tr>
                <tr>
                  <td>Адрес возврата</td>
                  <td>{{ contract.receiving_spot }}</td>
                </tr>
                <tr>
                  <td>Автомобиль принят</td>
                  <td>{{ contract.receiving_datetime | diffForHumans }}</td>
                </tr>
              </tbody>
            </table>
            <router-link
              :to="{ name: 'rentPage', params: { contractId: contract.id } }"
              class="btn btn-primary btn-md ml-auto"
            >
              Подробно
            </router-link>
          </div>
        </div>
        <!--</CLOSED CONTRACTS>-->
        <!--<DRAFT CONTRACTS>-->
        <div class="tab-pane fade" :class="{ 'active show': currentTab === 'draft' }" id="draft-rents">
          <table class="table table-hover w-100 d-none d-md-table">
            <thead>
              <tr>
                <th>Автомобиль</th>
                <th>Водитель</th>
                <th>Адрес возврата</th>
                <th>Окончание аренды</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="draftContracts.length === 0">
                <td colspan="4" style="text-align: center">Ничего не найдено</td>
              </tr>
              <tr
                v-for="contract of draftContracts"
                :key="contract.id"
                @click="$router.push(`/rent/${contract.id}`)"
                class="tablerow"
              >
                <td>
                  <TextHighlight class="highlighter" :queries="searchText">
                    {{ contract.car.brand }} {{ contract.car.model }} - {{ contract.car.registration_plate }}
                  </TextHighlight>
                </td>
                <td>
                  <TextHighlight class="highlighter" :queries="searchText">
                    {{ contract.driver.last_name }} {{ contract.driver.first_name }} {{ contract.driver.middle_name }}
                  </TextHighlight>
                </td>
                <td>{{ contract.receiving_spot }}</td>
                <td>{{ contract.receiving_datetime | diffForHumans }}</td>
              </tr>
            </tbody>
          </table>
          <div class="mobile-table-view card mt-2 mb-2 p-2 d-md-none" v-for="contract of draftContracts" :key="contract.id">
            <table v-if="draftContracts.length > 0" class="table borderless">
              <tbody>
                <tr>
                  <td style="border-top: none">№</td>
                  <td style="border-top: none">
                    <TextHighlight class="highlighter" :queries="searchText">{{ contract.number }}</TextHighlight>
                  </td>
                </tr>
                <tr>
                  <td>Автомобиль</td>
                  <td>
                    <TextHighlight class="highlighter" :queries="searchText">
                      {{ contract.car.brand }} {{ contract.car.model }} - {{ contract.car.registration_plate }}
                    </TextHighlight>
                  </td>
                </tr>
                <tr>
                  <td>Водитель</td>
                  <td>
                    <TextHighlight class="highlighter" :queries="searchText">
                      {{ contract.driver.last_name }} {{ contract.driver.first_name }} {{ contract.driver.middle_name }}
                    </TextHighlight>
                  </td>
                </tr>
                <tr>
                  <td>Адрес возврата</td>
                  <td>{{ contract.receiving_spot }}</td>
                </tr>
                <tr>
                  <td>Окончание аренды</td>
                  <td>{{ contract.receiving_datetime | diffForHumans }}</td>
                </tr>
              </tbody>
            </table>
            <router-link
              :to="{ name: 'rentPage', params: { contractId: contract.id } }"
              class="btn btn-primary btn-md ml-auto"
            >
              Подробно
            </router-link>
          </div>
        </div>
        <!--</DRAFT CONTRACTS>-->
      </div>
    </div>
  </div>
</template>

<script>
import { djsMixin } from '@/mixins/djsMixin'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  name: 'Contracts',
  mixins: [djsMixin],
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  mounted () {
    this.loadAllTabs()
    this.setTitle({
      items: [
        {
          title: 'Аренда'
        }
      ]
    })
  },
  data () {
    return {
      searchData: this.searchText
    }
  },
  computed: {
    ...mapState('taxi/contracts', {
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading,
      searchText: state => state.searchText,
      draftCount: state => state.draftCount,
      currentTab: state => state.currentTab
    }),
    ...mapGetters('taxi/contracts', [
      'activeContracts',
      'closedContracts',
      'draftContracts'
    ])
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/contracts', [
      'loadCurrentTab',
      'setSearchText',
      'loadAllTabs',
      'setActiveTab',
      'setClosedTab',
      'setDraftTab',
      'abortLoading',
      'removeItem'
    ]),
    searchRent (e) {
      this.setSearchText(e.target.value)
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  },
  watch: {
    page () {
      this.loadItems({ page: Number(this.page) })
    },
    currentTab () {
      this.loadCurrentTab()
    }
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1199px) {
    .page {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 576px) {
    .page {
      padding: 0.5rem;
    }
  }
  .card-header .btn-link {
    color: #0597f2;
  }
  .card-header {
    background-color: white !important;
  }
  .card-header {
    padding-bottom: 0;
  }
  th {
    font-weight: 600;
    font-size: 15.7px;
  }
  .highlighter ::v-deep .text__highlight {
    border-radius: 0px;
    padding: 0.2em 0 0.2em 0;
    background: #fff2ae;
  }
  .closed-table {
    td {
      word-break: break-word;
    }
    .closed-number-col {
      word-break: keep-all;
    }
  }
</style>
