<template>
  <div>
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Серия</label>
        <IMaskComponent
          type="text"
          class="form-control"
          v-model="data.series"
          required
        />
      </div>
      <div class="col-md-4 mb-2">
        <label>Номер</label>
        <IMaskComponent
          type="text"
          class="form-control"
          v-model="data.number"
          required
        />
      </div>
    </div>
    <hr class="mb-4" />
  </div>
</template>

<script>
export default {
  name: 'DocumentPassCarriageLicenceEdit',
  props: ['documentData'],
  data () {
    return {
      data: {
        number: '',
        series: ''
      }
    }
  },
  mounted () {
    if (this.documentData) {
      this.data = this.documentData
    }
  },
  watch: {
    data: {
      immediate: true,
      handler () {
        this.$emit('update', {
          type: 'pass_carriage_licence',
          data: this.data
        })
      },
      deep: true
    }
  }
}
</script>
