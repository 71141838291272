/* eslint-disable camelcase */
import APITemplate from '@/api/APITemplate'

const url = '/driver'
const template = APITemplate(url)
template.getItems = config => {
  const params = new URLSearchParams()
  params.append('sort', '-id')
  if (config && config.page !== undefined && config.pageSize !== undefined) {
    params.append('pagination[page]', (config.page - 1).toString())
    params.append('pagination[pageSize]', config.pageSize)
  }
  if (config && config.searches !== undefined) {
    Object.keys(config.searches).forEach(key => {
      params.append(`filter[or][][${key}][like]`, config.searches[key])
    })
  }
  return template.fetchGet(`${url}?${params}`)
}
export default template
