import moduleTemplate from '@/store/moduleTemplate'
import serviceWorkApi from '../api/serviceWork'

const template = moduleTemplate(serviceWorkApi)
template.types = {
  ...template.types,
  setItems: 'setSearchText'
}
template.mutations = {
  ...template.mutations,
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  }
}

template.actions = {
  ...template.actions,
  updateItem ({ state, commit, dispatch }, params) {
    serviceWorkApi.updateItem(params)
  },
  getWorkById ({ state, commit, dispatch }, params) {
    return serviceWorkApi.getWorkById(params)
  }
}

export default template
