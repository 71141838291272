<template>
  <div class="page">
    <div class="d-flex flex-column">
      <div class="input-group without-separator">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <font-awesome-icon icon="search" style="color:gray" />
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск по марке, модели, рег. номеру"
          aria-label="Поиск по марке, модели, рег. номеру"
          @input="searchServe"
          :value="searchText"
        />
      </div>
    </div>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr v-for="car of cars" :key="car.id" @click="$router.push(`/maintenance/car/${car.id}`)">
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.model }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.registration_plate }}</TextHighlight>
          </td>
          <td>
            <ServiceState
              v-if="car.nextServiceRecord"
              :distance="car.nextServiceRecord.distRemains"
              :interval="car.nextServiceRecord.interval"
            />
          </td>
          <td>
            <OsagoState
              :osago="car.documents.find(document => document.type === 'osago')"
              :days="car.osagoRemains"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mobile-table-view card mt-2 mb-2 p-2 d-md-none" v-for="car of cars" :key="car.id">
      <table v-if="cars.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Марка автомобиля</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Модель автомобиля</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ car.model }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Рег. номер</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ car.registration_plate }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Следующее ТО</td>
            <td>
              <ServiceState
                v-if="car.nextServiceRecord"
                :distance="car.nextServiceRecord.distRemains"
                :interval="car.nextServiceRecord.interval"
              />
            </td>
          </tr>
          <tr>
            <td>ОСАГО</td>
            <td>
              <OsagoState
                :osago="car.documents.find(document => document.type === 'osago')"
                :days="car.osagoRemains"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'servePage', params: { carId: car.id } }"
        class="btn btn-primary btn-md create-serve ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { djsMixin } from '@/mixins/djsMixin'
import OsagoState from '@/views/carDocuments/OsagoState'
import ServiceState from '../components/ServiceState'

export default {
  name: 'Serve',
  components: {
    OsagoState,
    ServiceState
  },
  mixins: [djsMixin],
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Тех. обслуживание'
        }
      ]
    })
  },
  computed: {
    ...mapState('maintenance/serviceCar', {
      cars: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    })
  },
  beforeDestroy () {
    this.abortLoading()
  },
  data () {
    return {
      sorts: [
        {
          name: 'Марка автомобиля',
          storeName: 'servecar-brand',
          values: ['', 'brand', '-brand'],
          order: 0
        }, {
          name: 'Модель автомобиля',
          storeName: 'servecar-model',
          values: ['', 'model', '-model'],
          order: 1
        }, {
          name: 'Рег. номер',
          storeName: 'servecar-registration_plate',
          values: ['', 'registration_plate', '-registration_plate'],
          order: 2
        }, {
          name: 'Следующее ТО',
          storeName: 'servecar-nextService',
          values: ['', 'nextServiceDist', '-nextServiceDist'],
          order: 3
        }, {
          name: 'ОСАГО',
          storeName: 'servecar-osago',
          values: ['', 'nextOsagoRemains', '-nextOsagoRemains'],
          order: 4,
          index: 2
        }
      ]
    }
  },
  methods: {
    ...mapActions('maintenance/serviceCar', [
      'loadItems',
      'setSearchText',
      'setSearch',
      'abortLoading',
      'removeItem',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    searchServe (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'brand', value: e.target.value })
      this.setSearch({ key: 'model', value: e.target.value })
      this.setSearch({ key: 'registration_plate', value: e.target.value })
      this.loadItems({ page: Number(this.page) })
    },
    leftUntil (planItem) {
      if (!this.car || !this.car.lastState || !this.car.lastState.totalOdometer) {
        return 'н/д о пробеге'
      }
      if (!planItem.total_odometer_stamp) {
        return 'н/д'
      }

      const odometerDiff = planItem.interval - (Math.ceil(this.car.lastState.totalOdometer.value / 1000) - planItem.total_odometer_stamp)
      switch (Math.sign(odometerDiff)) {
        case 1:
          return `осталось ${odometerDiff} км`
        case -1:
          return `просрочено на ${Math.abs(odometerDiff)} км`
        case 0:
          return 'осталось 0 км'
        case NaN:
          return 'н/д'
      }
    },
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
    }
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1199px) {
    .page {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 576px) {
    .page {
      padding: 0.5rem;
    }
  }
  .highlighter ::v-deep .text__highlight {
    border-radius: 0px;
    padding: 0.2em 0 0.2em 0;
    background: #fff2ae;
  }
</style>
