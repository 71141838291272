import APITemplate from '@/api/APITemplate'

const url = '/service-work'
const template = APITemplate(url)

export default {
  ...template,
  archivateItem (id) {
    return fetch(`${url}/archivate?id=${id}`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  getActiveServiceWorks () {
    return fetch(`${url}/get-active-service-works`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  getWorkById (id) {
    return fetch(`${url}/get-work-by-id?id=${id}`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  }
}
