import moduleTemplate from '@/store/moduleTemplate'
import carFuelingAPI from '../api/carFueling'

const template = moduleTemplate(carFuelingAPI)

template.types = {
  ...template.types,
  setSearchText: 'setSearchText'
}

template.mutations = {
  ...template.mutations,
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  }
}

template.state = {
  ...template.state,
  searchText: ''
}

template.actions = {
  ...template.actions,
  setSearchText ({ commit }, searchText) {
    commit(template.types.setSearchText, searchText)
  },
  importFueling ({ state, dispatch }, files) {
    const formData = new FormData()
    files.forEach(element => {
      formData.append('import-files[]', element)
    })
    return carFuelingAPI.importFueling(formData)
  }
}

export default template
