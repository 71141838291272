<template>
  <div class="page page-equipments">
    <h1 class="h2">Комплектность автомобиля</h1>
    <button class="btn btn-primary btn-save" @click="saveEquipments()">Записать</button>
    <table class="table">
      <thead>
        <th>#</th>
        <th>Наименование</th>
        <th>Количество</th>
        <th>ЕИ</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="(equipment, index) in equipments" :key="index">
          <td>{{ equipment.id ? equipment.id : '*' }}</td>
          <td>
            <input
              type="text"
              class="form-control form-control"
              v-model="equipment.name"
              placeholder
              required
              @input="updateEquipment(equipment)"
            />
          </td>
          <td class="sm-col">
            <input
              type="text"
              class="form-control form-control"
              v-model="equipment.count"
              placeholder
              required
              @input="updateEquipment(equipment)"
            />
          </td>
          <td class="sm-col">
            <VSelect
              :clearable="false"
              :options="['шт', 'к-т']"
              v-model="equipment.measure_units"
              label="measure_units"
              @input="updateEquipment(equipment)"
            />
          </td>
          <td style="text-align: right; cursor: default">
            <span @click="deleteEquipment(equipment)" class="delete-button">Удалить</span>
          </td>
        </tr>
        <tr @click="addEquipment">
          <td colspan="5" class="add-row border-bottom">Новая строка</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CarsAPI from '@/api/cars'
import EquipmentsAPI from '@/api/equipments'
import Swal from 'sweetalert2'

export default {
  name: 'EquipmentsPage',
  props: ['carId'],
  data () {
    return {
      equipments: [],
      promises: [],
      toCreate: [],
      toDelete: [],
      toUpdate: [],
      isLoading: false
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    saveEquipments () {
      if (this.isLoading) {
        return
      }
      if (this.toCreate.length || this.toDelete.length || this.toUpdate.length) {
        this.isLoading = true
      }
      this.toCreate.forEach(equipment => {
        this.promises.push(EquipmentsAPI.createItem(equipment).then(response => {
          this.toCreate = this.toCreate.filter(element => element !== equipment)
          equipment.id = response.model.id
        }))
      })
      this.toDelete.forEach(equipment => {
        this.promises.push(EquipmentsAPI.removeItem(equipment.id).then(() => {
          this.toDelete = this.toDelete.filter(element => element !== equipment)
        }))
      })
      this.toUpdate.forEach(equipment => {
        this.promises.push(EquipmentsAPI.updateItem(equipment).then(() => {
          this.toUpdate = this.toUpdate.filter(element => element !== equipment)
        }))
      })
      Promise.all(this.promises)
        .then(() => {
          this.promises = []
          this.isLoading = false
          this.$router.push(`/cars/${this.carId}`)
        })
        .catch(err => {
          console.error(err)
          this.promises = []
          this.isLoading = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    addEquipment () {
      const newEquip = {
        id: null,
        name: '',
        count: '',
        measure_units: 'шт',
        car_id: this.carId
      }
      const len = this.equipments.push(newEquip)
      this.toCreate.push(this.equipments[len - 1])
    },
    deleteEquipment (equipment) {
      const createIndex = this.toCreate.findIndex(element => element === equipment)
      const updateIndex = this.toUpdate.findIndex(element => element === equipment)

      // Если запись была создана ранее (на сервере) - добавить её в список удаляемых
      // Иначе (если запись еще не создана) - удалить её из списка создваемых
      if (createIndex === -1) {
        this.toDelete.push(equipment)
      } else {
        this.toCreate.splice(createIndex, 1)
      }

      // Если запись была в списке изменяемых записей - удалить из этого списка
      if (updateIndex !== -1) {
        this.toUpdate.splice(updateIndex, 1)
      }

      this.equipments = this.equipments.filter(element => element !== equipment)
    },
    updateEquipment (equipment) {
      if (!this.toUpdate.includes(equipment) && equipment.id) {
        this.toUpdate.push(equipment)
      }
    },
    getData () {
      CarsAPI.getItem({ id: this.carId, queryWith: ['equipments'] }).then(response => {
        this.equipments = response.equipments
        this.toCreate = []
        this.toDelete = []
        this.toUpdate = []
      })
    }
  }
}
</script>
<style scoped>
  .page-equipments {
    width: 98%;
    padding-bottom: 4rem;
  }
  .btn-save {
    float: right;
    margin-bottom: 0.7rem;
  }
  .add-row {
    text-align: center;
    vertical-align: middle;
  }
  .add-row:hover {
    color: #007bff !important;
    cursor: pointer;
  }
  .sm-col {
    width: 10%;
    min-width: 6rem;
  }
  .delete-button:hover {
    cursor: pointer;
    color: #007bff;
  }
</style>
