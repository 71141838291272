import Vue from 'vue'
import MapMarker from './MapMarker'
import SelectedMarker from './SelectedMarker'
import MapCard from './mapCard/Card'
import WatchState from './WatchState'
import CardSelected from './mapCard/CardSelected'
import MapCardInfo from './mapCard/Info'
import MapCardHistory from './mapCard/History'
import MapCardCommands from './mapCard/Commands'
import Track from './Track'

import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LIcon,
  LFeatureGroup,
  LPolyline,
  LControlAttribution,
  LControlZoom
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

import { Icon } from 'leaflet'

/* Фикс отображения маркера */
delete Icon.Default.prototype._getIconUrl
// leaflet/dist/images/marker-icon.png

Icon.Default.mergeOptions({
  iconRetinaUrl: require('@/assets/icons/defaultMarker.svg'),
  iconUrl: require('@/assets/icons/defaultMarker.svg'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.component('LMap', LMap)
Vue.component('LTileLayer', LTileLayer)
Vue.component('LMarker', LMarker)
Vue.component('LTooltip', LTooltip)
Vue.component('LIcon', LIcon)
Vue.component('LFeatureGroup', LFeatureGroup)
Vue.component('LPolyline', LPolyline)
Vue.component('LControlAttribution', LControlAttribution)
Vue.component('LControlZoom', LControlZoom)
Vue.component('MapMarker', MapMarker)
Vue.component('SelectedMarker', SelectedMarker)
Vue.component('MapCard', MapCard)
Vue.component('WatchState', WatchState)
Vue.component('CardSelected', CardSelected)
Vue.component('MapCardInfo', MapCardInfo)
Vue.component('MapCardHistory', MapCardHistory)
Vue.component('MapCardCommands', MapCardCommands)
Vue.component('Track', Track)
