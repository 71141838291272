import Cars from '@/views/Cars'
import CreateCar from '@/views/cars/CreateCar'
import CarPage from '@/views/cars/CarPage'
import CreateCarDocument from '@/views/carDocuments/CreateDocument'
import UpdateCarDocument from '@/views/carDocuments/UpdateDocument'
import EquipmentsPage from '@/views/EquipmentsPage'

export default [
  {
    path: '/cars/:carId/equipments',
    component: EquipmentsPage,
    props: true,
    meta: {
      title: 'Автомобили',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/cars/:carId/docs/create',
    component: CreateCarDocument,
    props: true,
    meta: {
      title: 'Автомобили',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/cars/:carId/docs/:documentId/update',
    component: UpdateCarDocument,
    props: true,
    meta: {
      title: 'Автомобили',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/cars',
    component: Cars,
    meta: {
      title: 'Автомобили',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/cars/page/:page',
    component: Cars,
    props: true,
    meta: {
      title: 'Автомобили',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/cars/create',
    component: CreateCar,
    meta: {
      title: 'Автомобили',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/cars/:carId',
    component: CarPage,
    name: 'carPage',
    props: true,
    meta: {
      title: 'Автомобили',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
