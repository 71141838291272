<template>
  <div class="page page-service-works">
    <div class="d-flex justify-content-end">
      <router-link to="/maintenance/service-works/create" class="btn btn-success">
        Добавить
      </router-link>
    </div>
    <br />
    <div class="draggable-cards-wrapper">
      <DraggableTree
        @change="changeItems"
        class="tree4"
        :data="works"
        draggable
        cross-tree
        :indent="30"
        :space="0"
      >
        <div slot-scope="{data, store}">
          <template v-if="!data.isDragPlaceHolder">
            <div class="row text-dark py-2">
              <div class="col">
                <font-awesome-icon
                  v-if="data.children && data.children.length"
                  :icon="data.open ? 'chevron-up' : 'chevron-down'"
                  class="mx-2"
                  @click="store.toggleOpen(data)"
                  :style="{
                    color: 'gray',
                    'margin-left': 'auto',
                    cursor: 'pointer'
                  }"
                />
                {{ data.name }}
                <span class="badge" style="line-height: unset">
                  {{ data.interval ? `Каждые ${data.interval} км.` : "" }}
                </span>
              </div>
              <div class="col-auto right-icons">
                <router-link :to="`/maintenance/service-works/create/${data.id}`" class="btn">
                  <font-awesome-icon icon="plus" />
                </router-link>
                <router-link :to="`/maintenance/service-works/update/${data.id}`" class="btn">
                  <font-awesome-icon icon="pen" />
                </router-link>
                <div
                  class="btn"
                  role="button"
                  tabindex="0"
                  @click="removeItem(data.id)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </DraggableTree>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import serviceWorkApi from '@/modules/maintenance/api/serviceWork'

export default {
  name: 'ServiceWorks',
  mounted () {
    this.loadItems({ sort: 'sort' })
    this.setTitle({
      items: [
        {
          title: 'Тех. обслуживание',
          url: '/maintenance'
        }, {
          title: 'Работы'
        }
      ]
    })
  },
  beforeDestroy () {
    this.abortLoading()
  },
  data () {
    return {
      editable: true,
      isDragging: false,
      delayedDragging: false,
      worksCopy: null
    }
  },
  computed: {
    ...mapState('maintenance/serviceWork', {
      worksState: state => state.items.filter(e => !e.archived_at),
      isLoading: state => state.isLoading
    }),
    works: {
      get () {
        if (!this.worksCopy) {
          return JSON.parse(JSON.stringify(this.worksState))
        }

        return this.worksCopy
      },
      set (value) {
        value.forEach((v, i) => {
          this.updateItem({ ...v, sort: i })
        })
        this.worksCopy = value
      }
    }
  },
  methods: {
    changeItems (node, targetTree, oldTree) {
      node.parent.children.forEach((v, i) => {
        this.updateItem({
          id: v.id,
          name: v.name,
          parent_work_id: v.parent.id ? v.parent.id : null,
          interval: v.interval,
          created_at: v.created_at,
          archived_at: v.archived_at,
          sort: i
        })
      })
    },
    removeItem (id) {
      serviceWorkApi
        .archivateItem(id)
        .then(() => this.loadItems({ sort: 'sort' }))
    },
    setOpenProp (works) {
      works.forEach(work => {
        work.open = false
        if (work.children.length) {
          this.setOpenProp(work.children)
        }
      })
    },
    ...mapActions('taxi', ['setTitle']),
    ...mapActions('maintenance/serviceWork', [
      'loadItems',
      'updateItem',
      'setSorts',
      'abortLoading'
    ])
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1199px) {
    .page {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 576px) {
    .page {
      padding: 0.5rem;
    }
  }
  .page-service-works {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  .draggable-cards-wrapper {
    height: 100%;
    flex-direction: column;
    flex-flow: nowrap;
  }
  ::v-deep .tree-node-inner {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
  }
  ::v-deep .tree-node-inner:hover {
    background: #e3e3e3 !important;
    cursor: grabbing;
  }
  ::v-deep .draggable-placeholder-inner {
    background: #e3e3e3 !important;
  }
  .right-icons * {
    color: gray;
  }
</style>
