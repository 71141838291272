<template>
  <div class="page page-companies">
    <h3>Организация</h3>
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeCompany"
      @cancelAction="cancelCompanyChanges"
      @deleteAction="deleteCompany"
      @saveAction="updateCompany"
    />
    <br />
    <Edit v-if="isEditing" class="company-view-edit" :company="company" />
    <Show v-else class="company-view-edit" :company="company" />
  </div>
</template>

<script>
/* eslint-disable camelcase */

import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import deepAssign from 'assign-deep'
import Edit from './Edit'
import Show from './Show'
import companiesAPI from '@/api/companies'

export default {
  name: 'CompanyPage',
  props: ['id'],
  components: {
    Edit,
    Show
  },
  data () {
    return {
      isUpdating: false,
      prevCompany: null,
      company: {},
      isEditing: false
    }
  },
  watch: {
    company: {
      handler (company, companyOld) {
        if (company?.id && company?.id !== companyOld?.id) {
          this.setTitle({
            items: [
              {
                title: 'Организации',
                url: '/companies'
              },
              {
                title: company.name
              }
            ]
          })
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    updateCompany () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      companiesAPI.updateItem(this.company)
        .then(company => {
          this.company = company.model
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadData () {
      companiesAPI.getItem({ id: this.id })
        .then(response => {
          this.company = response
        })
    },
    changeCompany () {
      this.prevcompany = deepAssign({}, this.company)
      this.isEditing = true
    },
    cancelCompanyChanges () {
      this.company = this.prevcompany
      this.isEditing = false
    },
    deleteCompany () {
      companiesAPI.removeItem(this.company.id)
        .then(() => {
          this.$router.push('/companies')
        })
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style scoped>
  .page-companies {
    width: 98%;
    padding-bottom: 4rem;
    padding-top: 2.5rem;
  }
</style>
