<template>
  <div>

    <div class="btn-group m-1">
      <button class="btn btn-outline-danger" type="button" @click="lockIgnition">
        Заблокировать зажигание
      </button>
      <button class="btn btn-outline-success" type="button" @click="unlockIgnition">
        Разблокировать зажигание
      </button>
    </div>
    <div>
      <button class="btn btn-light m-1" type="button" @click="fastConfig">
        Быстрое конфигурирование
      </button>
      <button class="btn btn-info m-1" type="button" @click="anyCommand">
        Произвольная команда
      </button>
    </div>
    <hr>
    <div>Отправить команду через очередь <input type="checkbox" v-model="useQueue"></div>
    <div title="Если не заданно то то ограничения нет">Ограничение по времени:</div>
    <div class="input-group" title="Если не заданно то то ограничения нет">
      <input type="text" class="form-control" placeholder="Ограничения нет" v-model.number="taskTimeout">
      <div class="input-group-append">
        <span class="input-group-text">мин.</span>
      </div>
    </div>
    <hr>
    <div v-for="task of tasks" :key="task.id" :class="getTaskClass(task)">
      <a
        v-if="task.status === 'wait'"
        class="btn float-right"
        style="margin: -5px"
        role="button"
        tabindex="0"
        @click="taskReject(task)"
      >
        <font-awesome-icon icon="times" />
      </a>
      <div class="task-title">{{ getTaskTitle(task) }}<span v-if="task.author" class="task-title-user"> {{ task.author.nameFormatted }}</span></div>
      <div class="task-command">{{ task.request_title }}</div>
      <div v-if="task.status !== 'complete success' && task.rejected_reason" class="task-subtitle">{{ task.rejected_reason }}</div>
      <div class="task-time">
        <span class="task-time-left">создано: {{ task.created_at | formatDateTimeShort }}</span>
        <span class="task-time-right">попытка: {{ task.try_at | formatDateTimeShort }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { paramCase } from 'change-case'
import { mapGetters } from 'vuex'
import commandsApi from '@/api/commands'
import deviceCommandTaskApi from '@/api/deviceCommandTask'

export default {
  name: 'MapCardCommands',
  data () {
    return {
      useQueue: true,
      taskTimeout: 60,
      tasks: [],
      taskRequest: null
    }
  },
  computed: {
    remainingAttempts () {
      if (!this.useQueue) {
        return 0
      }
      if (!this.taskTimeout) {
        return 999999999
      }
      return this.taskTimeout
    },
    getTaskClass () {
      return task => paramCase('task-' + task.status)
    },
    getTaskTitle () {
      return task => {
        switch (task.status) {
          case 'wait':
            return 'Ожидание'
          case 'complete success':
            return 'Отправлена'
          case 'complete error':
            return 'Не отправлена'
        }
        return 'Неопределенный статус'
      }
    },
    ...mapGetters('taxi/mapCars', ['selectedCar'])
  },
  methods: {
    taskReject (task) {
      commandsApi.taskReject({ id: task.id })
        .then(() => this.updateTasks())
        .catch(error => Swal.fire({
          title: 'Ошибка',
          text: error.message
        }))
    },
    lockIgnition () {
      Swal.fire({
        title: 'Отключить зажигание?',
        showCancelButton: true,
        confirmButtonText: 'Отправить',
        cancelButtonText: 'Отмена'
      })
        .then(({ value }) => {
          if (value) {
            commandsApi.lockIgnition({
              imei: this.selectedCar.imei,
              remaining_attempts: this.remainingAttempts
            })
              .then(answer => {
                Swal.fire({
                  title: 'Ответ устройства',
                  text: answer.result.response
                })
                this.updateTasks()
              })
              .catch(error => Swal.fire({
                title: 'Ошибка',
                text: error.message
              }))
          }
        })
    },
    unlockIgnition () {
      commandsApi.unlockIgnition({
        imei: this.selectedCar.imei,
        remaining_attempts: this.remainingAttempts
      })
        .then(answer => {
          Swal.fire({
            title: 'Ответ устройства',
            text: answer.result.response
          })
          this.updateTasks()
        })
        .catch(error => Swal.fire({
          title: 'Ошибка',
          text: error.message
        }))
    },
    anyCommand () {
      Swal.fire({
        title: 'Введите комманду',
        input: 'text',
        showCancelButton: true,
        cancelButtonText: 'Отмена',
        inputValidator: value => !value ? 'Команда не может быть пустой!' : null
      }).then(command => {
        if (!command.dismiss) {
          commandsApi.sendCommand({
            imei: this.selectedCar.imei,
            remaining_attempts: this.remainingAttempts,
            command: command.value
          })
            .then(answer => {
              Swal.fire({
                title: 'Ответ устройства',
                text: answer.result.response
              })
              this.updateTasks()
            })
            .catch(error => Swal.fire({
              title: 'Ошибка',
              text: error.message
            }))
        }
      })
    },
    fastConfig () {
      commandsApi.fastConfig({
        imei: this.selectedCar.imei,
        remaining_attempts: this.remainingAttempts
      })
        .then(answer => {
          Swal.fire({
            title: 'Ответ устройства',
            text: answer.result.response
          })
          this.updateTasks()
        })
        .catch(error => Swal.fire({
          title: 'Ошибка',
          text: error.message
        }))
    },
    updateTasks () {
      clearTimeout(this._taskRequestTimeout)
      if (this.taskRequest) {
        this.taskRequest.abort()
      }
      if (!this.selectedCar) {
        return
      }
      this.taskRequest = deviceCommandTaskApi
        .getItems({
          sort: ['-created_at'],
          extra: {
            key: 'filter[tracking_device_id]',
            value: this.selectedCar.tracking_device_id
          },
          expand: ['author']
        })
      this.taskRequest
        .promise
        .then(responce => {
          this.tasks = responce.items
          this.taskRequest = null
          this._taskRequestTimeout = setTimeout(() => this.updateTasks(), 30000)
        })
    }
  },
  beforeDestroy () {
    if (this.taskRequest) {
      clearTimeout(this._taskRequestTimeout)
      this.taskRequest.abort()
      this.taskRequest = null
      this._taskRequestTimeout = null
    }
  },
  watch: {
    selectedCar: {
      immediate: true,
      handler () {
        this.updateTasks()
      }
    }
  }
}
</script>

<style scoped>
  .task-wait,
  .task-complete-success,
  .task-complete-error {
    padding: 14px;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .task-title {
    font-size: 16px;
    font-weight: bolder;
    width: 100%;
  }
  .task-title-user {
    font-size: 14px;
    font-weight: normal;
    color: #555;
  }
  .task-subtitle {
    font-size: 15px;
    color: #333;
  }
  .task-command {
    font-size: 16px;
    color: #333;
  }
  .task-time {
    display: flex;
    justify-content: space-between;
    color: #666;
  }
  .task-time-left {
    font-size: 14px;
  }
  .task-time-right {
    font-size: 14px;
  }
  .task-wait {
    background: #f8f9fa;
    border-bottom: solid 1px #dddddd;
  }
  .task-complete-success {
    background: #28a74630;
    border-bottom: solid 1px #28a74650;
  }
  .task-complete-error {
    background: #dc354530;
    border-bottom: solid 1px #dc354550;
  }

  .task-wait:last-child,
  .task-complete-success:last-child,
  .task-complete-error:last-child {
    border-bottom: none;
  }
</style>
