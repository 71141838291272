<template>
  <div class="page">
    <h3>Изменение работы техобслуживания</h3>
    <div class="row" v-if="work">
      <div class="col-12">
        <label>Название</label>
        <input type="text" class="form-control" v-model="work.name" placeholder required />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Интервал обслуживания</label>
        <input type="text" class="form-control" v-model="work.interval" placeholder />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Вложенные работы</label>
        <div class="draggable-cards-wrapper">
          <DraggableTree @change="changeItems" class="tree4" :data="work.children" draggable cross-tree :indent="30" :space="0">
            <div slot-scope="{data, store}">
              <template v-if="!data.isDragPlaceHolder">
                <div class="row text-dark py-2">
                  <div class="col">
                    <font-awesome-icon
                      :icon="data.open ? 'chevron-up' : 'chevron-down'"
                      size="1x"
                      v-if="data.children && data.children.length"
                      class="mx-2"
                      @click="store.toggleOpen(data)"
                      :style="{
                        color: 'gray',
                        'margin-left': 'auto',
                        cursor: 'pointer'
                      }"
                    />
                    {{ data.name }}
                    <span class="badge" style="line-height: unset">
                      {{ data.interval ? `Каждые ${data.interval} км.` : "" }}
                    </span>
                  </div>
                  <div class="col-auto">
                    <font-awesome-icon
                      icon="plus"
                      size="1x"
                      :style="{
                        color: 'gray',
                        'margin-left': 'auto',
                        cursor: 'pointer'
                      }"
                      @click="$router.push(`/maintenance/service-works/create/${data.id}`)"
                    />
                    <font-awesome-icon
                      icon="pen"
                      size="1x"
                      :style="{
                        color: 'gray',
                        'margin-left': '2.0rem',
                        cursor: 'pointer'
                      }"
                      @click="$router.push(`/maintenance/service-works/update/${data.id}`)"
                    />
                    <font-awesome-icon
                      icon="trash-alt"
                      size="1x"
                      :style="{
                        color: 'gray',
                        'margin-left': '1.5rem',
                        cursor: 'pointer'
                      }"
                      @click="removeItem(data.id)"
                    />
                  </div>
                </div>
              </template>
            </div>
          </DraggableTree>
        </div>
      </div>
    </div>
    <hr class="mb-4" />
    <div class="float-right btn-group">
      <router-link
        :to="'/service-works/create/' + this.id"
        class="btn btn-success"
      >
        Добавить
      </router-link>
      <button class="btn btn-primary" @click="updateRecord">Сохранить</button>
      <router-link to="/maintenance/service-works" class="btn btn-primary">Назад</router-link>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import serviceWorkApi from '../../api/serviceWork'

export default {
  name: 'UpdateServiceWork',
  props: ['id'],
  computed: {
    ...mapState('maintenance/serviceWork', {
      workState (state) {
        return state.items.find(e => e.id == this.id)
      },
      isLoading: state => state.isLoading
    }),
    work: {
      get () {
        if (!this.serviceWork) {
          return JSON.parse(JSON.stringify(this.workState ? this.workState : null))
        }
        return this.serviceWork
      },
      set (value) {
        this.serviceWork = value
      }
    },
    dragOptions () {
      return {
        animation: 0,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost'
      }
    }
  },
  beforeMount () {
    this.getWorkById(this.id)
      .then(work => {
        this.serviceWork = work
      })
    this.setTitle({
      items: [
        {
          title: 'Тех. обслуживание',
          url: '/maintenance'
        }, {
          title: 'Работы',
          url: '/maintenance/service-works'
        }, {
          title: 'Изменить'
        }
      ]
    })
  },
  data () {
    return {
      isUpdating: false,
      serviceWork: null
    }
  },
  methods: {
    updateRecord () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      serviceWorkApi.updateItem({
        id: this.work.id,
        name: this.work.name,
        interval: this.work.interval,
        created_at: this.work.created_at,
        archived_at: this.work.archived_at,
        sort: this.work.sort
      })
        .then(work => {
          this.isUpdating = false
          this.$router.push('/maintenance/service-works')
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('maintenance/serviceWork', ['loadItem']),
    changeItems (node, targetTree, oldTree) {
      node.parent.children.forEach((v, i) => {
        this.updateItem({
          id: v.id,
          name: v.name,
          parent_work_id: v.parent.id ? v.parent.id : null,
          interval: v.interval,
          created_at: v.created_at,
          archived_at: v.archived_at,
          sort: i
        })
      })
    },
    removeItem (id) {
      serviceWorkApi
        .archivateItem(id)
        .then(() => this.loadItems({ sort: 'sort' }))
    },
    ...mapActions('maintenance/serviceWorks', ['getWorkById']),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
