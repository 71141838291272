<template>
  <div class="page">
    <div class="d-flex justify-content-end">
      <router-link to="/fueling/create" class="btn btn-success">
        Добавить
      </router-link>
      <button class="btn btn-primary ml-2" @click="$refs.file.click()">
        Импорт
      </button>
      <input
        type="file"
        ref="file"
        accept="text/xml"
        @change="addFilesFromDialog"
        class="d-none"
      />
    </div>
    <table class="table table-hover mt-3 d-none d-md-table">
      <TableSort :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr v-for="fuelingItem of fuelingList" :key="fuelingItem.id" @click="$router.push(`/fueling/update/${fuelingItem.id}`)">
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ fuelingItem.car.brand }} {{ fuelingItem.car.model }} - {{ fuelingItem.car.registration_plate }}</TextHighlight>
          </td>
          <td>
            <TextHighlight :queries="searchText">{{ fuelingItem.date | formatDateTime }}</TextHighlight>
          </td>
          <td>
            <TextHighlight :queries="searchText">{{ getFuelTypeName(fuelingItem.fuel_type_id) }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ fuelingItem.remaining }}</TextHighlight>
          </td>
          <td>
            <TextHighlight :queries="searchText">{{ fuelingItem.fueled }}</TextHighlight>
          </td>
          <td>
            <TextHighlight :queries="searchText">
              {{ fuelingItem.author.first_name }}
              {{ fuelingItem.author.last_name }}
              {{ fuelingItem.author.middle_name }}
            </TextHighlight>
          </td>
          <td>
            <TextHighlight :queries="searchText">{{ fuelingItem.created_at | formatDateTime }}</TextHighlight>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mobile-table-view card mt-2 mb-2 p-2 d-md-none" v-for="fuelingItem of fuelingList" :key="fuelingItem.id">
      <table v-if="fuelingList && fuelingList.length > 0"  class="table borderless">
        <tbody>
          <tr>
            <td class="border-top-0">Автомобиль</td>
            <td class="border-top-0">
              <TextHighlight :queries="searchText">
                {{ fuelingItem.car.brand }}
                {{ fuelingItem.car.model }} -
                {{ fuelingItem.car.registration_plate }}
              </TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Дата</td>
            <td>
              <TextHighlight :queries="searchText">{{ fuelingItem.date | formatDateTime }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Тип топлива</td>
            <td>
              <TextHighlight :queries="searchText">{{ getFuelTypeName(fuelingItem.fuel_type_id) }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Остаток, л</td>
            <td>
              <TextHighlight :queries="searchText">{{ fuelingItem.remaining }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Заправлено, л</td>
            <td>
              <TextHighlight :queries="searchText">{{ fuelingItem.fueled }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Автор</td>
            <td>
              <TextHighlight :queries="searchText">
                {{ fuelingItem.author.first_name }}
                {{ fuelingItem.author.last_name }}
                {{ fuelingItem.author.middle_name }}
              </TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Дата внесения записи</td>
            <td>
              <TextHighlight :queries="searchText">{{ fuelingItem.created_at | formatDateTime }}</TextHighlight>
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="`/fueling/update/${ fuelingItem.id }`"
        class="btn btn-primary btn-md create-car ml-auto"
      >
        Подробно
      </router-link>
    </div>
    <div v-if="logsShow && logs">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <font-awesome-icon
              @click="logsShow = false"
              icon="times"
              class="float-right text-secondary"
              role="button"
              tabindex="0"
              size="1x"
            />
            <h4>Информация о добавлении</h4>
            <hr>
            <div class="logs-container">
              <div>Успешно добавленно:
                <span class="font-weight-bold">{{ logs.successCount || 0 }}</span>
              </div>
              <div>Ошибка при добавлении:
                <span class="font-weight-bold">{{ logs.failedCount || 0 }}</span>
              </div>
              <div v-if="logs.errors">
                <div class="font-weight-bold mt-3" v-if="logs.errors.length">Ошибки</div>
                <div v-for="(error, key) of logs.errors" :key="key">{{ error }}</div>
              </div>
              <div v-if="logs.warnings">
                <div class="font-weight-bold mt-3" v-if="logs.warnings.length">Предупреждения</div>
                <div v-for="(warning, key) of logs.warnings" :key="key">{{ warning }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Fueling',
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      logsShow: false,
      logs: null,
      fuelTypesCopy: null,
      sorts: [
        {
          name: 'Автомобиль',
          storeName: 'carIndex',
          values: ['', 'car_id', '-car_id'],
          order: 1,
          disabled: true
        }, {
          name: 'Дата',
          storeName: 'dateIndex',
          values: ['', 'date', '-date'],
          order: 2
        }, {
          name: 'Тип топлива',
          storeName: 'dateIndex',
          values: ['', 'date', '-date'],
          order: 4,
          disabled: true
        }, {
          name: 'Остаток, л',
          storeName: 'remainingIndex',
          values: ['', 'remaining', '-remaining'],
          order: 3
        }, {
          name: 'Заправлено, л',
          storeName: 'fueledIndex',
          values: ['', 'fueled', '-fueled'],
          order: 2
        }, {
          name: 'Автор',
          storeName: 'statusIndex',
          values: ['', 'state', '-state'],
          order: 0,
          disabled: true
        }, {
          name: 'Дата внесения записи',
          storeName: 'createdAtIndex',
          values: ['', 'created_at', '-created_at'],
          order: 0
        }
      ]
    }
  },
  beforeMount () {
    this.loadFuels().then(fuels => {
      this.fuelTypesCopy = JSON.parse(JSON.stringify(fuels))
    })
  },
  mounted () {
    this.setTitle({
      items: [
        {
          title: 'Заправки'
        }
      ]
    })
  },
  computed: {
    ...mapState('fuel/carFueling', {
      fuelingList: state => state.items,
      searchText: state => state.searchText,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading
    }),
    getFuelTypeName () {
      return (fuelTypeId) => {
        if (!this.fuelTypesCopy) {
          return null
        }
        return this.fuelTypesCopy.find(fuelType => fuelType.id === fuelTypeId)?.name
      }
    }
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('fuel/fuelType', {
      loadFuels: 'loadItems'
    }),
    ...mapActions('fuel/carFueling', [
      'abortLoading',
      'setSearch',
      'loadItems',
      'setSearchText',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
    },
    ...mapActions('fuel/carFueling', {
      importFueling: 'importFueling'
    }),
    addFilesFromDialog (e) {
      this.importFueling(e.target.files)
        .then(logs => {
          this.logs = logs
          this.logsShow = true
          this.loadItems({ page: Number(this.page) })
        })
      this.$refs.file.value = null
    }
  },
  watch: {
    page () {
      this.loadItems({ page: Number(this.page) })
    }
  }
}
</script>

<style scoped>
  @media screen and (max-width: 1199px) {
    .page {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 576px) {
    .page {
      padding: 0.5rem;
    }
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
  .logs-container {
    max-height: 300px;
    overflow-y: auto;
    white-space: pre-wrap
  }
</style>
