import Companies from '@/views/Companies'
import Create from '@/views/companies/Create'
import Page from '@/views/companies/Page'

export default [
  {
    path: '/companies',
    component: Companies,
    meta: {
      title: 'Организации',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/companies/create',
    component: Create,
    meta: {
      title: 'Организации',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/companies/:id',
    component: Page,
    name: 'companyPage',
    props: true,
    meta: {
      title: 'Организации',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
