<template>
  <div class="page">
    <h1 class="h2">Создание нового документа</h1>
    <div class="row">
      <div class="col-md-6 mb-6">
        <label>Выберите документ</label>
        <select class="form-control" v-model="currentFormComponent">
          <option
            v-for="({ edit, title }, type) of Types"
            :key="type"
            :value="edit.name"
          >{{ title }}</option>
        </select>
      </div>
    </div>
    <hr class="mb-4" />
    <component
      v-if="currentFormComponent"
      :is="currentFormComponent"
      @update="handleData"
    />
    <button class="btn btn-primary" @click="createDocument()">Создать</button>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Types from '@/views/carDocuments/types'
import DocumentsAPI from '@/api/car-documents'
import CarsAPI from '@/api/cars'
import { mapActions } from 'vuex'

const components = {}
for (const typeName in Types) {
  components[Types[typeName].edit.name] = Types[typeName].edit.component
  components[Types[typeName].view.name] = Types[typeName].view.component
}

export default {
  name: 'CreateDocument',
  components,
  props: ['carId'],
  data () {
    return {
      isCreating: false,
      currentFormComponent: null,
      document: {
        type: '',
        data: '',
        car_id: this.carId
      },
      Types
    }
  },
  mounted () {
    CarsAPI.getItem({ id: this.carId })
      .then(car => {
        this.setTitle({
          items: [
            {
              title: 'Автомобили',
              url: '/cars/'
            }, {
              title: car.brand + ' ' + car.model + ' ' + car.registration_plate,
              url: '/cars/' + this.carId
            }, {
              title: 'Добавить документ'
            }
          ]
        })
      })
  },
  methods: {
    handleData ({ type, data }) {
      this.document.type = type
      this.document.data = data
    },
    createDocument () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      DocumentsAPI.createItem(this.document)
        .then(document => {
          this.isCreating = false
          this.$router.push(`/cars/${this.carId}`)
          return document
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
