var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-fuel-type"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":"/fuel-types/create"}},[_vm._v(" Добавить ")])],1),_c('br'),_c('div',{staticClass:"draggable-cards-wrapper"},_vm._l((_vm.fuelTypes),function(fuelType){return _c('li',{key:fuelType.id,staticClass:"list-group-item"},[_vm._v(" "+_vm._s(fuelType.name)+" "+_vm._s(fuelType.archived_at)+" "),_c('font-awesome-icon',{style:({
          color: 'gray',
          'margin-left': 'auto',
          cursor: 'pointer'
        }),attrs:{"icon":"pen","size":"1x"},on:{"click":function($event){return _vm.$router.push(("/fuel-types/update/" + (fuelType.id)))}}}),_c('font-awesome-icon',{style:({
          color: 'gray',
          'margin-left': '1.5rem',
          cursor: 'pointer'
        }),attrs:{"icon":"trash-alt","size":"1x"},on:{"click":function($event){return _vm.removeItem(fuelType)}}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }