<template>
  <div class="edit-passport">
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Серия/ номер</label>
        <input type="text" class="form-control" v-model="data.number" placeholder required>
      </div>
    </div>
    <Authority :document-data="data" @update="handleData" />
    <div class="row">
      <div class="col-md-8 mb-2">
        <label>Зарегистрирован по адресу</label>
        <dadata-suggestions
          type="ADDRESS"
          class="form-control"
          v-model="data.registration"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 mb-2">
        <label>Дата рождения</label>
        <input type="date"
          class="form-control"
          v-model="data.birth_date"
          required
          min="2010-06-01"
          max="2050-06-30"
        >
      </div>
      <div class="col-md-6 mb-2">
        <label>Место рождения</label>
        <input type="text" class="form-control" v-model="data.birth_place" placeholder required>
      </div>
    </div>
    <hr class="mb-4" />
  </div>
</template>

<script>
import Authority from './Authority'

const type = 'passport'

export default {
  name: 'DocumentPassportEdit',
  components: {
    Authority
  },
  props: ['documentData'],
  data () {
    return {
      data: {
        code: '',
        number: '',
        registration: '',
        authority: '',
        birth_date: '',
        birth_place: ''
      },
      fullInfo: ''
    }
  },
  mounted () {
    if (this.documentData) {
      this.data = this.documentData
    }
    this.update()
  },
  methods: {
    handleData ({ authority, code }) {
      this.data.authority = authority
      this.data.code = code
    },
    update () {
      this.$emit('update', {
        type: type,
        data: this.data
      })
    }
  },
  watch: {
    data: {
      handler () {
        this.update()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .edit-passport input {
    margin-bottom: 5px;
  }
  .edit-passport textarea {
    margin-bottom: 5px;
  }
</style>
