<template>
  <div class="history-container">
    <ul class="nav nav-pills" ref="datePillsContainer" v-if="activeDay">
      <li
        class="nav-item noselect"
        v-for="(day, index) in weekDaysArray()"
        :key="index"
        :ref="day.ref"
      >
        <a
          class="nav-link"
          :class="{ active: activeDay.text === day.text }"
          @click.prevent="selectDay(day)"
        >{{ day.text }}</a>
      </li>
    </ul>
    <br />
    <div class="card trip-card total-trips" v-if="trips.length > 0">
      <div class="card-body trip-card-body">
        <div class="col-trip">
          <font-awesome-icon
            icon="parking"
            size="1x"
            :style="{ color: '#62ADBF', width: '30px', height: '30px' }"
          />
        </div>
        <div>
          <font-awesome-icon
            icon="clock"
            size="1x"
            :style="{ color: '#55606a', 'margin-right': '0.1rem' }"
          />
          <span>{{ totalParkingTime }}</span>
        </div>
        <div class="col-trip" style="margin-left: 15px">
          <font-awesome-icon
            icon="car"
            size="1x"
            :style="{ color: '#92BF4E', width: '30px', height: '30px' }"
          />
        </div>
        <div class="col-trip">
          <font-awesome-icon
            icon="clock"
            size="1x"
            :style="{ color: '#55606a', 'margin-right': '0.1rem' }"
          />
          <span>{{ totalTravelTime }}</span>
        </div>
        <div class="col-trip">
          <font-awesome-icon
            icon="road"
            size="1x"
            :style="{ color: '#55606a', 'margin-right': '0.1rem' }"
          />
          <span>{{ Math.round(trips[0].daylyOdometer / 1000) }}км</span>
        </div>
      </div>
      <hr />
    </div>
    <div class="card trip-card total-trips" v-if="!trips.length">
      <span class="d-flex justify-content-center">Не найдено маршрутов за указанный период</span>
    </div>
    <ul class="list-group">
      <li
        v-for="(trip, i) in trips"
        :key="i"
        class="list-group-item trip-card"
        role="button"
        tabindex="0"
        @click="makeATrack(trip)"
      >
        <div class="trip-card-body">
          <div class="col-trip">
            <font-awesome-icon
              :icon="trip.type === 'parked' ? 'parking' : 'car'"
              :style="{
                color: trip.type === 'parked' ? '#62ADBF' : '#92BF4E',
                width: '30px',
                height: '30px',
              }"
            />
          </div>
          <div class="col-trip" style="display: table">
            <span style="display: table-row">{{ formatTime(trip.startTime) }}</span>
          </div>
          <div class="spaced-items">
            <div class="col-trip">
              <font-awesome-icon
                icon="clock"
                size="1x"
                :style="{ color: '#55606a', 'margin-right': '0.1rem' }"
              />
              <span>{{ formatDifference(trip.duration) }}</span>
            </div>
            <div
              class="col-trip"
              :class="{ undisplayed: trip.type === 'parked' }"
            >
              <font-awesome-icon
                icon="road"
                size="1x"
                :style="{
                  color: '#55606a',
                  'margin-right': '0.1rem'
                }"
              />
              <span>{{ trip.deltaOdometer ? Math.round(trip.deltaOdometer / 1000) : 0 }} км</span>
            </div>
            <div
              class="col-trip"
              :class="{ undisplayed: trip.type === 'parked' }"
            >
              <font-awesome-icon
                icon="tachometer-alt"
                size="1x"
                :style="{ color: '#55606a', 'margin-right': '0.1rem' }"
              />
              <span class="icon-text">{{ trip.maxSpeed || 0 }} км/ч</span>
            </div>
          </div>
          <font-awesome-icon
            icon="route"
            size="1x"
            class="track-icon"
            :class="{ 'selected-trip-icon': isSelectedTrip(trip) }"
            :style="{
              color: 'rgba(64, 64, 64, 0.2)',
              width: '30px',
              height: '30px',
              'margin-left': 'auto',
              transition: 'visibility 0.05s linear,opacity 0.05s linear !important',
              visibility: 'hidden',
              opacity: '0'
            }"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'MapCardHistory',
  data () {
    return {
      activeDay: null,
      selectedTrip: null
    }
  },
  computed: {
    totalParkingTime () {
      return this.formatDifference(
        this.trips.reduce(
          (acc, el) => el.type === 'parked' ? acc + el.daylyDuration : acc,
          0
        )
      )
    },
    totalTravelTime () {
      return this.formatDifference(
        this.trips.reduce(
          (acc, el) => el.type === 'traveled' ? acc + el.daylyDuration : acc,
          0
        )
      )
    },
    formatTime () {
      return dateString => {
        if (!dateString) {
          return null
        }
        const date = new Date(dateString)
        return String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0')
      }
    },
    ...mapState('taxi/trips', {
      trips: state => state.items,
      isLoading: state => state.isLoading
    }),
    ...mapState('taxi/mapCars', {
      track: state => state.track
    }),
    ...mapGetters('taxi/mapCars', {
      car: 'selectedCar'
    })
  },
  mounted () {
    this.selectDay(this.weekDaysArray().shift())
  },
  beforeDestroy () {
    this.clearTrack()
    this.destroyWatcher()
  },
  methods: {
    ...mapActions('taxi/trips', [
      'loadItems',
      'mergeWithCarData'
    ]),
    ...mapActions('taxi/mapCars', [
      'getTrack',
      'clearTrack',
      'selectCar'
    ]),
    makeATrack (trip) {
      if (this.isSelectedTrip(trip)) {
        this.clearTrack()
        this.selectCar(this.car.id)
        return
      }
      this.getTrack({
        id: this.car.id,
        timeFrom: trip.startTime,
        timeTo: trip.endTime
      })
      this.selectedTrip = trip
    },
    weekDaysArray () {
      const result = []
      const now = new Date()
      now.setDate(now.getDate() + 1)
      for (let i = 14; i > 0; --i) {
        const endDateTime = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate()
        now.setDate(now.getDate() - 1)
        const startDateTime = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate()
        const text = now.toLocaleDateString('ru-RU', {
          month: 'long',
          day: 'numeric'
        })
        result.push({
          text,
          ref: `refDayHistory${text.replace(/\s/g, '')}`,
          startDateTime,
          endDateTime
        })
      }
      return result
    },
    selectDay (day) {
      this.clearTrack()
      this.selectCar(this.car.id)
      this.loadItems({
        imei: this.car.imei,
        startDateTime: day.startDateTime,
        endDateTime: day.endDateTime
      })
      this.activeDay = day
      const out = this.$refs.datePillsContainer

      if (!this.$refs[this.activeDay.ref]) {
        return
      }

      const tar = this.$refs[this.activeDay.ref][0]
      let q = 0
      for (const day of this.weekDaysArray()) {
        if (this.$refs[day.ref][0] === tar) {
          break
        }
        q += this.$refs[day.ref][0].clientWidth
      }
      out.scrollTo(Math.max(0, q - (out.clientWidth - tar.clientWidth) / 2), 0)
    },
    formatDifference (diff) {
      const totalMinutes = Math.round(diff / 60)
      const totalHours = parseInt(totalMinutes / 60)
      let result = ''

      if (totalHours) {
        result += totalHours + 'ч '
      }
      if (totalMinutes) {
        const minutes = totalMinutes - totalHours * 60
        result += minutes ? minutes + 'мин' : ''
      }

      if (!totalHours && !totalMinutes) {
        result = '0 мин'
      }

      return result
    },
    startWatch () {
      this.destroyWatcher()
      if (!this.isLoading && this.activeDay) {
        this.loadItems({
          imei: this.car.imei,
          startDateTime: this.activeDay.startDateTime,
          endDateTime: this.activeDay.endDateTime
        })
        this._timeout = setTimeout(() => this.startWatch(), 10000)
        return
      }
      this._timeout = setTimeout(() => this.startWatch(), 5000)
    },
    destroyWatcher () {
      clearTimeout(this._timeout)
      this._timeout = null
    },
    isSelectedTrip (trip) {
      if (!this.selectedTrip) {
        return false
      }
      return this.selectedTrip.type === trip.type && this.selectedTrip.startTime === trip.startTime
    }
  },
  watch: {
    car: {
      immediate: true,
      handler (car, carOld) {
        if ((car && carOld && car.id !== carOld.id) || (car && !carOld)) {
          this.startWatch()
        }
      }
    },
    track () {
      if (!this.track.length) {
        this.selectedTrip = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-link {
    border-radius: 50px;
    padding: 0.1rem 1.5rem;
    white-space: nowrap;
    color: #1a97f2;
    border: 1px solid #1a97f2;
    margin-right: 0.2rem;
    cursor: pointer;
  }

  .nav-link.active {
    background-color: #1a97f2;
  }
  .nav-pills {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0;
  }
  .noselect {
    user-select: none;
  }
  .trip-card-body {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.25rem;
  }
  .col-trip {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
    margin: 0 0.4rem;
    flex-flow: nowrap;
    align-items: center;
  }
  .spaced-items {
    display: flex;
    flex-direction: row;
    width: 100%;
    width: fit-content;
    padding: 0 1.25rem;
    flex-wrap: wrap;
  }
  .spaced-items .col-trip {
    flex-grow: 1;
  }
  .trip-card {
    padding: 0;
  }
  .undisplayed {
    display: none;
  }
  .hidden {
    visibility: hidden;
  }
  .total-trips {
    border: none;
  }
  .total-trips .card-body {
    justify-content: center;
  }
  .list-group-item:hover  {
    .track-icon {
      visibility: visible !important;
      opacity: 1 !important;
    }
    border-radius: 5px;
    box-shadow: 3px 2px 4px 0px rgba(64, 64, 64, 0.6);
    z-index: 999;
  }
  .selected-trip-icon {
    visibility: visible !important;
    opacity: 1 !important;
    color: rgb(146, 191, 78) !important;
  }
</style>
